import React from "react";
import CompetePage from "../../CompetePage"

function Compete() {
  return (
    <>
      <CompetePage />
    </>
  );
}

export default Compete;

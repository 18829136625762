import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Home from "./components/pages/HomePage/Home";
import Games from "./components/pages/Games/Games";
import Compete from "./components/pages/Compete/Compete";
import Leaderboards from "./components/pages/Leaderboards/Leaderboards";
import Locations from "./components/pages/Locations/Locations";
import PrivacyPolicy from "./components/pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./components/pages/TermsOfUse/TermsOfUse";
import ContestRules from "./components/pages/ContestRules/ContestRules";
import GameRules from "./components/pages/GameRules/GameRules";
import Footer from "./components/pages/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Share from "./components/pages/Share/Share";
import Reset from "./components/pages/Reset/Reset";
import ContestLanding from "./components/pages/ContestLanding/ContestLanding";
import ContestLandingSacTown from "./components/pages/ContestLanding/ContestLandingSacTown";
import LeaderboardTest from "./components/pages/LeaderboardTest";
import ContestLandingTokenMtg from "./components/pages/ContestLanding/ContestLandingTokenMtg";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/games" component={Games} />
          <Route path="/compete" component={Compete} />
          <Route path="/leaderboards" component={Leaderboards} />
          <Route path="/locations" component={Locations} />
          <Route path="/omni-arena-privacy-policy" component={PrivacyPolicy} />
          <Route path="/omni-arena-player-agreement" component={TermsOfUse} />
          <Route path="/contest-rules" component={ContestRules} />
          <Route path="/game-rules" component={GameRules} />
          <Route path="/share" component={Share} />
          <Route path="/reset" component={Reset} />
          <Route path="/contest-landing" component={ContestLanding} />
          <Route path="/contest-sac-town" component={ContestLandingSacTown} />
          <Route path="/contest-token-mtg" component={ContestLandingTokenMtg} />
          <Route path="/leaderboard-test-12yhr76f5" component={LeaderboardTest} />
          <Redirect to="/" />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;

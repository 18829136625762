import React from 'react';
import {Button} from './Button';
import './VideoHeroSection.css';
import Fade from 'react-reveal/Fade';
import LazyAnimation from './LazyAnimation';
import { Link } from 'react-router-dom';



function VideoHeroSection({
    lightText, lightTextDesc, imgStart
}) {

    return (
        <>
            <div className='video__hero-section'>
                <div className="video__hero-container">
                    <div className="video__hero-row"
                    style={{display: 'flex', flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'}}
                    >
                    <video autoPlay playsInline muted="true" loop className="video_style" poster="/video/oa_trailer_playing_teaser_img.jpg">
                        <source src="/video/oa_trailer_playing_teaser.webm" type="video/webm" />
                        <source src="/video/oa_trailer_playing_teaser.mp4" type="video/mp4" />
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                        <div className="col_video">
                        <LazyAnimation></LazyAnimation>
                            <Fade bottom cascade duration={800}>
                                <div className="video__hero-text-wrapper-video">
                                    <div className="top-line"></div>
                                    <h1 className={lightText ? 'heading_video' : 'heading_video dark'}>Run around in VR</h1>
                                    <p className={lightTextDesc ? 'video__hero-subtitle' : 'video__hero-subtitle dark'}>Move freely and at full speed in 360 degrees inside your favorite game.</p>
                                    <Link to='/games'>                     
                                    <Button buttonSize='btn--wide' buttonColor='green'>
                                        See Our Games <div className="right-arrow-video"><img src="/images/arrow-icon-now.svg" alt="arrow"/></div>
                                    </Button>
                                    </Link>
                                </div>
                            </Fade> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VideoHeroSection
import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./Survey.css";
import { Button } from "./Button";
import { Formik, Field, Form, ErrorMessage } from "formik";

const Survey = (props) => {
  const styleObjWidth22 = {
    width: "22px",
  };

  const [displayError, setDisplayError] = useState(true);
  const [displayClose, setDisplayClose] = useState(false);
  
  const handleError = () => setDisplayError(false);
  const handleClose = () => setDisplayClose(true);

  return (
    <div className={displayClose ? "survey-modal-close" : ""}>
      <CSSTransition
        in={props.show}
        unmountOnExit
        timeout={{ enter: 0, exit: 300 }}
      >
        <div
          className={`survey-modal ${props.show ? "show" : ""}`}
          onClick={props.onClose}
        >
          <div
            className="survey-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="survey-modal-header">
              <div
                className={`close-survey-modal ${props.show ? "show" : ""}`}
                onClick={props.onClose}
              >
                <img
                  src="/images/x-close-green.svg"
                  style={styleObjWidth22}
                  alt=""
                  onClick={props.onClose}
                />
              </div>
              <h4 className="survey-modal-title">Thank you for playing!</h4>
              <p className="survey-modal-subheader">
                Please give us your feedback.
              </p>
            </div>
            <div className="background-green-survey-modal">&nbsp;</div>
            <div className="survey-modal-body">
              <Formik
                initialValues={{
                  rating: "",
                  again: "",
                  primarily: "",
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      planToPlay: parseInt(values.primarily),
                      playAgain: parseInt(values.again),
                      rate: parseInt(values.rating),
                    }),
                  };
                  if (values.primarily === "") {
                    handleError();
                  }
                  if (values.again === "") {
                    handleError();
                  }
                  if (values.rating === "") {
                    handleError();
                  }
                  const response = await fetch(
                    "https://api.playerhub.omniverse.global/social/" +
                      window.location.search.substring(4) +
                      "/survey",
                    requestOptions
                  );
                  if (response.status !== 204) {
                    handleError();
                  } else {
                    handleClose();
                  }
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div
                      id="experience-rating-group"
                      className="survey-modal-copy-rank"
                    >
                      How was your experience? (10 = Awesome)
                    </div>
                    <div
                      role="group"
                      aria-labelledby="experience-rating-group"
                      className="survey-rank-radio"
                    >
                      <label className="survey-label-radio-rank">
                        <Field
                          type="radio"
                          name="rating"
                          value="1"
                          className="survey-radio"
                        />
                        <div className="survey-rank-text">1</div>
                      </label>
                      <label className="survey-label-radio-rank">
                        <Field
                          type="radio"
                          name="rating"
                          value="2"
                          className="survey-radio"
                        />
                        <div className="survey-rank-text">2</div>
                      </label>
                      <label className="survey-label-radio-rank">
                        <Field
                          type="radio"
                          name="rating"
                          value="3"
                          className="survey-radio"
                        />
                        <div className="survey-rank-text">3</div>
                      </label>
                      <label className="survey-label-radio-rank">
                        <Field
                          type="radio"
                          name="rating"
                          value="4"
                          className="survey-radio"
                        />
                        <div className="survey-rank-text">4</div>
                      </label>
                      <label className="survey-label-radio-rank">
                        <Field
                          type="radio"
                          name="rating"
                          value="5"
                          className="survey-radio"
                        />
                        <div className="survey-rank-text">5</div>
                      </label>
                      <label className="survey-label-radio-rank">
                        <Field
                          type="radio"
                          name="rating"
                          value="6"
                          className="survey-radio"
                        />
                        <div className="survey-rank-text">6</div>
                      </label>
                      <label className="survey-label-radio-rank">
                        <Field
                          type="radio"
                          name="rating"
                          value="7"
                          className="survey-radio"
                        />
                        <div className="survey-rank-text">7</div>
                      </label>
                      <label className="survey-label-radio-rank">
                        <Field
                          type="radio"
                          name="rating"
                          value="8"
                          className="survey-radio"
                        />
                        <div className="survey-rank-text">8</div>
                      </label>
                      <label className="survey-label-radio-rank">
                        <Field
                          type="radio"
                          name="rating"
                          value="9"
                          className="survey-radio"
                        />
                        <div className="survey-rank-text">9</div>
                      </label>
                      <label className="survey-label-radio-rank">
                        <Field
                          type="radio"
                          name="rating"
                          value="10"
                          className="survey-radio"
                        />
                        <div className="survey-rank-text">10</div>
                      </label>
                    </div>
                    <ErrorMessage
                      className="survey-pass-error"
                      name="rating"
                      component="div"
                    />
                    <div className="survey-modal-question-flex">
                      <div
                        id="play-again-group"
                        className="survey-modal-copy-body"
                      >
                        Would you play again?
                      </div>
                      <div
                        role="group"
                        aria-labelledby="play-again-group"
                        className="survey-options"
                      >
                        <label className="survey-label-radio">
                          <Field
                            type="radio"
                            name="again"
                            value="0"
                            className="survey-question-no"
                          />
                          <div className="survey-label-text">No</div>
                        </label>
                        <label className="survey-label-radio">
                          <Field
                            type="radio"
                            name="again"
                            value="1"
                            className="survey-question-yes"
                          />
                          <div className="survey-label-text">Yes</div>
                        </label>
                      </div>
                    </div>
                    <ErrorMessage
                      className="survey-pass-error"
                      name="again"
                      component="div"
                    />
                    <div className="survey-modal-question-flex">
                      <div
                        id="primarily-group"
                        className="survey-modal-copy-body"
                      >
                        Did you come to the venue primarily to play Omni Arena?
                      </div>
                      <div
                        role="group"
                        aria-labelledby="primarily-group"
                        className="survey-options"
                      >
                        <label className="survey-label-radio">
                          <Field
                            type="radio"
                            name="primarily"
                            value="0"
                            className="survey-question-no"
                          />
                          <div className="survey-label-text">No</div>
                        </label>
                        <label className="survey-label-radio">
                          <Field
                            type="radio"
                            name="primarily"
                            value="1"
                            className="survey-question-yes"
                          />
                          <div className="survey-label-text">Yes</div>
                        </label>
                      </div>
                    </div>
                    <ErrorMessage
                      className="survey-pass-error"
                      name="primarily"
                      component="div"
                    />
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      buttonSize="btn-full-width"
                      buttonColor="green"
                    >
                      Send
                    </Button>
                    <div
                      className={
                        displayError
                          ? "survey-modal-success-none"
                          : "survey-modal-error"
                      }
                    >
                      Please complete survey before submitting.
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export { Survey };
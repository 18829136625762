import React from "react";
import "./ContestLandingHowTo.css";
import { Link } from "react-router-dom";
import LazyAnimation from "./LazyAnimation";
import ContestLandingSubscribe from "./ContestLandingSubscribe";

function ContestLandingHowTo({
    contestGame,
    contestGenre,
    contestVenueLocation,
    contestEndDate,
  }) {

    return (
        <>
          <div className="contest-landing-how-container">
          <LazyAnimation />
            <h2 className="contest-landing-how-header" >
            How to play
            </h2>
            <p className="contest-landing-how-body-first">
            Form a team of up to four players and play { contestGame }, Omni Arena’s team-based { contestGenre }, at { contestVenueLocation }!
            </p>
            <p className="contest-landing-how-body">
            The leaderboards are ongoing, meaning you can play anytime and compete for the top spot. Your team’s score will be added automatically to the contest leaderboard. The { contestGame } contest ends { contestEndDate } at midnight Pacific time.
            </p>
            <p className="contest-landing-how-body">
            For the complete set of official rules governing the prize contest, click <Link className="contest-landing-how-link-gray" to="/contest-rules" target="_blank" rel="noreferrer">here</Link>.
            </p>
            <p className="contest-landing-how-body-last">
            Think your team can take the top spot? Play Omni Arena and compete to win!
            </p>
          <LazyAnimation />
            <h2 className="contest-landing-how-header" >
            Want to learn more?
            </h2>
            <p className="contest-landing-how-body-first">
            Fill out your email address to get notified about this contest.
            </p>
            <ContestLandingSubscribe />
          </div>
        </>
      );
  }
  
  export default ContestLandingHowTo;
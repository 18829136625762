import React, { useEffect, useState } from "react";
import "./ContestLanding.css";
import { contestObjTokenMtg, contestAdTokenMtg, contestHowTokenMtg } from './ContestLandingData';
import ContestLandingAd from "../../ContestLandingAd";
import ContestLandingHeroTokenMtg from "../../ContestLandingHeroTokenMtg";
import ContestLandingHomeHero from "../../ContestLandingHomeHero";
import ContestLandingHowTo from "../../ContestLandingHowTo";
import ContestLandingEnd from "../../ContestLandingEnd";
import { Helmet } from "react-helmet";

function ContestLandingTokenMtg() {
  return (
    <>
    <Helmet>
        <meta name="robots" content="noindex" />
    </Helmet>
      <ContestLandingHomeHero {...contestObjTokenMtg} />
      <ContestLandingAd {...contestAdTokenMtg}/>
      <ContestLandingHeroTokenMtg />
      <ContestLandingHowTo {...contestHowTokenMtg} />
      <ContestLandingEnd />
    </>
  );
}

export default ContestLandingTokenMtg;
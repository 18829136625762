import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Navbar.css';
import { IconContext } from 'react-icons/lib';
// import Announcebar from "./Announcebar";

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerwidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    };

    const [show, setShow] = useState(false);
    const [videoshow, setVideoShow] = useState(false);

    useEffect(() => {
        showButton()
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
        {/* <Announcebar /> */}
        <IconContext.Provider value={{ color: '#fff'}}>
            <div className="navbar">
                <div className="navbar-container">
                    <Link to='/' className="navbar-logo" onClick={closeMobileMenu}>
                        {/* <img src="/images/omniarena_logo_color2.svg" alt=''/> */}
                        {/* <img src="/images/omniarena_logo_color2.png" alt=''/> */}
                        <img src="/images/omniarena_logo_color2_230px.svg" alt=''/>
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        {click ? <FaTimes /> : <FaBars />}
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className="nav-item">
                            <Link to='/games' className="nav-links" onClick={closeMobileMenu}>
                                Games
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/compete' className="nav-links" onClick={closeMobileMenu}>
                                Compete
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/leaderboards' className="nav-links" onClick={closeMobileMenu}>
                                Leaderboards
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/locations' className="nav-links" onClick={closeMobileMenu}>
                                Locations
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            </IconContext.Provider>
        </>
    )
}

export default Navbar
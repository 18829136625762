import React from "react";
import "./ContestLandingHeroSection.css";
import Fade from "react-reveal/Fade";
import LazyAnimation from "./LazyAnimation";
import GoogleMapReact from "google-map-react";

function ContestLandingHeroSacTown() {
  const ContestMapMarker = () => (
    <img
      className="contest-map-marker"
      src="images/marker-green.png"
      alt="Map marker icon"
    />
  );
  const defaultProps = {
    center: { 
      lat: 38.63036889710692,
      lng: -121.21554085835841,
    },
    zoom: 15,
  };

  const styleWidth = {
    maxWidth: '300px'
  }

  return (
    <>
      <div className="contest-landing-hero-section-text-extra-padding">
        <div className="contest-landing-hero-section-text">
          <div className="contest-landing-hero-section-container">
            <div className="contest-landing-hero-section-row">
              <div className="contest-landing-hero-section-col">
                <LazyAnimation />
                <Fade bottom cascade duration={800}>
                  <div className="contest-landing-hero-section-text-wrapper">
                    <h1 className="contest-landing-hero-section-heading contest-landing-hero-section-text-dark">
                      Where to play
                    </h1>
                    <p className="contest-landing-hero-section-subtitle contest-landing-hero-section-text-dark">
                      <img src="/images/sacTown_logo_600px.png" style={ styleWidth } alt="" />
                    </p>
                    <p className="contest-landing-hero-section-subtitle contest-landing-hero-section-text-dark">
                        12401 Folsom Blvd #105
                      <br />
                        Rancho Cordova, CA 95742
                      <br />
                      <a className="contest-landing-how-link-gray" href="https://www.sactownfamilyfun.com/" target="_blank" rel="noreferrer">
                        Visit Website
                      </a>
                    </p>
                  </div>
                </Fade>
              </div>
              <div className="contest-landing-hero-section-col">
                <div style={{ height: "550px", width: "100%", }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyA2EnH7YKk3P3hPR4yd2n3raKXss0e_3_0",
                    }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                  >
                    <ContestMapMarker
                      lat={38.63036889710692}
                      lng={-121.21554085835841}
                    />
                  </GoogleMapReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContestLandingHeroSacTown;

import React from "react";
import "./CompetePage.css";
import LazyAnimation from "./LazyAnimation";
import Fade from "react-reveal/Fade";
import CompeteHeroSection from "./CompeteHeroSection";
import { competeObjOne, competeObjTwo, competeObjThree, competeObjFour, competeObjFive, competeObjSix } from "./CompeteHeroSectionData";
import CompetitionSection from './CompetitionSection';
import { activeCompetition } from './CompetitionSectionData';
import LocationSection from "./LocationSection";

export function CompetePage() {
    return (
      <>
        <div className="compete-hero-bg">
          <div className="compete-container">
          <div className="compete-hero">
            <h1 className="compete-title">Play and Win!</h1>
            <LazyAnimation></LazyAnimation>
            <h2 className="compete-subtitle">
              Top the leaderboards to win cash from a $100,000 prize pool!
            </h2>
          </div>
          </div>
        </div>
        <CompeteHeroSection {...competeObjOne} />
        <CompeteHeroSection {...competeObjTwo} />
        <CompeteHeroSection {...competeObjThree} />
        <CompetitionSection {...activeCompetition} />
        <div className="compete-hero-container">
        <Fade bottom duration={800}>
            <h1 className="compete-hero-header">
              How to Participate
            </h1>
          </Fade>
          </div>
        <CompeteHeroSection {...competeObjFour} />
        <CompeteHeroSection {...competeObjFive} />
        <CompeteHeroSection {...competeObjSix} />
        <LocationSection />
      </>
    );
  }
  
  export default CompetePage;
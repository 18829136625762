import React, { useState } from "react";
import "./GameCard.css";
import Fade from "react-reveal/Fade";
import LazyAnimation from "./LazyAnimation";
import { Button } from "./Button";
import { VideoModalDeadZone, VideoModalEliteForce, VideoModalGunslinger, VideoModalParanormal, VideoModalBlackbeard, VideoModalCoreDefense, VideoModalHardpoint, VideoModalRhythmMaster } from './VideoModal';


function GameCardOne({
    img,
    caption,
    gameTitle,
    subTitle,
    description,
    buttonLabel,
    alt
  }) {    

  const [videoshow, setVideoShow] = useState(false);

    return (
        <>
        <div className="gamecard-col">
              <img src={img} alt={alt} className="gamecard-img" />
              {/* <Fade bottom duration={800}>
                <div className="gamecard-caption">{caption}</div>
              </Fade> */}
              <LazyAnimation></LazyAnimation>
              {/* <Fade bottom duration={800}>
                <div className="gamecard-title">{gameTitle}</div>
              </Fade> */}
              <Fade bottom duration={800}>
                <div className="gamecard-subtitle">{subTitle}</div>
              </Fade>
              <Fade bottom duration={800}>
                <div className="gamecard-caption">{description}</div>
              </Fade>
                <Button buttonSize="btn-wide" buttonColor="green" onClick={() => setVideoShow(true) }>
                  {buttonLabel}{" "}
                  <div className="gamecard-right-arrow">
                    <img src="/images/arrow-icon-now.svg" alt="arrow" />
                  </div>
                </Button>
              <VideoModalDeadZone title="Trailer" onClose={() => setVideoShow(false)} show={videoshow} />
            </div>
        </>
    )
}

function GameCardTwo({
  img,
  caption,
  gameTitle,
  subTitle,
  description,
  buttonLabel,
  alt
}) {    

const [videoshow, setVideoShow] = useState(false);

  return (
      <>
      <div className="gamecard-col">
            <img src={img} alt={alt} className="gamecard-img" />
            {/* <Fade bottom duration={800}>
              <div className="gamecard-caption">{caption}</div>
            </Fade> */}
            <LazyAnimation></LazyAnimation>
            {/* <Fade bottom duration={800}>
              <div className="gamecard-title">{gameTitle}</div>
            </Fade> */}
            <Fade bottom duration={800}>
              <div className="gamecard-subtitle">{subTitle}</div>
            </Fade>
            <Fade bottom duration={800}>
              <div className="gamecard-caption">{description}</div>
            </Fade>
              <Button buttonSize="btn-wide" buttonColor="green" onClick={() => setVideoShow(true) }>
                {buttonLabel}{" "}
                <div className="gamecard-right-arrow">
                  <img src="/images/arrow-icon-now.svg" alt="arrow" />
                </div>
              </Button>
            <VideoModalEliteForce title="Trailer" onClose={() => setVideoShow(false)} show={videoshow} />
          </div>
      </>
  )
}

function GameCardThree({
  img,
  caption,
  gameTitle,
  subTitle,
  description,
  buttonLabel,
  alt
}) {    

const [videoshow, setVideoShow] = useState(false);

  return (
      <>
      <div className="gamecard-col">
            <img src={img} alt={alt} className="gamecard-img" />
            {/* <Fade bottom duration={800}>
              <div className="gamecard-caption">{caption}</div>
            </Fade> */}
            <LazyAnimation></LazyAnimation>
            {/* <Fade bottom duration={800}>
              <div className="gamecard-title">{gameTitle}</div>
            </Fade> */}
            <Fade bottom duration={800}>
              <div className="gamecard-subtitle">{subTitle}</div>
            </Fade>
            <Fade bottom duration={800}>
              <div className="gamecard-caption">{description}</div>
            </Fade>
              <Button buttonSize="btn-wide" buttonColor="green" onClick={() => setVideoShow(true) }>
                {buttonLabel}{" "}
                <div className="gamecard-right-arrow">
                  <img src="/images/arrow-icon-now.svg" alt="arrow" />
                </div>
              </Button>
            <VideoModalGunslinger title="Trailer" onClose={() => setVideoShow(false)} show={videoshow} />
          </div>
      </>
  )
}

function GameCardFour({
  img,
  caption,
  gameTitle,
  subTitle,
  description,
  buttonLabel,
  alt
}) {    

const [videoshow, setVideoShow] = useState(false);

  return (
      <>
      <div className="gamecard-col">
            <img src={img} alt={alt} className="gamecard-img" />
            {/* <Fade bottom duration={800}>
              <div className="gamecard-caption">{caption}</div>
            </Fade> */}
            <LazyAnimation></LazyAnimation>
            {/* <Fade bottom duration={800}>
              <div className="gamecard-title">{gameTitle}</div>
            </Fade> */}
            <Fade bottom duration={800}>
              <div className="gamecard-subtitle">{subTitle}</div>
            </Fade>
            <Fade bottom duration={800}>
              <div className="gamecard-caption">{description}</div>
            </Fade>
              <Button buttonSize="btn-wide" buttonColor="green" onClick={() => setVideoShow(true) }>
                {buttonLabel}{" "}
                <div className="gamecard-right-arrow">
                  <img src="/images/arrow-icon-now.svg" alt="arrow" />
                </div>
              </Button>
            <VideoModalParanormal title="Trailer" onClose={() => setVideoShow(false)} show={videoshow} />
          </div>
      </>
  )
}


function GameCardEight({
  img,
  caption,
  gameTitle,
  subTitle,
  description,
  buttonLabel,
  alt
}) {    

const [videoshow, setVideoShow] = useState(false);

  return (
      <>
      <div className="gamecard-col">
            <img src={img} alt={alt} className="gamecard-img" />
            {/* <Fade bottom duration={800}>
              <div className="gamecard-caption">{caption}</div>
            </Fade> */}
            <LazyAnimation></LazyAnimation>
            {/* <Fade bottom duration={800}>
              <div className="gamecard-title">{gameTitle}</div>
            </Fade> */}
            <Fade bottom duration={800}>
              <div className="gamecard-subtitle">{subTitle}</div>
            </Fade>
            <Fade bottom duration={800}>
              <div className="gamecard-caption">{description}</div>
            </Fade>
              <Button buttonSize="btn-wide" buttonColor="green" onClick={() => setVideoShow(true) }>
                {buttonLabel}{" "}
                <div className="gamecard-right-arrow">
                  <img src="/images/arrow-icon-now.svg" alt="arrow" />
                </div>
              </Button>
            <VideoModalRhythmMaster title="Trailer" onClose={() => setVideoShow(false)} show={videoshow} />
          </div>
      </>
  )
}

function GameCardFive({
  img,
  caption,
  gameTitle,
  subTitle,
  description,
  buttonLabel,
  alt
}) {    

const [videoshow, setVideoShow] = useState(false);

  return (
      <>
      <div className="gamecard-col">
            <img src={img} alt={alt} className="gamecard-img" />
            {/* <Fade bottom duration={800}>
              <div className="gamecard-caption">{caption}</div>
            </Fade> */}
            <LazyAnimation></LazyAnimation>
            {/* <Fade bottom duration={800}>
              <div className="gamecard-title">{gameTitle}</div>
            </Fade> */}
            <Fade bottom duration={800}>
              <div className="gamecard-subtitle">{subTitle}</div>
            </Fade>
            <Fade bottom duration={800}>
              <div className="gamecard-caption">{description}</div>
            </Fade>
              <Button buttonSize="btn-wide" buttonColor="green" onClick={() => setVideoShow(true) }>
                {buttonLabel}{" "}
                <div className="gamecard-right-arrow">
                  <img src="/images/arrow-icon-now.svg" alt="arrow" />
                </div>
              </Button>
            <VideoModalBlackbeard title="Trailer" onClose={() => setVideoShow(false)} show={videoshow} />
          </div>
      </>
  )
}

function GameCardSix({
  img,
  caption,
  gameTitle,
  subTitle,
  description,
  buttonLabel,
  alt
}) {    

const [videoshow, setVideoShow] = useState(false);

  return (
      <>
      <div className="gamecard-col">
            <img src={img} alt={alt} className="gamecard-img" />
            {/* <Fade bottom duration={800}>
              <div className="gamecard-caption">{caption}</div>
            </Fade> */}
            <LazyAnimation></LazyAnimation>
            {/* <Fade bottom duration={800}>
              <div className="gamecard-title">{gameTitle}</div>
            </Fade> */}
            <Fade bottom duration={800}>
              <div className="gamecard-subtitle">{subTitle}</div>
            </Fade>
            <Fade bottom duration={800}>
              <div className="gamecard-caption">{description}</div>
            </Fade>
              <Button buttonSize="btn-wide" buttonColor="green" onClick={() => setVideoShow(true) }>
                {buttonLabel}{" "}
                <div className="gamecard-right-arrow">
                  <img src="/images/arrow-icon-now.svg" alt="arrow" />
                </div>
              </Button>
            <VideoModalCoreDefense title="Trailer" onClose={() => setVideoShow(false)} show={videoshow} />
          </div>
      </>
  )
}

function GameCardSeven({
  img,
  caption,
  gameTitle,
  subTitle,
  description,
  buttonLabel,
  alt
}) {    

const [videoshow, setVideoShow] = useState(false);

  return (
      <>
      <div className="gamecard-col">
            <img src={img} alt={alt} className="gamecard-img" />
            {/* <Fade bottom duration={800}>
              <div className="gamecard-caption">{caption}</div>
            </Fade> */}
            <LazyAnimation></LazyAnimation>
            {/* <Fade bottom duration={800}>
              <div className="gamecard-title">{gameTitle}</div>
            </Fade> */}
            <Fade bottom duration={800}>
              <div className="gamecard-subtitle">{subTitle}</div>
            </Fade>
            <Fade bottom duration={800}>
              <div className="gamecard-caption">{description}</div>
            </Fade>
              <Button buttonSize="btn-wide" buttonColor="green" onClick={() => setVideoShow(true) }>
                {buttonLabel}{" "}
                <div className="gamecard-right-arrow">
                  <img src="/images/arrow-icon-now.svg" alt="arrow" />
                </div>
              </Button>
            <VideoModalHardpoint title="Trailer" onClose={() => setVideoShow(false)} show={videoshow} />
          </div>
      </>
  )
}

export { GameCardOne, GameCardTwo, GameCardThree, GameCardFour, GameCardFive, GameCardSix, GameCardSeven, GameCardEight }
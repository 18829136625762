export const homeObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    headline: 'Play. Compete. Win Cash.',
    subTitle:
      'Experience full freedom of movement inside videogames.',
    copy:
      'Sign up to get notified when Omni One pre-orders start.',
    buttonLabel: 'Omni Arena Locations ',
    imgStart: '',
    img: '',
    alt: 'Omni One'
  };
  
  export const homeObjTwo = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    extraPad: true,
    headline: 'Run around in VR',
    description:
      'Move freely at full speed in 360 degrees.',
    buttonLabel: '',
    imgStart: 'start',
    img: '/images/omniplayer_section1.jpg',
    alt: 'Runner'
  };

  export const homeObjThree = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    extraPad: false,
    headline: 'Play with friends',
    description:
      'Play with up to four friends and compete for the top spot on the leaderboards.',
    buttonLabel: '',
    imgStart: '',
    img: '/images/omniplayer_section2_kidsplaying.jpg',
    alt: 'VR Headset'
  };

  export const homeObjFour = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    extraPad: false,
    headline: 'Compete for money',
    description:
      'Claim your piece of the $100,000 prize pool in ongoing weekly and monthly esports contests.',
    buttonLabel: '',
    imgStart: 'start',
    img: '/images/omniplayer_section3_winners.jpg',
    alt: 'Crouch'
  };
import React from "react";
import GamesPage from "../../GamesPage";

function Games() {
  return (
    <>
      <GamesPage />
    </>
  );
}

export default Games;

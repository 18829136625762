import MailchimpSubscribe from 'react-mailchimp-subscribe';
import ContestLandingForm from './ContestLandingForm';

const ContestLandingSubscribe = () => {

  const MAILCHIMP_URL = 'https://virtuix.us9.list-manage.com/subscribe/post?u=4e183d4472ed890a7a7ea651f&amp;id=dcf1154e99';

  return (
    <MailchimpSubscribe
      url={ MAILCHIMP_URL }
      render={ ( props ) => {
        const { subscribe, status, message } = props || {};
        return (
          <ContestLandingForm
            status={ status }
            message={ message }
            onValidated={ formData => subscribe( formData ) }
          />
        );
      } }
    />
  );
};

export default ContestLandingSubscribe;
import React, { useState } from "react";
import "./AllGames.css";
import "./GamesData";
import Fade from "react-reveal/Fade";
import ScrollContainer from "react-indiana-drag-scroll";
import { VideoModalDeadZone, VideoModalEliteForce, VideoModalGunslinger, VideoModalParanormal, VideoModalBlackbeard, VideoModalCoreDefense, VideoModalHardpoint } from './VideoModal';

function AllGames({ 
    imgGameDeadZone,
    altDeadZone,
    imgGameBlackbeard,
    altBlackbeard,
    imgGameGunslinger,
    altGunslinger,
    imgGameCoreDefense,
    altCoreDefense,
    imgGameParanormal,
    altGameParanormal,
    imgGameEliteForce,
    altEliteForce,
    imgGameHardpoint,
    altHardpoint
}) {

  const [videoshowDZ, setVideoShowDZ] = useState(false);
  const [videoshowEF, setVideoShowEF] = useState(false);
  const [videoshowGS, setVideoShowGS] = useState(false);
  const [videoshowP, setVideoShowP] = useState(false);
  const [videoshowBB, setVideoShowBB] = useState(false);
  const [videoshowCD, setVideoShowCD] = useState(false);
  const [videoshowHP, setVideoShowHP] = useState(false);

  return (
    <>
      <div className="all-games-bg">
        <div className="all-games-container">
          <Fade bottom duration={800}>
            <h1 className="all-games-header">Play Again and Try All Games</h1>
          </Fade>
          <Fade bottom duration={800}>
            <h3 className="all-games-subheader">
              Experience VR like never before.
            </h3>
          </Fade>
          <ScrollContainer hideScrollbars="false" className="all-games-scroll-container">
            <div className="all-games-carousel-margin">
                <img
                  src={imgGameDeadZone}
                  alt={altDeadZone}
                  className="all-games-img"
                  onClick={() => setVideoShowDZ(true) }
                />
                <VideoModalDeadZone title="Trailer" onClose={() => setVideoShowDZ(false)} show={videoshowDZ} />
            </div>
            <div className="all-games-carousel-margin">
                <img
                  src={imgGameEliteForce}
                  alt={altEliteForce}
                  className="all-games-img"
                  onClick={() => setVideoShowEF(true) }
                />
                <VideoModalEliteForce title="Trailer" onClose={() => setVideoShowEF(false)} show={videoshowEF} />
            </div>
            <div className="all-games-carousel-margin">
                <img
                  src={imgGameGunslinger}
                  alt={altGunslinger}
                  className="all-games-img"
                  onClick={() => setVideoShowGS(true) }
                />
                <VideoModalGunslinger title="Trailer" onClose={() => setVideoShowGS(false)} show={videoshowGS} />
            </div>
            <div className="all-games-carousel-margin">
                <img
                  src={imgGameParanormal}
                  alt={altGameParanormal}
                  className="all-games-img"
                  onClick={() => setVideoShowP(true) }
                />
                <VideoModalParanormal title="Trailer" onClose={() => setVideoShowP(false)} show={videoshowP} />
            </div>
            <div className="all-games-carousel-margin">
                <img
                  src={imgGameBlackbeard}
                  alt={altBlackbeard}
                  className="all-games-img"
                  onClick={() => setVideoShowBB(true) }
                />
                <VideoModalBlackbeard title="Trailer" onClose={() => setVideoShowBB(false)} show={videoshowBB} />
            </div>
            <div className="all-games-carousel-margin">
                <img
                  src={imgGameCoreDefense}
                  alt={altCoreDefense}
                  className="all-games-img"
                  onClick={() => setVideoShowCD(true) }
                />
                <VideoModalCoreDefense title="Trailer" onClose={() => setVideoShowCD(false)} show={videoshowCD} />
            </div>
            <div className="all-games-carousel-margin">
                <img
                  src={imgGameHardpoint}
                  alt={altHardpoint}
                  className="all-games-img"
                  onClick={() => setVideoShowHP(true) }
                />
                <VideoModalHardpoint title="Trailer" onClose={() => setVideoShowHP(false)} show={videoshowHP} />
            </div>
          </ScrollContainer>
        </div>
      </div>
    </>
  );
}

export default AllGames;

import React, { useEffect, useLayoutEffect, useState } from "react";
import "./LeaderboardsSection.css";
import "./CompetitionSectionData";
import Fade from "react-reveal/Fade";
import LazyAnimation from "./LazyAnimation";
import MonthlyCompTable from "./MonthlyCompTable";
import WeeklyCompTable from "./WeeklyCompTable";

function LeaderboardsSection({
  imgMonth,
  monthCaption,
  imgWeek,
  weekCaption,
  gameTitleMonth,
  gameTitleWeek,
  subTitleMonth,
  subTitleWeek,
  buttonLabel,
  alt,
}) {
  const [monthlyLeaderboardId, setMonthlyLeaderboardId] = useState();
  const [weeklyLeaderboardId, setWeeklyLeaderboardId] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(()=>{
    fetch("https://api.livefeed.omniverse.global/api/livefeeds/playerhub")
    .then(result => result.json())
    .then(result => {
      setWeeklyLeaderboardId(result[1].leaderboardId);
      setMonthlyLeaderboardId(result[0].leaderboardId);
    });
  },[])
  return (
    <>
      <div className="leaderboards-comp-bg">
        <div className="leaderboards-comp-container">
          <div className="leaderboards-comp-row">
            <div className="leaderboards-comp-col leaderboards-responsive-margin">
              <img src={imgMonth} alt={alt} className="leaderboards-comp-img" />
              <Fade bottom duration={800}>
                <div className="leaderboards-comp-caption">{monthCaption}</div>
              </Fade>
              <LazyAnimation></LazyAnimation>
              <Fade bottom duration={800}>
                <div className="leaderboards-comp-title">{gameTitleMonth}</div>
              </Fade>
              <Fade bottom duration={800}>
                <div className="leaderboards-comp-subtitle">{subTitleMonth}</div>
              </Fade>
            </div>
            <div className="leaderboards-comp-col">
              {monthlyLeaderboardId && (<MonthlyCompTable leaderboardId={monthlyLeaderboardId} />)}
            </div>
          </div>
          <span class="leaderboards-comp-anchor" id="weekly" />
          <div className="leaderboards-comp-row">
          <div className="leaderboards-comp-col leaderboards-responsive-margin">
              <img src={imgWeek} alt={alt} className="leaderboards-comp-img" />
              <Fade bottom duration={800}>
                <div className="leaderboards-comp-caption">{weekCaption}</div>
              </Fade>
              <LazyAnimation></LazyAnimation>
              <Fade bottom duration={800}>
                <div className="leaderboards-comp-title">{gameTitleWeek}</div>
              </Fade>
              <Fade bottom duration={800}>
                <div className="leaderboards-comp-subtitle">{subTitleWeek}</div>
              </Fade>
            </div>
            <div className="leaderboards-comp-col">
            {weeklyLeaderboardId && (<MonthlyCompTable leaderboardId={weeklyLeaderboardId} />)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeaderboardsSection;
import React from "react";
import "../../Copy.css";

function PrivacyPolicy() {
  return (
    <>
      <div className="copy-container">
        <h1 className="copy-title">Omni Arena Privacy Policy</h1>
        <p className="copy-body">
          Virtuix Inc. and its affiliates (collectively, "Virtuix" or "we")
          respects the privacy of its online visitors and customers of its
          products and services, including Omni Arena, and complies with
          applicable laws for the protection of your privacy, including, without
          limitation, the European Union General Data Protection Regulation
          ("GDPR").
        </p>
        <h2 className="copy-header">1. Definitions</h2>
        <p className="copy-body">
          Wherever we talk about "Personal Data" below, we mean any information
          that can either itself identify you as an individual ("Personally
          Identifying Information") or that can be connected to you indirectly
          by linking it to Personally Identifying Information. Virtuix also
          processes anonymous data, aggregated or not, to analyze and produce
          statistics related to the habits, usage patterns, and demographics of
          customers as a group or as individuals. Such anonymous data does not
          allow the identification of the customers to which it relates.
        </p>
        <p className="copy-body">
          Virtuix may share both Personally Identifying Information and
          anonymous data, aggregated or not, with third parties.
        </p>
        <h2 className="copy-header">
          2. Why Virtuix Collects and Processes Data
        </h2>
        <p className="copy-body">
          Virtuix collects and processes Personal Data for the following
          reasons:
        </p>
        <p className="copy-body copy-list-indent">
          <ol className="copy-list-lower-alpha">
            <li>
              where it is necessary to provide a complete and enjoyable Omni
              Arena experience to you and deliver associated content and
              services, including Omni Arena competitions and prize contests;
            </li>
            <li>
              where it is necessary for compliance with legal obligations that
              we are subject to (e.g. our obligations to keep certain
              information);
            </li>
            <li>
              where it is necessary for the purposes of the legitimate and legal
              interests of Virtuix or a third party (e.g. the interests of our
              customers or development partners), except where such interests
              are overridden by your prevailing legitimate interests and rights;
              or
            </li>
            <li>where you have given consent to it.</li>
          </ol>
        </p>
        <p className="copy-body">
          These reasons for collecting and processing Personal Data determine
          and limit what Personal Data we collect and how we use it (Section 3
          below), how long we store it (Section 4 below), who has access to it
          (Section 5 below) and what rights and other control mechanisms are
          available to you as a user (Section 6 below).
        </p>
        <h2 className="copy-header">3. What Data We Collect and Process</h2>
        <div className="copy-indent">
          <h3 className="copy-subheader">3.1 Basic Account Data</h3>
          <p className="copy-body">
            When setting up an Omni Arena User Account ("User Account"), Virtuix
            will collect your email address, first name, last name, date of
            birth, country of residence, and preferred Omni gameplay location.
            You are also required to choose a user name and a password. The
            provision of this information is necessary to register a User
            Account. During setup of your User Account, the User Account is
            automatically assigned a number (the "Omni Arena ID") that is later
            used to reference your User Account without directly exposing
            Personally Identifying Information about you.
          </p>
          <h3 className="copy-subheader">3.2 Omni Arena Data</h3>
          <p className="copy-body">
            When using the Omni Arena attraction, we will collect your height,
            shoe size, and harness size to help facilitate the Omni setup
            process. We will store this data to your User Account so that we can
            automatically load this data each time you play in the Omni Arena
            attraction.
          </p>
          <h3 className="copy-subheader">3.3 Gameplay Data</h3>
          <p className="copy-body">
            Each time you use Omni Arena, we will track your calories burned on
            the Virtuix Omni, your steps taken, and miles run. We will also
            track and save your game scores, achievements, and game statistics.
            By game statistics we mean information about your games'
            preferences, progress in the games, playtime, and crash data. Your
            gameplay data may be displayed on Omni Arena leaderboards and
            rankings.
          </p>
          <h3 className="copy-subheader">
            3.4 Other Data You Explicitly Submit
          </h3>
          <p className="copy-body">
            We will collect and process Personal Data whenever you explicitly
            provide it to us or send it as part of communication with others,
            including community forums, chats, or when you provide feedback or
            other user generated content. This data includes:
          </p>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>
                Information that you post, comment or follow in any of our
                content and services;
              </li>
              <li>Information sent through chat;</li>
              <li>
                Information you provide when you request information or support
                from us;
              </li>
              <li>
                Information you provide to us when participating in
                competitions, contests and tournaments or responding to surveys,
                e.g. your contact details.
              </li>
            </ul>
          </p>
          <h3 className="copy-subheader">3.5 Tracking Data and Cookies</h3>
          <p className="copy-body">
            Omni Arena and Virtuix websites may use "Cookies", which are text
            files placed on your computer, to help us analyze how users use our
            services, and similar technologies (e.g. web beacons, pixels, ad
            tags and device identifiers) to recognize you and/or your device(s)
            on, off and across different devices and our services, as well as to
            improve the services we are offering, to improve marketing,
            analytics or website functionality. The use of Cookies is standard
            on the internet. Although most web browsers automatically accept
            cookies, the decision of whether to accept or not is yours. You may
            adjust your browser settings to prevent the reception of cookies, or
            to provide notification whenever a cookie is sent to you. You may
            refuse the use of cookies by selecting the appropriate settings on
            your browser. However, please note that if you do this, you may not
            be able to access the full functionality of Omni Arena and related
            products and services.
          </p>
          <p className="copy-body">
            When you visit any of our services, our servers log your global IP
            address, which is a number that is automatically assigned to the
            network your computer is part of.
          </p>
          <h3 className="copy-subheader">3.6 Google Analytics</h3>
          <p className="copy-body">
            Omni Arena and Virtuix websites may use Google Analytics, a web
            analytics service provided by Google, Inc. ("Google"). Google
            Analytics uses "cookies", which are text files placed on visitors'
            computers, to help the website operators analyze how visitors use
            the site. The information generated by the cookie about the
            visitors' use of the website will generally be transmitted to and
            stored by Google on servers in the United States.
          </p>
          <p className="copy-body">
            On this website, IP anonymization has been activated. The IP
            addresses of users will be shortened. Only in exceptional cases will
            a complete IP address be transferred to a Google server in the
            United States and shortened there. On behalf of the website
            operator, Google will use this information for the purpose of
            evaluating the website for its users, in order to compile reports on
            website activity, and to provide other services relating to website
            activity and internet usage for website operators.
          </p>
          <p className="copy-body">
            Google will not associate the IP address transferred in the context
            of Google Analytics with any other data held by Google. You may
            refuse the use of cookies by selecting the appropriate settings on
            your browser. However, please note that in this case you may not be
            able to use the full functionality of this website.
          </p>
          <p className="copy-body">
            Furthermore, users can prevent the collection of data about their
            use of the website (including their IP address) generated by the
            cookie, and the processing of data by Google, by downloading and
            installing the browser plug-in through the following link:{" "}
            <a
              className="copy-link-gray"
              href="http://tools.google.com/dlpage/gaoptout?hl=en"
            >
              http://tools.google.com/dlpage/gaoptout?hl=en
            </a>
            .
          </p>
          <h3 className="copy-subheader">
            3.7 Content Recommendations and Marketing
          </h3>
          <p className="copy-body">
            Where explicitly permitted under applicable laws on email marketing,
            Virtuix may send you marketing messages about products and services
            offered by Virtuix to your email address, including but not limited
            to marketing of new Omni Arena games or Omni Arena contests and
            events. In such a case we may also use your collected information to
            customize such marketing messages as well as collect information on
            whether you opened such messages and which links in their text you
            followed.
          </p>
          <p className="copy-body">
            You can opt out or withdraw your consent to receive marketing emails
            at any time by clicking the "unsubscribe" link provided in every
            marketing email.
          </p>
        </div>
        <h2 className="copy-header">4. How Long We Store Data</h2>
        <p className="copy-body">
          We will only store your information as long as necessary to fulfil the
          purposes for which the information is collected and processed or —
          where the applicable law provides for longer storage and retention
          period — for the storage and retention period required by law. After
          that your Personal Data will be deleted, blocked or anonymized, as
          provided by applicable law.
        </p>
        <p className="copy-body">In particular:</p>
        <p className="copy-body copy-list-indent">
          <ul className="copy-list-disc">
            <li>
              If you terminate your Omni Arena User Account, your Personal Data
              will be marked for deletion except to the degree legal
              requirements or other prevailing legitimate purposes dictate a
              longer storage.
            </li>
            <li>
              In certain cases Personal Data cannot be completely deleted in
              order to ensure the consistency of the gameplay experience for
              other Omni Arena users. For instance, matches you have played that
              affect other players' matchmaking data and scores will not be
              deleted; rather, your connection to these matches will be
              permanently anonymized.
            </li>
            <li>
              If you withdraw your consent on which a processing of your
              Personal Data or of the Personal Data of your child is based, we
              will delete, respectively, your Personal Data or the Personal Data
              of your child without undue delay to the extent that the
              collection and processing of the Personal Data was based on the
              withdrawn consent.
            </li>
            <li>
              If you exercise a right to object to the processing of your
              Personal Data, we will review your objection and delete your
              Personal Data that we processed for the purpose to which you
              objected without undue delay, unless another legal basis for
              processing and retaining this data exists or unless applicable law
              requires us to retain the data.
            </li>
          </ul>
        </p>
        <h2 className="copy-header">5. Who Has Access to Data</h2>
        <div className="copy-indent">
          <p className="copy-body">
            5.1 Virtuix and its affiliates may share your Personal Data with
            each other and use it to the degree necessary to achieve the
            purposes listed in Section 2 above. In the event of a
            reorganization, sale or merger we may transfer Personal Data to the
            relevant third party subject to applicable laws.
          </p>
          <p className="copy-body">
            5.2 We may also share your Personal Data with our third party
            providers that provide customer services in connection with Omni
            Arena content and services, including operators of entertainment
            venues that operate Virtuix Omni or Omni Arena attractions. Your
            Personal Data will be used in accordance with this Privacy Policy
            and only as far as this is necessary for performing customer
            services, including but not limited to marketing Omni Arena contests
            and events, or other events or promotions organized by third party
            providers.
          </p>
          <p className="copy-body">
            5.3 In accordance with internet standards, we may also share certain
            information (including your IP address and the identification of
            Omni Arena content you wish to access) with our third party network
            providers that provide content delivery network services and game
            server services in connection with Omni Arena.
          </p>
          <p className="copy-body">
            5.4 We make certain data related to your Omni Arena User Account
            available to our development partners through an API. At a minimum,
            the public persona name you have chosen to represent you on Omni
            Arena is accessible this way. Depending on which Omni Arena services
            are implemented in the game it may also include leaderboard
            information, your progress in the game, achievements you have
            completed, your multiplayer game matchmaking information, in-game
            items and other information needed to operate the game and provide
            support for it.
          </p>
          <p className="copy-body">
            5.5 The Virtuix community includes message boards, forums and/or
            chat areas, where users can exchange ideas and communicate with each
            other. When posting a message to a board, forum or chat area, please
            be aware that the information is being made publicly available
            online; therefore, you are doing so at your own risk. If your
            Personal Data is posted on one of our community forums against your
            will, please let us know and request its removal.
          </p>
          <p className="copy-body">
            5.6 Virtuix may release Personal Data to comply with court orders or
            laws and regulations that require us to disclose such information.
          </p>
        </div>
        <h2 className="copy-header">6. Your Rights and Control Mechanisms</h2>
        <p className="copy-body">
          The data protection laws of the European Economic Area and other
          territories grant their citizens certain rights in relation to their
          Personal Data. While other jurisdictions may provide fewer statutory
          rights to their citizens, we make the tools designed to exercise such
          rights available to our customers worldwide.
        </p>
        <p className="copy-body">
          To allow you to exercise your data protection rights in a simple way,
          we are providing you access to your Personal Data, allow you to
          rectify and delete it where necessary and to object to its use where
          you feel necessary. You can obtain your Personal Data by emailing us
          at{" "}
          <a
            className="copy-link-gray"
            href="mailto:info@virtuix.com"
            target="_blank"
            rel="noreferrer"
          >
            info@virtuix.com
          </a>
          .
        </p>
        <p className="copy-body">
          You have the following rights in relation to your Personal Data:
        </p>
        <div className="copy-indent">
          <h3 className="copy-subheader">6.1 Right of Access.</h3>
          <p className="copy-body">
            You have the right to access your Personal Data that we hold about
            you, i.e. the right to require free of charge (i) information
            whether your Personal Data is retained, (ii) access to and/or (iii)
            duplicates of the Personal Data retained. You can use the right to
            access to your Personal Data by contacting us via email at
            info@virtuix.com. Please note that in some circumstances, we may not
            be able to fully comply with your request, such as if it is
            frivolous or extremely impractical, if it jeopardizes the rights of
            others, or if it is not required by law, but in those circumstances,
            we will still respond to notify you of such a decision. We also
            reserve the right to charge a reasonable fee in light of the
            administrative costs of providing the information or communication
            or taking the action requested. In some cases, we may also need to
            you to provide us with additional information, which may include
            Personal Data, if necessary to verify your identity and the nature
            of your request.
          </p>
          <h3 className="copy-subheader">6.2 Right to Rectification.</h3>
          <p className="copy-body">
            If we process your Personal Data, we shall endeavor to ensure by
            implementing suitable measures that your Personal Data is accurate
            and up-to-date for the purposes for which it was collected. If your
            Personal Data is inaccurate or incomplete, you can change the
            information you provided by emailing us at{" "}
            <a
              className="copy-link-gray"
              href="mailto:info@virtuix.com"
              target="_blank"
              rel="noreferrer"
            >
              info@virtuix.com
            </a>
            .
          </p>
          <h3 className="copy-subheader">6.3. Right to Erasure.</h3>
          <p className="copy-body">
            You have the right to obtain deletion of Personal Data concerning
            you if the reason why we could collect it (see Section 2 above) does
            not exist anymore or if there is another legal ground for its
            deletion. For individual items of Personal Data please request the
            deletion via email at{" "}
            <a
              className="copy-link-gray"
              href="mailto:info@virtuix.com"
              target="_blank"
              rel="noreferrer"
            >
              info@virtuix.com
            </a>
            .
          </p>
          <p className="copy-body">
            As a result of deleting your Omni Arena User Account, you will lose
            access to Omni Arena leaderboards and other Omni Arena services
            reserved for players with an Omni Arena account.
          </p>
          <p className="copy-body">
            We allow you to restore your Omni Arena User Account during a grace
            period of 30 (thirty) days from the moment you request deletion of
            your Omni Arena User Account. This functionality allows you not to
            lose your account by mistake, because of your loss of your account
            credentials or due to hacking. After the grace period, Personal Data
            associated with your account will be deleted subject to Section 4
            above.
          </p>
          <h3 className="copy-subheader">6.4 Right to Object.</h3>
          <p className="copy-body">
            When our processing of your Personal Data is based on legitimate
            interests according to Article 6(1)(f) of the GDPR / Section 2(c) of
            this Privacy Policy, you have the right to object to this
            processing. If you object we will no longer process your Personal
            Data unless there are compelling and prevailing legitimate grounds
            for the processing as described in Article 21 of the GDPR; in
            particular if the data is necessary for the establishment, exercise
            or defense of legal claims.
          </p>
          <h3 className="copy-subheader">6.5 Portability.</h3>
          <p className="copy-body">
            You can ask for a copy of your Personal Data in a machine-readable
            format. You can also request that we transmit the data to another
            controller where technically feasible.
          </p>
          <h3 className="copy-subheader">6.6 Restriction of Processing.</h3>
          <p className="copy-body">
            In some circumstances you have the right to restrict the processing
            of your Personal Data. Those circumstances are as follows: you
            contest the accuracy of the Personal Data; processing is unlawful
            but you oppose erasure; we no longer need the personal data for the
            purposes of our processing, but you require personal data for the
            establishment, exercise or defense of legal claims; and you have
            objected to processing, pending the verification of that objection.
            Where processing has been restricted on this basis, we may continue
            to store your Personal Data. However, we will only otherwise process
            it: with your consent; for the establishment, exercise or defense of
            legal claims; for the protection of the rights of another natural or
            legal person; or for reasons of important public interest.
          </p>
          <h3 className="copy-subheader">6.7 Right to File a Complaint.</h3>
          <p className="copy-body">
            If applicable, you have the right to lodge a complaint about
            Virtuix's practices with respect to your Personal Data with the
            relevant supervisory authority of your habitual residence, your
            place of work or the place of the alleged infringement.
          </p>
        </div>
        <h2 className="copy-header">7. Children</h2>
        <p className="copy-body">
          The minimum age to create an Omni Arena User Account is 13. Virtuix
          will not knowingly collect Personal Data from children under this age.
          Virtuix encourages parents to instruct their children to never give
          out personal information when online.
        </p>
        <h2 className="copy-header">8. Contact Info</h2>
        <p className="copy-body">
          You can contact Virtuix's data protection officer at the address
          below.
        </p>
        <p className="copy-body">
          While we review any request sent by mail, please be aware that to
          combat fraud, harassment and identity theft, the only way to access,
          rectify or delete your data is by emailing us at or via the address
          below.
        </p>
        <p className="copy-body copy-indent">
          <i>
            Virtuix Inc.
            <br />
            1826 Kramer Lane
            <br />
            Suite H<br />
            Austin, TX 78758
            <br />
            USA
          </i>
        </p>
        <h2 className="copy-header">9. Changes to the Privacy Policy</h2>
        <p className="copy-body">
          We reserve the right to update this Privacy Policy at any time,
          without prior notice to you, to the extent permitted by applicable
          law. We will notify the changes that we make to this Privacy Policy by
          posting the revised Privacy Policy on this site, and such changes will
          be effective immediately unless otherwise stated. We will provide
          notice to you if these changes are material and, where required by
          applicable law, we will obtain your consent.
        </p>
        <p className="copy-body">Last Revision: April 25, 2022</p>
      </div>
    </>
  );
}

export default PrivacyPolicy;

import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import "./AccountConfirm.css";
import { Button } from "./Button";
import { Formik, Form, Field, ErrorMessage } from "formik";

const AccountConfirmModal = (props) => {
  const styleObjWidth22 = {
    width: "22px",
  };

  const [displayNone, setDisplayNone] = useState(true);
  const [displayBlock, setDisplayBlock] = useState(true);
  const [displayError, setDisplayError] = useState(true);

  const handleError = () => setDisplayError(!displayError);
  const handleDisplay = () => setDisplayBlock(!displayBlock);
  const handleSuccess = () => setDisplayNone(!displayNone);

  return (
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className={`confirm-modal ${props.show ? "show" : ""}`}>
        <div
          className="confirm-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={
              displayBlock
                ? "confirm-modal-success"
                : "confirm-modal-success-none"
            }
          >
            <div className="confirm-modal-header">
              <h4 className="confirm-modal-title">Welcome to Omni Arena</h4>
              <Link className="close-confirm-modal" to="#">
                <img
                  src="/images/x-close-green.svg"
                  style={styleObjWidth22}
                  alt=""
                  onClick={props.onClose}
                />
              </Link>
            </div>
            <div className="background-green-confirm-modal">&nbsp;</div>
            <div className="confirm-modal-body">
              <p className="confirm-modal-copy-body">Set your password</p>
              <Formik
                initialValues={{ password: "", confirmPassword: "" }}
                validate={(values) => {
                  const errors = {};
                  if (!values.password) {
                    errors.password = "Required";
                  } else if (values.password.length < 6) {
                    errors.password = "Password must be at least 6 characters";
                  } else if (values.confirmPassword !== values.password) {
                    errors.confirmPassword = "Password does not match";
                  }
                  return errors;
                }}
                onSubmit={async (values) => {
                  const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      token: window.location.search.split("=")[1].split("&")[0],
                      password: values.password,
                      confirmPassword: values.confirmPassword,
                    }),
                  };
                  const actionType = window.location.search.split("=").at(-1);
                  let url =
                    "https://api.playerhub.omniverse.global/api/users/verify/";
                    // "https://api.playerhub.omniverse-vr.com/api/users/verify/";
                  if (actionType === "reset") {
                    requestOptions.method = "PUT";
                    url =
                      "https://api.playerhub.omniverse.global/api/users/reset-and-change-password";
                      // "https://api.playerhub.omniverse-vr.com/api/users/reset-and-change-password";
                  }
                  const response = await fetch(url, requestOptions);
                  if (response.status !== 204) {
                    handleError();
                  } else {
                    handleSuccess();
                    handleDisplay();
                  }
                  // const data = await response.json();
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Field
                      className="confirm-input-pass"
                      type="password"
                      name="password"
                      placeholder="Enter your new password"
                    />
                    <ErrorMessage
                      className="confirm-pass-error"
                      name="password"
                      component="div"
                      />
                    <Field
                      className="confirm-input-pass"
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm password"
                    />
                    <ErrorMessage
                      className="confirm-pass-error"
                      name="confirmPassword"
                      component="div"
                    />
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      buttonSize="btn-full-width"
                      buttonColor="green"
                    >
                      Set Password
                    </Button>
                    <div
                      className={
                        displayError
                          ? "confirm-modal-success-none"
                          : "confirm-modal-error"
                      }
                    >
                      This account is already verified.
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className={displayNone ? "confirm-modal-success-none" : ""}>
            <div className="confirm-modal-header">
              <h4 className="confirm-modal-title">Success!</h4>
              <Link className="close-confirm-modal" to="#">
                <img
                  src="/images/x-close-green.svg"
                  style={styleObjWidth22}
                  alt=""
                  onClick={props.onClose}
                />
              </Link>
            </div>
            <div className="background-green-confirm-modal">&nbsp;</div>
            <div className="confirm-modal-body">
              <p className="confirm-modal-copy-body">
                Receive your gameplay video and participate in Omni Arena
                contests by entering your username and password each time you
                play.
              </p>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export { AccountConfirmModal };

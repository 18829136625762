import React from "react";
import "../../Copy.css";

function GameRules() {
  return (
    <>
      <div className="copy-container">
        <h1 className="copy-title">Omni Arena Game Rules</h1>
        <h2 className="copy-header">1. Official Game Rules</h2>
        <p className="copy-body">
          These official Game Rules ("Game Rules") govern the rules for
          competitive play of certain Omni Arena games that are used for Omni
          Arena prize contests ("Contests" or "Contest") organized by Virtuix
          Inc. or any of its affiliates (collectively, "Virtuix" or "we"). These
          Game Rules complement the Official Rules listed on the Omni Arena
          player website at https://arena.virtuix.com. You are required to read
          and understand both these Game Rules and Official Rules (together, the
          "Contest Rules") before you can participate in any Contests. You must
          accept these Contest Rules in order to participate as a player and
          member of a team ("Team") in Contests (respectively, "Player" and
          "Team Member"), and you must abide by these Contest Rules in order to
          remain eligible to play in Contests and receive prizes. We make every
          effort to ensure the rules are complete and up to date, but this may
          not always be the case. If you have any questions about the rules,
          please contact us at{" "}
          <a
            className="copy-link-gray"
            href="mailto:info@virtuix.com"
            target="_blank"
            rel="noreferrer"
          >
            info@virtuix.com
          </a>
          .
        </p>
        <h2 className="copy-header">2. Core Defense</h2>
        <p className="copy-body">
          In Core Defense, a single player ("Single Player") or a Team competes
          for the highest score on the game leaderboard. Points can be won in
          the following ways:
        </p>
        <div className="copy-indent">
          <h3 className="copy-subheader">1. Kill an enemy robot:</h3>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>100 points for killing a "Grunt"</li>
              <li>500 points for killing a "Drone"</li>
              <li>1,000 points for killing a "Tank"</li>
            </ul>
          </p>
          <h3 className="copy-subheader">
            2. Kill an enemy robot via a headshot, grenade, or with any of the
            special weapons available for pick-up in the arena:
          </h3>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>
                100 points (these are in addition to the basic "kill-a-robot"
                points described in (1) above)
              </li>
            </ul>
          </p>
          <h3 className="copy-subheader">
            3. Kill the enemy "boss" (in level "Metropolis") or two "bosses" (in
            level "Coliseum") in the third game round:
          </h3>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>
                7,500 points if the boss was killed (3,750 points per boss in
                Coliseum)
              </li>
              <li>
                5,000 points if the boss was not killed but caused medium damage
                (2,500 points per boss in Coliseum)
              </li>
              <li>
                2,500 points if the boss was not killed but caused low damage
                (1,250 points per boss in Coliseum)
              </li>
            </ul>
          </p>
          <h3 className="copy-subheader">
            4. Shoot a bonus "star" that appears in the arena every 15 seconds
            for 5 seconds: 2,000 points
          </h3>
          <h3 className="copy-subheader">
            5. Maintain the health of the "Power Core" in the arena:
          </h3>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>
                7,500 points if the Power Core survives and remains in high
                health
              </li>
              <li>
                5,000 points if the Power Core survives and remains in medium
                health
              </li>
              <li>
                2,500 points if the Power Core survives and remains in low
                health
              </li>
            </ul>
          </p>
        </div>
        <p className="copy-body">
          The final Single Player or Team score is the total score achieved
          after completing the three rounds of one Core Defense game.
        </p>
        <p className="copy-body">
          In the event of a tie, the tied entries will be sorted as follows:
        </p>
        <p className="copy-body copy-list-indent">
          <ol>
            <li>
              By the highest number of epic kills (i.e., kills with a headshot,
              grenade, or with any of the special weapons available for pick-up
              in the arena)
            </li>
            <li>
              By the date/time the score was submitted. Earlier entries are
              placed higher than later entries.
            </li>
          </ol>
        </p>
        <h2 className="copy-header">3. Blackbeard</h2>
        <p className="copy-body">
          In Blackbeard, a single player ("Single Player") or a Team competes
          for the highest score on the game leaderboard. Points can be won in
          the following ways:
        </p>
        <div className="copy-indent">
          <h3 className="copy-subheader">
            1. Collect treasure scattered around the map
          </h3>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>100 points per pouch of gold collected</li>
              <li>500 points per gem collected</li>
              <li>1,000 points per treasure chest collected</li>
            </ul>
          </p>
          <h3 className="copy-subheader">2. Kill an enemy "skeleton"</h3>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>500 points for killing a skeleton in Trials 1 and 4</li>
              <li>
                5,000 points for killing a skeleton with an explosive barrel in
                Trial 2
              </li>
            </ul>
          </p>
          <h3 className="copy-subheader">
            3. Shoot one of the hidden "treasure maps" scattered around the map
          </h3>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>2,500 points per hidden treasure map</li>
            </ul>
          </p>
          <h3 className="copy-subheader">
            4. Complete a symbol sequence in trial 3 by pressing the symbols in
            the specified order
          </h3>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>10,000 points per completed sequence</li>
            </ul>
          </p>
          <h3 className="copy-subheader">
            5. Earn a time bonus by completing a symbol sequence in trial 3 in
            less than 5 seconds
          </h3>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>
                2,000 points per second of completion time below 5 seconds
              </li>
            </ul>
          </p>
          <h3 className="copy-subheader">
            6. Defeat "Captain Blackbeard" before the end of trial 4
          </h3>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>15,000 points for defeating Captain Blackbeard</li>
            </ul>
          </p>
          <h3 className="copy-subheader">
            7. Players lose points each time they are damaged by an enemy (the
            team's score cannot be reduced below 0)
          </h3>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>-10 points per hit by a skeleton sword</li>
              <li>-10 points per hit by a skeleton pistol shot</li>
              <li>-20 points per hit by a skeleton grenade launcher</li>
              <li>-50 points per hit by Blackbeard's sword</li>
              <li>-50 points per hit by Blackbeard's grenade launcher</li>
            </ul>
          </p>
        </div>
        <p className="copy-body">
          The game dynamically sets a difficulty level depending on the team's
          performance in trial 1. If the team is placed in the "easy"
          difficulty, certain score values are reduced.
        </p>
        <p className="copy-body">
          The final Single Player or Team score is the total score achieved
          after completing all 4 trials of one Blackbeard game.
        </p>
        <p className="copy-body">
          In the event of a tie, the tied entries will be sorted as follows:
        </p>
        <p className="copy-body copy-list-indent">
          <ol>
            <li>
              By the date/time the score was submitted. Earlier entries are
              placed higher than later entries.
            </li>
          </ol>
        </p>
        <h2 className="copy-header">4. Dead Zone</h2>
        <p className="copy-body">
          In Dead Zone, a single player ("Single Player") or a Team competes for
          the highest score on the game leaderboard. Points can be won in the
          following ways:
        </p>
        <div className="copy-indent">
          <h3 className="copy-subheader">
            1. Kill an enemy "zombie": 100 points
          </h3>
          <h3 className="copy-subheader">
            2. Collect one of the "supply crates" scattered around the map:
            2,000 points
          </h3>
        </div>
        <p className="copy-body">
          The player will lose points if they are hit by a zombie:
        </p>
        <div className="copy-indent">
          <h3 className="copy-subheader">1. -10 points per hit by a zombie.</h3>
          <h3 className="copy-subheader">
            2. -101 points when damaged while in the inner radius of an
            explosion (0 ft. - 20 ft.)
          </h3>
          <h3 className="copy-subheader">
            3. -1 point when damaged while in the outer radius of an explosion
            (20 ft - 40 ft.)
          </h3>
        </div>
        <p className="copy-body">
          The final Single Player or Team score is the total score achieved
          after completing the two rounds of one Dead Zone game.
        </p>
        <p className="copy-body">
          In the event of a tie, the tied entries will be sorted as follows:
        </p>
        <p className="copy-body copy-list-indent">
          <ol>
            <li>
              By the date/time the score was submitted. Earlier entries are
              placed higher than later entries.
            </li>
          </ol>
        </p>
        <h2 className="copy-header">5. Gunslinger</h2>
        <p className="copy-body">
          In Gunslinger, a single player ("Single Player") or a Team competes
          for the highest score on the game leaderboard. Points can be won in
          the following ways:
        </p>
        <div className="copy-indent">
          <h3 className="copy-subheader">1. Kill or destroy an enemy:</h3>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>100 points for killing an "Outlaw"</li>
              <li>500 points for destroying an "Airship"</li>
              <li>1,000 points for destroying an "Explosive Cart"</li>
            </ul>
          </p>
          <h3 className="copy-subheader">
            2. Kill an enemy via a headshot, explosion, shotgun, or dynamite
            launcher:{" "}
          </h3>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>
                100 points (these are in addition to the basic "kill" points
                described in (1) above)
              </li>
            </ul>
          </p>
          <h3 className="copy-subheader">
            3. Defeat "Carmichael" before the end of the second half:
          </h3>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>7,500 points for defeating Carmichael</li>
            </ul>
          </p>
          <h3 className="copy-subheader">
            4. Secure stolen gold by running over it:
          </h3>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>2,000 points</li>
            </ul>
          </p>
          <h3 className="copy-subheader">
            5. Maintain "Supplies Remaining" in the center of the town:
          </h3>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>
                100 points x % supplies remaining (e.g., 100 points x 64%
                supplies remaining = 6,400 points)
              </li>
            </ul>
          </p>
          <p className="copy-body">
            The final Single Player or Team score is the total score achieved
            after completing both halves of one Gunslinger game.
          </p>
          <p className="copy-body">
            In the event of a tie, the tied entries will be sorted as follows:
          </p>
          <p className="copy-body copy-list-indent">
            <ol>
              <li>
                By the date/time the score was submitted. Earlier entries are
                placed higher than later entries.
              </li>
            </ol>
          </p>
        </div>
      </div>
    </>
  );
}

export default GameRules;

import React from "react";
import "../LeaderBoardTest.css";

function LeaderboardTest() {
  return (
    <>
      <div className="lb-test-container">
        <h1 className="lb-test-title">Leaderboard Testing</h1>
        <p className="lb-test-body">
          This is a test for adding leaderboards to a page. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mi sit amet mauris commodo quis imperdiet massa tincidunt. Ullamcorper malesuada proin libero nunc consequat. Porta lorem mollis aliquam ut porttitor leo a. Auctor neque vitae tempus quam pellentesque nec. Quis viverra nibh cras pulvinar mattis. Tellus elementum sagittis vitae et leo duis ut diam. Molestie ac feugiat sed lectus. Lorem ipsum dolor sit amet consectetur adipiscing elit duis tristique. Purus ut faucibus pulvinar elementum integer enim.
        </p>
        <h2 className="lb-test-header center">Here are the leaderboards</h2>
        <wc-omni-arena-leaderboard></wc-omni-arena-leaderboard>
        <div className="leaderboard-flex">
        <wc-omni-arena-custom-leaderboard Leaderboard="646569"></wc-omni-arena-custom-leaderboard>
          {/* <wc-omni-arena-custom-leaderboard Leaderboard="646551"></wc-omni-arena-custom-leaderboard> */}
        </div>
        <wc-omni-arena-custom-leaderboard Leaderboard="646569"></wc-omni-arena-custom-leaderboard>
        <div className="leaderboard-flex">
          <wc-omni-arena-custom-leaderboard Leaderboard="646567"></wc-omni-arena-custom-leaderboard>
        </div>
        <p className="lb-test-body mar-top">
          Here is additional text added after the leaderboards for testing. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mi sit amet mauris commodo quis imperdiet massa tincidunt. Ullamcorper malesuada proin libero nunc consequat. Porta lorem mollis aliquam ut porttitor leo a. Auctor neque vitae tempus quam pellentesque nec. Quis viverra nibh cras pulvinar mattis. Tellus elementum sagittis vitae et leo duis ut diam.
        </p>
      </div>
    </>
  );
}

export default LeaderboardTest;

import React from "react";
import "./LocationSection.css";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import Fade from "react-reveal/Fade";

function LocationSection({
  lightBg,
}) {
  return (
    <div className={lightBg ? "location-section-bg-white" : "location-section-bg"}>
      <div className="location-section-container">
        <Fade bottom duration={800}>
          <h1 className="location-section-header">
            Find an Omni Arena Near You
          </h1>
        </Fade>
        <Fade bottom duration={800}>
          <div className="location-section-subtitle">
            Play the world's most advanced VR experience at a location near you.
          </div>
        </Fade>
        <Link to="/locations">
          <Button buttonSize="btn-large" buttonColor="green">
            See All Locations
            <div className="location-section-right-arrow">
              <img src="/images/arrow-icon-now.svg" alt="arrow" />
            </div>
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default LocationSection;

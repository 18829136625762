import React from 'react';
import LocationsPage from '../../LocationsPage';

function Locations() {
    return (
        <>
        <LocationsPage />
        </>
    )
}

export default Locations
import React from "react";
import ArenaLocationsMap from "./LocationsMap";
import "./LocationsPage.css";
import LazyAnimation from "./LazyAnimation";

export function LocationsPage() {
  return (
    <div className="locations-hero-bg">
      <div className="locations-container">
      <div className="locations-hero">
        <h1 className="locations-title">Where To Play</h1>
        <LazyAnimation></LazyAnimation>
        <h2 className="locations-subtitle">
          Play the world's most advanced VR experience at a location near you.
        </h2>
      </div>
      </div>
      <ArenaLocationsMap />
      
    </div>
  );
}

export default LocationsPage;

import React, { useState, useEffect } from 'react';
import CompetitionSection from '../../CompetitionSection';
import HeroSection from '../../HeroSection';
import HomeHeroSection from '../../HomeHeroSection';
import { homeObjOne, homeObjThree, homeObjFour } from './Data';
import { activeCompetition } from '../../CompetitionSectionData';
import EsportsGames from '../../EsportsGames';
import PvpGames from '../../PvpGames';
import OtherGames from '../../OtherGames';
import { gameCard } from '../../GamesData';
import LocationSection from '../../LocationSection';
import { AccountConfirmModal } from '../../AccountConfirm';
import VideoHeroSection from '../../VideoHeroSection';

function Home() {

    const [accountConfirm, setAccountConfirm] = useState(false);

    let url = window.location.href;

    useEffect(()=>{
        setTimeout(()=>{
            if(url.indexOf('?token') !== -1) {setAccountConfirm(true)}
        }, 0)
      }, [url]);

    return (
        <div>
        <HomeHeroSection {...homeObjOne} />
        <VideoHeroSection />
        <HeroSection {...homeObjThree} />
        <HeroSection {...homeObjFour} />
        <CompetitionSection {...activeCompetition} />
        <EsportsGames {...gameCard} />
        <PvpGames {...gameCard} />
        <OtherGames {...gameCard} />
        <LocationSection />
        <AccountConfirmModal onClose={() => setAccountConfirm(false)} show={accountConfirm} />
        </div>
    )
}

export default Home
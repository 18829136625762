import React from "react";
import "./ContestLandingEnd.css";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

function ContestLandingEnd() {
  return (
    <>
      <div className="contest-landing-end-text contest-landing-end-dark-bg">
        <div className="contest-landing-end-container">
          <div className="contest-landing-end-col">
            <h1 className="contest-landing-end-heading">Play Today and Win!</h1>
              <img
                className="contest-landing-end-img-wrap"
                src="/images/arena_players_silhouette.png"
                alt=""
              />
          </div>
          <div className="contest-landing-end-row">
            <a className="contest-landing-end-link" href="https://virtuix.com/terms-of-use">Terms of Use</a>
            <a className="contest-landing-end-link" href="https://virtuix.com/privacy-policy">Privacy Policy</a>
            <Link className="contest-landing-end-link" to="/contest-rules">Contest Rules</Link>
            <Link className="contest-landing-end-link" to="/game-rules">Game Rules</Link>
          </div>
        </div>
        <hr className="contest-landing-end-hr" />
      </div>
    </>
  );
}

export default ContestLandingEnd;

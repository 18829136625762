import React from "react";
import { GameCardOne, GameCardTwo, GameCardThree, GameCardFour, GameCardFive, GameCardSix, GameCardSeven, GameCardEight } from "./GameCard";
import "./GamesPage.css";
import LazyAnimation from "./LazyAnimation";
import { gameCardOne, gameCardTwo, gameCardThree, gameCardFour, gameCardFive, gameCardSix, gameCardSeven, gameCardEight } from "./GameCardData";
import LocationSection from "./LocationSection";
import GamesPageCarousel from "./GamesPageCarousel";

export function GamesPage() {
  return (
    <>
      <div className="games-hero-bg">
        <div className="games-container">
        <div className="games-hero">
          <h1 className="games-title">Experience Next-Level VR</h1>
          <LazyAnimation></LazyAnimation>
          <h2 className="games-subtitle">
          Walk and run around inside video games.
          </h2>
        </div>
        </div>
      </div>
      <GamesPageCarousel />
      <div className="games-card-container">
        <div className="games-card-row">
          <GameCardOne {...gameCardOne} />
          <GameCardTwo {...gameCardTwo} />
        </div>
        <div className="games-card-row">
          <GameCardThree {...gameCardThree} />
          <GameCardFour {...gameCardFour} />
        </div>        
        <div className="games-card-row">
          <GameCardEight {...gameCardEight} />
          <GameCardFive {...gameCardFive} />
        </div>        
        <div className="games-card-row">
          <GameCardSix {...gameCardSix} />
          <GameCardSeven {...gameCardSeven} />
        </div>
      </div>
      <LocationSection />
    </>
  );
}

export default GamesPage;

import React from "react";
import LeaderboardsSection from "../../LeaderboardsSection";
import { activeCompetition } from '../../CompetitionSectionData';
import LocationSection from "../../LocationSection";

function Leaderboards() {
  return (
    <>
      <LeaderboardsSection {...activeCompetition} />
      <LocationSection />
    </>
  );
}

export default Leaderboards;
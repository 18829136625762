export const contestObjAccelerate = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    headline: 'Experience VR Like Never Before',
    subTitle:
      "Play in Accelerate's Omni Arena contest and win cash!",
    copy: '',
    buttonLabel: '',
    imgStart: '',
    img: '',
    alt: 'Omni One'
  };

export const contestHowAccelerate = {
  contestGame: 'Dead Zone',
  contestGenre: 'zombie shooter',
  contestVenueLocation: 'Accelerate Indoor Speedway in Wisconsin',
  contestEndDate: 'Monday, August 22nd',
}

export const contestAdAccelerate = {
  contestAdImg: '/images/livefeed_accelerate_contest_dz_1920x1080.jpg'
}

export const contestObjYakima = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    headline: 'Experience VR Like Never Before',
    subTitle:
      "Play in Yakima Family Fun Center's Omni Arena contest and win cash!",
    copy: '',
    buttonLabel: '',
    imgStart: '',
    img: '',
    alt: 'Omni One'
  };

export const contestHowYakima = {
  contestGame: 'Gunslinger',
  contestGenre: 'western shooter',
  contestVenueLocation: 'Yakima Family Fun Center in Washington',
  contestEndDate: 'Tuesday, February 28th',
}

export const contestAdYakima = {
  contestAdImg: '/images/livefeed_YakimaFFC_contest_gunslinger_1920x1080.jpg'
}

export const contestObjSacTown = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    headline: 'Experience VR Like Never Before',
    subTitle:
      "Play in Sac Town Family Fun's Omni Arena contest and win cash!",
    copy: '',
    buttonLabel: '',
    imgStart: '',
    img: '',
    alt: 'Omni Arena Contest for Sac Town'
  };

export const contestHowSacTown = {
  contestGame: 'Dead Zone',
  contestGenre: 'zombie shooter',
  contestVenueLocation: 'Sac Town Family Fun in California',
  contestEndDate: 'Sunday, July 2nd',
}

export const contestAdSacTown = {
  contestAdImg: '/images/livefeed_sactown_rcordova_contest_dz_1920x1080.jpg'
}


export const contestObjTokenMtg = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    headline: 'Experience VR Like Never Before',
    subTitle:
      "Play in Token MTG's Omni Arena contest and win cash!",
    copy: '',
    buttonLabel: '',
    imgStart: '',
    img: '',
    alt: 'Omni Arena Contest for Token MTG'
  };

export const contestHowTokenMtg = {
  contestGame: 'Dead Zone',
  contestGenre: 'zombie shooter',
  contestVenueLocation: 'Token MTG in Maryland',
  contestEndDate: 'Sunday, December 31st',
}

export const contestAdTokenMtg = {
  contestAdImg: '/images/livefeed_tokenmtg_contest_img.jpg'
}

import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import "./OmniUpdateSubModal.css";
import "./OmniSubModal.css";
import OmniArenaKlav from "./OmniArenaKlav";

const OmniOneSubModal = (props) => {
  const styleObjWidth22 = {
    width: "22px",
  };

  const pageReload = () => {
    window.location.reload()
  }

  return (
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div
        className={`omni-sub-modal ${props.show ? "show" : ""}`}
        onClick={ pageReload }
      >
        <Link className="close-omni-sub-modal" to="/#">
              <img
                src="/images/x-close-green.svg"
                style={styleObjWidth22}
                alt=""
                onClick={ pageReload }
              />
            </Link>
        <div
          className="omni-update-sub-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="omni-sub-modal-header">
            <h4 className="omni-update-sub-modal-title">{props.title}</h4>
            
          </div>
          <div className="background-green-omni-update-sub-modal">&nbsp;</div>
          <div className="omni-update-sub-modal-body">{props.children}</div>
          <div className="omni-sub-modal-footer">
            <OmniArenaKlav />
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default OmniOneSubModal;

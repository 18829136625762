import React from "react";
import "../../Copy.css";

function TermsOfUse() {
  return (
    <>
      <div className="copy-container">
        <h1 className="copy-title">Omni Arena Player Agreement</h1>
        <p className="copy-body">
        This Omni Arena Player Agreement ("Agreement") governs your use of the Omni Arena attraction, the games and software provided by Omni Arena, the Virtuix Omni motion platform, and the online player site https://arena.virtuix.com (the "Site") (collectively, "Omni Arena"). This Agreement governs your terms of use for Omni Arena. This Agreement is between you and Virtuix Inc. and its affiliates (collectively, "Virtuix" or "we").
        </p>
        <p className="copy-body">
        By clicking the "ACCEPT" button or using the Site, you accept the terms and conditions in this Agreement and also those in the "Privacy Policy" and "Official Rules," which are both defined and discussed below. If you do not accept the terms and conditions in the Agreement, Privacy Policy, and Official Rules, you should discontinue use of Omni Arena. Further, by using Omni Arena, you signify your acknowledgement and agreement to the terms and conditions of this Agreement, the Privacy Policy, and the Official Rules, and any and all amendments and/or modifications thereto.
        </p>
        <h2 className="copy-header">This Agreement Could Change</h2>
        <p className="copy-body">
        Note that this Agreement will change over time, and we will post any modified and/or updated Agreement on the Site. We will not notify you by email, through any Omni Arena service, or by presenting you with a new Agreement to accept. It is your responsibility to check the Site for changes, and when you use Omni Arena after a modification is posted, by your actions, you are telling us that you accept the modified or updated Agreement.
        </p>
        <h2 className="copy-header">Our Privacy Policy</h2>
        <p className="copy-body">
        Virtuix's Omni Arena Privacy Policy (the "Privacy Policy") is found on the Site. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices, including the collection practices of Omni Arena. Our Privacy Policy is incorporated by reference into this Agreement. Your use of Omni Arena is subject to all the terms of our Privacy Policy, so it is your responsibility to read and understand all aspects of our data collection as it relates to your use of Omni Arena. In particular, you agree that Virtuix will collect your personal and gameplay data, and that some of this data may be displayed publicly on Omni Arena leaderboards or rankings, or shared with third parties for marketing purposes.
        </p>
        <h2 className="copy-header">Users</h2>
        <p className="copy-body">
        You may provide the needed Omni Arena data only if you are over 13 years of age and are not barred from receiving services under applicable law. Virtuix does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. 
        </p>
        <h2 className="copy-header">Acknowledgment</h2>
        <p className="copy-body">
        You acknowledge that the Virtuix Omni motion platform is a physical device, and using the Virtuix Omni requires physical exercise. You agree to not use the Virtuix Omni if you are pregnant, intoxicated, epileptic, or recovering from major surgery, illness, or a heart attack. The Virtuix Omni is intended for use by healthy adults between 4'2" (128 cm) and 6'5" (195 cm) tall, and not more than 285 lbs (130 kg). You agree not to use the Virtuix Omni if you do not meet the height and weight requirements, or if you are not healthy enough to engage in moderate to heavy physical exercise. 
        </p>
        <p className="copy-body">
        Exercise can present a health risk. The Virtuix Omni should not be used for longer than one hour at a time. You should consult a physician before beginning any exercise with the Virtuix Omni. Players who feel faint or dizzy should immediately discontinue use of the Virtuix Omni. Some people may have a severe reaction to certain video games or virtual reality environments, including dizziness, seizures, epileptic seizures, or blackouts triggered by light flashes or patterns, even if they have no prior history of seizures or blackouts. If you have had a seizure, loss of awareness, or other symptoms that may be linked to an epileptic disorder, you should consult a physician before using the Virtuix Omni.
        </p>
        <p className="copy-body">
        You agree to follow all instructions and guidelines provides to you during your Omni Arena experience, and you acknowledge that failure to follow these instructions may result in serious bodily injury.
        </p>
        <h2 className="copy-header">Creating a User Account</h2>
        <p className="copy-body">
        Full use of Omni Arena, including participation in Omni Arena prize contests, requires that you create an Omni Arena User Account ("User Account") by providing us with a valid email address, password, and other user information. You are responsible for all activity that occurs in association with your User Account. Virtuix is not liable for any loss or damages caused by your failure to maintain the confidentiality of your User Account credentials, or from any third party interference with your User Account or through your connectivity to Omni Arena.
        </p>
        <p className="copy-body">
        We may need to contact you about your use of Omni Arena. These communications are part of the Omni Arena service and you should not opt-out from receiving them. 
        </p>
        <h2 className="copy-header">Omni Arena Prize Contests</h2>
        <p className="copy-body">
        If you have a valid Omni Arena User Account, you may from time to time participate in official Omni Arena prize contests ("Contests"). Contests are governed by official rules ("Official Rules"), including but not limited to rules governing player eligibility, teams, prize awards, and the conduct of players. The Official Rules are found on the Site. The Official Rules are incorporated by reference into this Agreement. Your participation in any Contests is subject to all the terms of the Official Rules, so it is your responsibility to read and understand all Official Rules.
        </p>
        <h2 className="copy-header">Electronic Communications</h2>
        <p className="copy-body">
        Visiting the Site or sending emails to Virtuix constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing. 
        </p>
        <h2 className="copy-header">International Use</h2>
        <p className="copy-body">
        Although Omni Arena may be accessible worldwide, we make no representation that Omni Arena or related materials are appropriate or available for use in locations outside the United States, and accessing them from territories where the content is illegal is prohibited. Those who choose to access Omni Arena from other locations do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable.
        </p>
        <h2 className="copy-header">Open Source Software</h2>
        <p className="copy-body">
        The current or future Omni Arena software may contain or link to certain software including without limitation "open source software" that is licensed under other software license terms ("Third Party Software"). You agree to any such additional license terms for such Third Party Software and any other content or other materials that Virtuix provides to you, which may be provided within your Omni Arena experience, via your access to the Site, or included as part of this Agreement.
        </p>
        <h2 className="copy-header">
          Links to Third Party Sites/Third Party Services
        </h2>
        <p className="copy-body">
        Certain Omni Arena software may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of Virtuix and Virtuix is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. Virtuix is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by Virtuix of the site or any association with its operators. 
        </p>
        <p className="copy-body">
        Certain services made available via Omni Arena are delivered by third party sites and organizations. By using any product, service or functionality originating from Omni Arena, you hereby acknowledge and consent that Virtuix may share such information and data with any third party with whom Virtuix has a contractual relationship to provide the requested product, service or functionality on behalf of Omni Arena users. 
        </p>
        <h2 className="copy-header">Collection of Data</h2>
        <p className="copy-body">
        As set out in the Omni Arena Privacy Policy, information that you may reveal in your Omni Arena User Account to us or in communication with any other users or in other online discussions regarding your experience in association with Omni Arena is not in any way private. You should think carefully before publicly disclosing any personally identifiable information, or in your Submissions, which are addressed below. You acknowledge and agree that your Omni Arena user profile and gameplay data may be publicly displayed on Omni Arena leaderboards and player rankings.
        </p>
        <p className="copy-body">
        By your use of Omni Arena, you consent to our collection and use of any and all raw data collected from your use of the Virtuix Omni motion platform or Omni Arena attraction, or as collected from your use of any Omni Arena software, and/or as may be sent for processing and storage as well as what is collected in your User Account. Data that is measured and collected as part of Omni Arena could include certain metrics, including but not limited to height, weight, age, gender, waist size, shoe size, steps taken, calories burned, your geographic location, and gameplay achievements and statistics ("Personal Data"). We will use this Personal Data to help improve Omni Arena and your Omni Arena experience. 
        </p>
        <p className="copy-body">
        As set out in our Privacy Policy, we may engage other companies to provide limited services on our behalf to facilitate your experience of our products and services. Virtuix will only provide these companies with those items of your Personal Data needed to deliver such services. These companies may include operators of entertainment locations that offer Omni Arena gameplay and Omni Arena Contests, and such services may include (but are not limited to) marketing of upcoming Omni Arena Contests and other events. We may also share your personal information with third parties in order to fulfill a service or transaction you may have requested. We further reserve the right to delete your Personal Data at any time.
        </p>
        <h2 className="copy-header">Submissions regarding Omni Arena</h2>
        <p className="copy-body">
        Virtuix may now or in the future enable users to transmit and/or submit (submit) contributions (including without limitation any text, photographs, graphics, video or audio material) regarding Omni Arena to Omni Arena operators and services, including the Site. You agree that any feedback, experiences, responses, creative suggestions, ideas, notes, drawings, concepts, or other information communicated verbally, via email, via user forums, via the Site, or via any other means of communication (collectively, the "Submissions") shall be deemed, and shall remain, VIRTUIX  INTELLECTUAL PROPERTY AND/OR VIRTUIX PROPERTY and you shall have no ownership or rights to the Submissions except the use as set forth herein. 
        </p>
        <p className="copy-body">
        FOR THE AVOIDANCE OF DOUBT, ANY AND ALL OF YOUR SUBMISSIONS SHALL BE THE SOLE AND EXCLUSIVE PROPERTY OF VIRTUIX, AND YOU HEREBY ASSIGN ALL OF YOUR RIGHT, TITLE AND INTEREST IN THE SUBMISSIONS, AND ALL INTELLECTUAL PROPERTY RIGHTS RELATED THERETO TO VIRTUIX AS SET FORTH HEREIN. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, YOU AGREE THAT VIRTUIX, INC. AND ITS AFFILIATES AND/OR ITS LICENSEES SHALL HAVE THE PERPETUAL AND IRREVOCABLE RIGHT TO USE, MODIFY, AND OTHERWISE EXPLOIT ALL OR PART OF YOUR SUBMISSIONS OR ANY DERIVATIVE THEREOF IN ANY MANNER OR MEDIA NOW KNOWN OR HEREAFTER DEVISED WITHOUT ANY REMUNERATION, COMPENSATION OR CREDIT TO YOU. YOU HEREBY REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT TO ASSIGN AND GRANT THE RIGHTS SET FORTH HEREIN, AND THAT ANY SUBMISSION WHICH IS PROVIDED BY YOU HEREUNDER IS ORIGINAL WORK MADE SOLELY BY YOU AND DOES NOT INFRINGE ANY THIRD PARTY INTELLECTUAL PROPERTY RIGHTS.
        </p>
        <p className="copy-body">
        Whenever you make use of a feature that allows you to submit Submissions or to make contact with other users of Omni Arena, you must comply with the content standards set out in the Code of Conduct set forth below. In the event any of your Submissions fail to so comply with the Code of Conduct, you will fully reimburse Virtuix for any resulting costs, including consequential damages.
        </p>
        <h2 className="copy-header">CODE OF CONDUCT</h2>
        <p className="copy-body">
        The following rules are not meant to be exhaustive, and Virtuix reserves the right to determine which conduct is outside the spirit of these rules and to take such disciplinary measures as Virtuix sees fit. 
        </p>
        <p className="copy-body">
        I.  You will not advertise or sell any products, services or otherwise (whether or not for profit), solicit others (including, without limitation, solicitations for contributions or donations) or use the Site or other Omni Arena services for commercial purposes or fundraising of any kind.
        </p>
        <p className="copy-body">
        II.  You will not submit, post, upload, distribute, or otherwise make available or transmit any software or other computer files that contain a virus or other harmful component, or otherwise impair or damage Omni Arena, related services, or any connected network, or otherwise interfere with any person or entity's use or enjoyment of Omni Arena.
        </p>
        <p className="copy-body">
        III.  You will not impersonate any person or entity or misrepresent your identity or affiliation with any person or entity including, without limitation, using another person's username, password or other account information, or another person's name, likeness, voice, image or photograph.
        </p>
        <p className="copy-body">
        IV.  You will not engage in antisocial, disruptive, or destructive acts, including "flaming," "spamming," "flooding," "trolling," and "griefing" as those terms are commonly understood and used on the Internet.
        </p>
        <p className="copy-body">
        V.  You will not submit, post, upload, distribute or otherwise make available or transmit any illegal content, or make statements that do not generally pertain to the designated topic or theme of the forum.
        </p>
        <p className="copy-body">
        VI.  You will not engage in (or attempt to engage in) any criminal activity including, without limitation, child pornography, stalking, sexual assault, fraud, trafficking in obscene or stolen material, drug dealing, harassment, theft, and conspiracy to commit any criminal activity in connection with the service and/or our website.
        </p>
        <p className="copy-body">
        VII.  You will not communicate or post any user's personal information in or on Omni Arena services or forums related to Virtuix.
        </p>
        <p className="copy-body">
        VIII.  You will not post any content or language which, in the sole and absolute discretion of Virtuix, is deemed to be offensive, including without limitation content or language that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, hateful, sexually explicit, or racially, ethnically or otherwise objectionable.
        </p>
        <h2 className="copy-header">Enforcement Rights and Termination</h2>
        <p className="copy-body">
        We are not obligated to monitor access or use of Omni Arena, or your Submissions, but we have the right to do so for the purpose of ensuring compliance with this Agreement, and to comply with applicable law or other legal requirements. We may consult with and disclose unlawful conduct to law enforcement authorities; and pursuant to valid legal process, we may cooperate with law enforcement authorities to prosecute users who violate the law. We reserve the right to remove, delete, or disable your Omni Arena user profile, your access to Omni Arena, your Omni Arena data, or your Submissions at any time and without notice, and at our sole discretion.  In the event your use of Omni Arena is terminated, termination of this Agreement for any reason shall not release either party from any liabilities or obligations set forth in this Agreement that remain to be performed or by their nature would be intended to be applicable following any such termination.
        </p>
        <h2 className="copy-header">Reliance On information</h2>
        <p className="copy-body">
        Information published via Omni Arena related services is intended solely for the purpose of providing general information and is not intended to amount to advice on which reliance should be placed. We do not warrant that any materials are accurate or free from error and we disclaim all liability and responsibility arising from any reliance placed on any content on Omni Arena.
        </p>
        <p className="copy-body">
        You acknowledge that when using Omni Arena you may be exposed to materials from a variety of sources and that we are not responsible for the content, truth, accuracy, usefulness or safety of such materials. 
        </p>
        <p className="copy-body">
        We may change the content of Omni Arena at any time. We may suspend access to our services, or close them indefinitely at any time and at our sole discretion. The materials may be out of date at any given time and we are under no obligation to update them.
        </p>
        <p className="copy-body">
        You may not link to Omni Arena in a way that is unfair, illegal, damages our reputation, or takes advantage of it. You may not establish a link in any way that suggests any form of association, representation, approval, or endorsement on our part where none exists. 
        </p>
        <h2 className="copy-header">Seizure Warning</h2>
        <p className="copy-body">
        In rare instances, some users may experience seizures when exposed to certain light patterns or flashing lights associated with Omni Arena or any accessories to either Omni Arena or the Virtuix Omni, including but not limited to any headset used within Omni Arena. Exposure to certain light patterns or backgrounds on a video monitor may induce an epileptic seizure, even in persons who have no history of prior seizures or epilepsy. Users of any third-party accessories within Omni Arena, including but not limited to headsets, should carefully review all health and safety warnings and/or instruction from the manufacturer.
        </p>
        <h2 className="copy-header">Disclaimers</h2>
        <p className="copy-body">
        ALL OMNI ARENA SOFTWARE AND HARDWARE IS PROVIDED "AS IS," WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. We do not offer any warranties or guarantees in relation to Omni Arena configuration or error/defect correction. We make no warranty that any Omni Arena software or hardware will meet your requirements or be available on an uninterrupted, secure, or error-free basis. We make no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness or reliability of any Omni Arena software or hardware. You acknowledge and agree that if you rely on any Omni Arena software or hardware, you do so solely at your own risk.
        </p>
        <p className="copy-body">
        THIS DISCLAIMER SHALL NOT BE APPLICABLE SOLELY TO THE EXTENT THAT ANY SPECIFIC PROVISION OF THIS DISCLAIMER IS PROHIBITED BY ANY FEDERAL, STATE, OR MUNICIPAL LAW. 
        </p>
        <p className="copy-body">
        WE DO NOT AND CANNOT CONTROL THE FLOW OF DATA TO OR FROM OUR NETWORK AND OTHER PORTIONS OF THE INTERNET, WIRELESS NETWORKS, OR OTHER THIRD-PARTY NETWORKS. SUCH FLOW DEPENDS IN LARGE PART ON THE PERFORMANCE OF THE INTERNET AND WIRELESS SERVICES PROVIDED OR CONTROLLED BY THIRD PARTIES. AT TIMES, ACTIONS OR INACTIONS OF SUCH THIRD PARTIES MAY IMPAIR OR DISRUPT YOUR CONNECTIONS TO THE INTERNET, WIRELESS SERVICES, OR PORTIONS THEREOF. WE CANNOT GUARANTEE THAT SUCH EVENTS WILL NOT OCCUR. ACCORDINGLY, WE DISCLAIM ANY AND ALL LIABILITY RESULTING FROM OR RELATED TO THIRD-PARTY ACTIONS OR INACTIONS THAT IMPAIR OR DISRUPT YOUR CONNECTIONS TO THE INTERNET, WIRELESS SERVICES, OR PORTIONS THEREOF OR THE USE OF THE SOFTWARE AND RELATED SERVICES AND PRODUCTS.
        </p>
        <p className="copy-body">
        FINALLY, BECAUSE SOME STATES/COUNTRIES DO NOT ALLOW LIMITATIONS ON LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, DEATH, OR PERSONAL INJURY RESULTING FROM NEGLIGENCE, FRAUD, OR WILLFUL MISCONDUCT, THESE LIMITATIONS AND/OR EXCLUSIONS AND ANY EXCLUSION OR LIMITATION TO THIS AGREEMENT, INCLUDING WARRANTIES SET FORTH HEREIN, OR OTHERWISE MAY NOT APPLY TO YOU. 
        </p>
        <h2 className="copy-header">Indemnity</h2>
        <p className="copy-body">
        You will indemnify and hold harmless (i) Virtuix, (ii) its manufacturers, suppliers, agents, licensors, licensees, and affiliates, (iii) the operators and distributors of the Virtuix Omni motion platform and Omni Arena attraction, and (iv) for each of the foregoing, their respective officers, directors, employees and agents ("Indemnified Parties"), from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable attorneys' fees arising out of or in any way connected with any claim(s) (collectively, "Claims") resulting from (i) any injury to person or damage to or loss of property resulting from your access to or use of or inability to use Omni Arena, including use of the Virtuix Omni motion platform, whether or not caused by you and/or users of the Virtuix Omni or Omni Arena, (ii) any acts and/or omission to act in using Omni Arena pursuant to the terms of this Agreement (iii) your Submissions, or (iv) your breach of any warranties or obligations made by you hereunder or your violation of any other provision of this Agreement or the Official Rules. We reserve the right to assume control of the defense of any third-party claim that is subject to indemnification by you, in which event you will cooperate with us in asserting any available defenses. For the avoidance of doubt, you acknowledge and agrees that the indemnification provided herein to the Indemnified Parties is full and to the greatest extent allowed by all Applicable Laws. For purposes of this section, "Applicable Laws" means all applicable statutes, laws, rules and regulations of any applicable governmental authority, instrumentality or agency, whether promulgated by any state or governmental entity of an applicable territory or any government in the world.
        </p>
        <p className="copy-body">
        For the avoidance of doubt, you acknowledge and agree that the foregoing indemnification of the Indemnified Parties includes indemnification from and against any Claims resulting from any personal injury to you or theft of your property resulting from your use of the Virtuix Omni motion platform or the Omni Arena.
        </p>
        <h2 className="copy-header">Limitation of Liability</h2>
        <p className="copy-body">
          <b>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, VIRTUIX AND ITS AFFILIATES SHALL NOT BE LIABLE TO YOU OR ANYONE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING FROM ANY CAUSES OF ACTION WITH RESPECT TO THIS AGREEMENT AND THE OMNI ARENA SOFTWARE AND HARDWARE ASSOCIATED WITH THIS AGREEMENT, WHETHER ARISING IN TORT (INCLUDING NEGLIGENCE), CONTRACT, STRICT LIABILITY OR OTHERWISE, WHETHER OR NOT YOU HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  FOR THE AVOIDANCE OF DOUBT, VIRTUIX AND ITS AFFILIATES WILL NOT BE LIABLE TO YOU OR ANYONE FOR ANY AND ALL LOSSES RELATED TO PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, LOSS OF GOODWILL, COMPUTER OR OPERATIONAL FAILURE OR MALFUNCTION, LOSS OF DATA OR ANY OTHER FORM OF DAMAGES (INCLUDING THOSE SET OUT IN THE PRECEDING SENTENCE) FROM ANY CAUSES OF ACTION ARISING OUT OF OR RELATED TO OMNI ARENA. IN NO EVENT SHALL VIRTUIX AND/OR ITS AFFILIATES' AND/OR ITS LICENSORS' OR LICENSEES' AGGREGATE LIABILITY TO YOU UNDER THIS AGREEMENT EXCEED THE PRICE YOU PAID FOR ACCESS TO PLAY OMNI ARENA AT A COMMERCIAL ENTERTAINMENT VENUE.  THIS SECTION AND THE PROVISIONS CONTAINED HEREIN ARE A CONDITION OF THIS AGREEMENT AND DELINEATE THE MAXIMUM POTENTIAL LIABILITY OF VIRTUIX AND/OR ITS AFFILIATES AND/OR ITS LICENSORS OR LICENSEES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF OMNI ARENA, OR WITH ANY OF THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING OMNI ARENA. 
          </b>
        </p>
        <h2 className="copy-header">General Terms</h2>
        <p className="copy-body">
        Except as otherwise stated herein, this Agreement, the Privacy Policy, and the Official Rules constitute the entire and exclusive understanding and agreement between Virtuix and you regarding Omni Arena, and this Agreement, the Privacy Policy, and the Official Rules supersede and replace any and all prior oral or written understandings or agreements between Virtuix and you regarding Omni Arena.
        </p>
        <p className="copy-body">
        To the maximum extent permitted by law, the construction, validity, and performance of these Official Rules is governed by the laws of the State of Texas in all disputes arising out of or relating to your participation in the Contests or these Official Rules. As a condition of your participation in the Contests, you hereby consent to Texas law, excluding Texas' choice-of-law provisions. Litigation of disputes under this Agreement shall be conducted in courts located in the City of Austin, Texas. The parties hereto consent to the jurisdiction of any local, state, or federal court in which an action is commenced and located in accordance with the terms of this Section and that is located in Austin, Texas. The parties further agree not to disturb such choice of forum, and if not resident in such state, waive the personal service of any and all process upon them, and consent that such service of process may be made by certified or registered mail, return receipt requested, addressed to the parties as set forth herein.
        </p>
        <p className="copy-body">
        If any of the provisions of this Agreement are held by a court or other tribunal of competent jurisdiction to be void or unenforceable, such provisions shall be limited or eliminated to the minimum extent necessary and replaced with a valid provision that best embodies the intent of the term or provision in the Agreement, so that this Agreement shall remain in full force and effect. 
        </p>
        <p className="copy-body">
        You may not assign or transfer this Agreement, by operation of law or otherwise, without Virtuix's prior written consent. Any attempt by you to assign or transfer this Agreement, without such consent, will be null. Virtuix may freely assign or transfer this Agreement without restriction. Subject to the foregoing, this Agreement will bind and inure to the benefit of the parties, their successors and permitted assigns.
        </p>
        <p className="copy-body">
        Virtuix's failure to enforce any right or provision of this Agreement will not be considered a waiver of such right or provision. The waiver of any such right or provision will be effective only if in writing and signed by the CEO of Virtuix, Inc. Except as expressly set forth in this Agreement, the exercise by either party of any of its remedies under this Agreement will be without prejudice to its other remedies under this Agreement or otherwise.
        </p>
        <h2 className="copy-header">Contact Us</h2>
        <p className="copy-body">
          Please contact us if you have any questions about this Agreement at{" "}
          <a
            className="copy-link-gray"
            href="mailto:info@virtuix.com"
            target="_blank"
            rel="noreferrer"
          >
            info@virtuix.com
          </a>
          .
        </p>
        <p className="copy-body">Last revision: April 25, 2022</p>
      </div>
    </>
  );
}

export default TermsOfUse;

import React, { useEffect, useState } from "react";
import "./ContestLanding.css";
import { contestObjAccelerate, contestAdAccelerate, contestHowAccelerate } from './ContestLandingData';
import ContestLandingAd from "../../ContestLandingAd";
import ContestLandingHeroSection from "../../ContestLandingHeroSection";
import ContestLandingHomeHero from "../../ContestLandingHomeHero";
import ContestLandingHowTo from "../../ContestLandingHowTo";
import ContestLandingEnd from "../../ContestLandingEnd";
import { Helmet } from "react-helmet";

function ContestLanding() {
  return (
    <>
    <Helmet>
        <meta name="robots" content="noindex" />
    </Helmet>
      <ContestLandingHomeHero {...contestObjAccelerate} />
      <ContestLandingAd {...contestAdAccelerate}/>
      <ContestLandingHeroSection />
      <ContestLandingHowTo {...contestHowAccelerate} />
      <ContestLandingEnd />
    </>
  );
}

export default ContestLanding;
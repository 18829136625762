export const gameCardOne = {
    img: '/images/gamecard_img_deadzone.jpg',
    caption: 'Cooperative Shooter | Players: 1-4',
    gameTitle: 'Dead Zone: Zombies',
    subTitle: 'Enter the Dead Zone and clear out the zombie horde!',
    description: 'Compete for kills, find supplies, and work together with your teammates to survive.',
    buttonLabel: 'Watch Trailer',
    alt: 'Dead Zone',
  };

  export const gameCardTwo = {
    img: '/images/gamecard_img_eliteforce.jpg',
    caption: 'Cooperative Shooter | Players: 1-4',
    gameTitle: 'Elite Force',
    subTitle: 'Enter the VR battlefield!',
    description: 'Join the Elite Force and fight against your friends in this military shooter deathmatch. Eliminate the most enemies to win.',
    buttonLabel: 'Watch Trailer',
    alt: 'Elite Force',
  };

  export const gameCardThree = {
    img: '/images/gamecard_img_gunslinger.jpg',
    caption: 'Cooperative Shooter | Players: 1-4',
    gameTitle: 'Gunslinger: Outlaw Shootout',
    subTitle: 'Defend the town of Brightstone from a gang of plundering bandits.',
    description: "Get ready to fire double-barreled shotguns, kill outlaws, and blow up some dynamite! Protect the town's resources to achieve a high score.",
    buttonLabel: 'Watch Trailer',
    alt: 'Gunslinger',
  };

  export const gameCardFour = {
    img: '/images/gamecard_img_paranormal.jpg',
    caption: 'Cooperative Shooter | Players: 1-4',
    gameTitle: 'Paranormal: Enter The Manor',
    subTitle: 'Explore a VR haunted house.',
    description: 'Up to four players enter a haunted house together to investigate supernatural activity. As they make their way through the majestic mansion, it becomes clear they’re not alone…',
    buttonLabel: 'Watch Trailer',
    alt: 'Paranormal',
  };

  export const gameCardFive = {
    img: '/images/gamecard_img_blackbeard.jpg',
    caption: 'Cooperative Shooter | Players: 1-4',
    gameTitle: 'Blackbeard: Trials of Skull Island',
    subTitle: 'Prove your worth in the trials of Skull Island!',
    description: 'Loot treasure, defeat foes, and overcome special challenges to become a true pirate legend!',
    buttonLabel: 'Watch Trailer',
    alt: 'Blackbeard',
  };

  export const gameCardSix = {
    img: '/images/gamecard_img_coredefense.jpg',
    caption: 'Cooperative Shooter | Players: 1-4',
    gameTitle: 'Core Defense',
    subTitle: 'Defend the Powercore from attacking robots!',
    description: 'Survive waves of robots and defend the Powercore in Core Defense. Team up with other players and go for the high score on the leaderboard!',
    buttonLabel: 'Watch Trailer',
    alt: 'Core Defense',
  };

  export const gameCardSeven = {
    img: '/images/gamecard_img_hardpoint.jpg',
    caption: 'Cooperative Shooter | Players: 1-4',
    gameTitle: 'Hardpoint',
    subTitle: 'Compete against your friends in this PvP shooter.',
    description: 'Score points by occupying the Hardpoint in the arena. The team with the most points at the end of the match wins!',
    buttonLabel: 'Watch Trailer',
    alt: 'Hardpoint',
  };

  export const gameCardEight = {
    img: '/images/gamecard_img_rhythmmaster.jpg',
    caption: 'Rhythm & Dancing | Players: 1-4',
    gameTitle: 'Rhythm Master',
    subTitle: 'Slice the beats!',
    description: 'Walk to the tempo of the music and slice through blocks to earn points. Compete with friends for the top score!',
    buttonLabel: 'Watch Trailer',
    alt: 'Rhythm Master',
  };
import React, { useRef } from "react";
import "./LocationsPage.css";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";

const greenIcon = new Icon({
  iconUrl: "images/marker-green.png",
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const center = [37.2664, -99.6369];
const zoom = 4;
const llLaunchPrattville = [32.4889, -86.413];
const llJakesUnlimitedMesa = [33.37998985204505, -111.79098272675664];
const llNoSurrenderClovis = [36.806684011177076, -119.69352195663939];
const llEpicenterSantaRosa = [38.46749207122926, -122.7465794191758];
const llGatorMikesCapeCoral = [26.655111255582842, -81.97017068657294];
const llAndrettiMarietta = [33.95216097539878, -84.51539748039437];
const llPolePositionDesMoines = [41.67814186485581, -93.78034219793498];
const llSpareTimeFortRiley = [39.11157320981388, -96.81581256201521];
const llFullThrottleFlorence = [39.01628859525696, -84.64252182873251];
const llScaryStrokesWaldorf = [38.630926121636406, -76.913460899612];
const llBigPlayBiloxi = [30.394278154873508, -88.9479516005498];
const llK1SpeedLasVegas = [36.114239294112025, -115.19987537355965];
const llVictoryLaneCharlotte = [35.271074960775906, -80.84354132899486];
const llFullThrottleCincinnati = [39.29067345219098, -84.45452132950527];
const llZoneSanJuan = [18.40835351381376, -66.10548919911035];
const llCharlestonFunMountPleasant = [32.86803841437107, -79.7795388664889];
const llAndrettiKaty = [29.787522375412955, -95.77006812880263];
const llCinergyOdessa = [31.92464557457363, -102.28464839969656];
const llDaveBustersAustin = [30.38343330852895, -97.73747215807757];
const llDaveBustersHouston = [29.78641001822767, -95.46612827341534];
const llPinballzLakeCreek = [30.45995045684183, -97.79022450734148];
const llSchulmansSherman = [33.59506119826191, -96.6025198999038];
const llK1SpeedNashville = [36.16068314609185, -86.76222354165654];
const llEpicenterPuyallup = [47.11706885931601, -122.29255669981491];
const llAccelerateWaukesha = [43.05006867294663, -88.20564106498456];
const llBackAlleyDaytona = [29.164011308144136, -81.00089842954856];
const llAdventureZoneDuluth = [46.78288429829123, -92.09564757034373];
const llVortexNiagara = [43.08749890633652, -79.06416602702632];
const llXtremeActionFtLauderdale = [26.192296394402423, -80.15352075770305];
const llTheDivrgence = [34.719705228314425, -86.60706835578128];
const llLaurelLanesMapleShade = [39.93949552756869, -74.97430407116815];
const llStarLanesColumbus = [40.143155366411335, -82.97806739879896];
const llCrossroadsBeaumont = [30.119734244110358, -94.15946532879892];
const llLaunchGurnee = [42.37150007911159, -87.95295325767982];
const llMb2SantaClarita = [34.41583695466018, -118.5114705670119];
const llOasisLanesElPaso = [31.748228879580125, -106.28142322196214];
const llAutobahnJacksonville = [30.255424413393268, -81.60238628867698];
const llLakeAreaLakeCharles = [30.16772510962072, -93.2394678275487];
const llLaunchLeominster = [42.52913332773431, -71.73988111610213];
const llAccelerateMokena = [41.55028328624172, -87.82623981747058];
const llOwynnsWorldLitchfieldPark = [33.50927961168276, -112.34243886366109];
const llSacTownRanchoCordova = [38.63038229658675, -121.21551629738983];
const ll1upEntertainmentTampa = [28.055518941917917, -82.50617348501197];
const llK1SpeedLeesSummit = [38.96573261738954, -94.36027779923683];
const llWarriorZoneFtCampbell = [36.664019863050406, -87.46819129934214];
const llQuakeKennewick = [46.19522160835188, -119.27259428524297];
const llScandiaFairfield = [38.22491351576323, -122.12392615825792];
const llTokenMtgEdgewater = [38.928154251451815, -76.56332618743663];
const llFastLaneBoise = [43.61866817533417, -116.30990042938775];
const llPremiereBryan = [30.67593969418392, -96.33648260071404];
const llThunderRoadSiouxFalls = [43.54892971294645, -96.7617992304856];
const llCinemaSocialCorbin = [36.91714554448758, -84.12546219999868];
const llFlight509Spokane = [47.680210553939204, -117.26395271250266];
const llGrandSlamCoonRapids = [45.17376280000001, -93.33964429999999];

export function ArenaLocationsMap() {
  const mapRef = useRef();

  function handleFlyTollLaunchPrattville() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llLaunchPrattville, 13, {
      duration: 2,
    });
  }

  function handleFlyTollJakesUnlimitedMesa() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llJakesUnlimitedMesa, 15, {
      duration: 2,
    });
  }

  function handleFlyTollNoSurrenderClovis() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llNoSurrenderClovis, 15, {
      duration: 2,
    });
  }

  function handleFlyTollEpicenterSantaRosa() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llEpicenterSantaRosa, 15, {
      duration: 2,
    });
  }

  function handleFlyTollGatorMikesCapeCoral() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llGatorMikesCapeCoral, 14, {
      duration: 2,
    });
  }

  function handleFlyTollAndrettiMarietta() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llAndrettiMarietta, 15, {
      duration: 2,
    });
  }

  function handleFlyTollPolePositionDesMoines() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llPolePositionDesMoines, 15, {
      duration: 2,
    });
  }

  function handleFlyTollSpareTimeFortRiley() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llSpareTimeFortRiley, 15, {
      duration: 2,
    });
  }

  function handleFlyTollFullThrottleFlorence() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llFullThrottleFlorence, 15, {
      duration: 2,
    });
  }

  function handleFlyTollScaryStrokesWaldorf() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llScaryStrokesWaldorf, 15, {
      duration: 2,
    });
  }

  function handleFlyTollBigPlayBiloxi() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llBigPlayBiloxi, 14, {
      duration: 2,
    });
  }

  function handleFlyTollK1SpeedLasVegas() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llK1SpeedLasVegas, 15, {
      duration: 2,
    });
  }

  function handleFlyTollVictoryLaneCharlotte() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llVictoryLaneCharlotte, 15, {
      duration: 2,
    });
  }

  function handleFlyTollFullThrottleCincinnati() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llFullThrottleCincinnati, 15, {
      duration: 2,
    });
  }

  function handleFlyTollZoneSanJuan() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llZoneSanJuan, 15, {
      duration: 2,
    });
  }

  function handleFlyTollCharlestonFunMountPleasant() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llCharlestonFunMountPleasant, 14, {
      duration: 2,
    });
  }

  function handleFlyTollAndrettiKaty() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llAndrettiKaty, 15, {
      duration: 2,
    });
  }

  function handleFlyTollCinergyOdessa() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llCinergyOdessa, 15, {
      duration: 2,
    });
  }

  function handleFlyTollDaveBustersAustin() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llDaveBustersAustin, 15, {
      duration: 2,
    });
  }

  function handleFlyTollDaveBustersHouston() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llDaveBustersHouston, 15, {
      duration: 2,
    });
  }

  function handleFlyTollPinballzLakeCreek() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llPinballzLakeCreek, 14, {
      duration: 2,
    });
  }

  function handleFlyTollSchulmansSherman() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llSchulmansSherman, 13, {
      duration: 2,
    });
  }

  function handleFlyTollK1SpeedNashville() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llK1SpeedNashville, 15, {
      duration: 2,
    });
  }

  function handleFlyTollEpicenterPuyallup() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llEpicenterPuyallup, 15, {
      duration: 2,
    });
  }

  function handleFlyTollAccelerateWaukesha() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llAccelerateWaukesha, 14, {
      duration: 2,
    });
  }

  function handleFlyTollBackAlleyDaytona() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llBackAlleyDaytona, 14, {
      duration: 2,
    });
  }

  function handleFlyTollAdventureZoneDuluth() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llAdventureZoneDuluth, 14, {
      duration: 2,
    });
  }
  
  function handleFlyTollVortexNiagara() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llVortexNiagara, 14, {
      duration: 2,
    });
  }

  function handleFlyTollXtremeActionFtLauderdale() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llXtremeActionFtLauderdale, 14, {
      duration: 2,
    });
  }

  function handleFlyTollTheDivrgence() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llTheDivrgence, 14, {
      duration: 2,
    });
  }

  function handleFlyTollLaurelLanesMapleShade() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llLaurelLanesMapleShade, 14, {
      duration: 2,
    });
  }
  
  function handleFlyTollStarLanesColumbus() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llStarLanesColumbus, 14, {
      duration: 2,
    });
  }
  
  function handleFlyTollCrossroadsBeaumont() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llCrossroadsBeaumont, 14, {
      duration: 2,
    });
  }

  function handleFlyTollLaunchGurnee() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llLaunchGurnee, 14, {
      duration: 2,
    });
  }

  function handleFlyTollMb2SantaClarita() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llMb2SantaClarita, 14, {
      duration: 2,
    });
  }

  function handleFlyTollOasisLanesElPaso() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llOasisLanesElPaso, 14, {
      duration: 2,
    });
  }

  function handleFlyTollAutobahnJacksonville() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llAutobahnJacksonville, 14, {
      duration: 2,
    });
  }

  function handleFlyTollLakeAreaLakeCharles() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llLakeAreaLakeCharles, 14, {
      duration: 2,
    });
  }

  function handleFlyTollLaunchLeominster() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llLaunchLeominster, 14, {
      duration: 2,
    });
  }

  function handleFlyTollAccelerateMokena() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llAccelerateMokena, 14, {
      duration: 2,
    });
  }

  function handleFlyTollOwynnsWorldLitchfieldPark() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llOwynnsWorldLitchfieldPark, 14, {
      duration: 2,
    });
  }

  function handleFlyTollSacTownRanchoCordova() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llSacTownRanchoCordova, 14, {
      duration: 2,
    });
  }

  function handleFlyToll1upEntertainmentTampa() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(ll1upEntertainmentTampa, 14, {
      duration: 2,
    });
  }

  function handleFlyTollK1SpeedLeesSummit() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llK1SpeedLeesSummit, 14, {
      duration: 2,
    });
  }

  function handleFlyTollWarriorZoneFtCampbell() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llWarriorZoneFtCampbell, 14, {
      duration: 2,
    });
  }

  function handleFlyTollQuakeKennewick() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llQuakeKennewick, 14, {
      duration: 2,
    });
  }

  function handleFlyTollScandiaFairfield() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llScandiaFairfield, 14, {
      duration: 2,
    });
  }

  function handleFlyTollTokenMtgEdgewater() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llTokenMtgEdgewater, 14, {
      duration: 2,
    });
  }

  function handleFlyTollFastLaneBoise() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llFastLaneBoise, 14, {
      duration: 2,
    });
  }

  function handleFlyTollPremiereBryan() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llPremiereBryan, 14, {
      duration: 2,
    });
  }

  function handleFlyTollThunderRoadSiouxFalls() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llThunderRoadSiouxFalls, 14, {
      duration: 2,
    });
  }

  function handleFlyTollCinemaSocialCorbin() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llCinemaSocialCorbin, 14, {
      duration: 2,
    });
  }

  function handleFlyTollFlight509Spokane() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llFlight509Spokane, 14, {
      duration: 2,
    });
  }

  function handleFlyTollGrandSlamCoonRapids() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.flyTo(llGrandSlamCoonRapids, 14, {
      duration: 2,
    });
  }

  return (
    <div className="locations-map-container">
      <div className="locations-leaflet-container">
      <Map ref={mapRef} center={center} zoom={zoom}>
        {/* <TileLayer url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png" /> */}
        {/* <TileLayer url="https://api.mapbox.com/styles/v1/virtuix/cl1nq9pqi000m14qq1ydek0yj/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidmlydHVpeCIsImEiOiJjbDFucWJ5Z2YweDJyM3FyenhwcmtyeDJzIn0.gaLdbhFAk-gEJwvV_347FQ" /> */}
        <TileLayer url="https://api.mapbox.com/styles/v1/virtuix/cl1nvaei0002814ox993chzgc/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidmlydHVpeCIsImEiOiJjbDFucWJ5Z2YweDJyM3FyenhwcmtyeDJzIn0.gaLdbhFAk-gEJwvV_347FQ" />
        <Marker icon={greenIcon} position={llLaunchPrattville}>
          <Popup>
            Launch Trampoline Park
            <br />
            891 Boardroom Drive
            <br />
            Prattville, AL 36066
            <br />
            <a
              href="https://www.google.com/maps/dir//891+Boardroom+Dr,+Prattville,+AL+36066/@32.4890911,-86.4153037,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x888e9aac87ed1fdb:0xe69f82b7d998a215!2m2!1d-86.413115!2d32.4890911"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llJakesUnlimitedMesa}>
          <Popup>
            Jake's Unlimited
            <br />
            1830 E Baseline Rd
            <br />
            Mesa, AZ 85204
            <br />
            <a
              href="https://www.google.com/maps/dir//Jake's+Unlimited,+1830+E+Baseline+Rd,+Mesa,+AZ+85204/@33.3799003,-111.7931607,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x872ba8ef501c5d1d:0xafa6ee04f234b36f!2m2!1d-111.790972!2d33.3799003"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llNoSurrenderClovis}>
          <Popup>
            No Surrender Adventure Park
            <br />
            1200 Shaw Ave #103
            <br />
            Clovis, CA 93612
            <br />
            <a
              href="https://www.google.com/maps/dir//No+Surrender+Adventure+Park,+1200+Shaw+Ave+%23103,+Clovis,+CA+93612/@36.8066411,-119.6956892,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8094678a5c352dd3:0xc514d0f75ec980f7!2m2!1d-119.6935005!2d36.8066411"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llEpicenterSantaRosa}>
          <Popup>
            Epicenter Sports and Entertainment
            <br />
            3215 Coffey Ln
            <br />
            Santa Rosa, CA 95403
            <br />
            <a
              href="https://www.google.com/maps/dir//Epicenter+Sports+and+Entertainment,+3215+Coffey+Ln,+Santa+Rosa,+CA+95403/@38.4673157,-122.7484248,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x808438f67573a781:0xd579a20592440c9c!2m2!1d-122.7465737!2d38.467433"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llGatorMikesCapeCoral}>
          <Popup>
            Gator Mike's Family Fun Park
            <br />
            35 NE Pine Island Rd
            <br />
            Cape Coral, FL 33909
            <br />
            <a
              href="https://www.google.com/maps/dir//Gator+Mike's+Family+Fun+Park,+35+NE+Pine+Island+Rd,+Cape+Coral,+FL+33909/@26.6551017,-81.9723272,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88db46a39100001d:0x6b55229db8ace830!2m2!1d-81.9701385!2d26.6551017"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llAndrettiMarietta}>
          <Popup>
            Andretti Indoor Karting & Games
            <br />
            1255 Roswell Rd
            <br />
            Marietta, GA 30062
            <br />
            <a
              href="https://www.google.com/maps/dir//Andretti+Indoor+Karting+and+Games+Marietta,+1255+Roswell+Rd,+Marietta,+GA+30062/@33.9524458,-84.5175218,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88f513fa6e811a9f:0x8b10115fa10f9bd3!2m2!1d-84.515309!2d33.9520161?hl=en-US"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llPolePositionDesMoines}>
          <Popup>
            Pole Position Raceway
            <br />
            1350 SE Gateway Dr # 108
            <br />
            Grimes, IA 50111
            <br />
            <a
              href="https://www.google.com/maps/dir//Pole+Position+Raceway+Des+Moines,+1350+SE+Gateway+Dr+%23+108,+Grimes,+IA+50111/@41.6780778,-93.7825309,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x87ec27b6405647e1:0xfda40b8349726091!2m2!1d-93.7803422!2d41.6780778?hl=en-US"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llSpareTimeFortRiley}>
          <Popup>
            SpareTime Interactive Entertainment
            <br />
            7485 Normandy Dr
            <br />
            Fort Riley, KS 66442
            <br />
            <a
              href="https://www.google.com/maps/dir//SpareTime+Interactive+Entertainment,+7485+Normandy+Dr,+Fort+Riley,+KS+66442/@39.1114234,-96.8176794,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x87bc4b47cc1eb55f:0x54dd14196cb80c0e!2m2!1d-96.8157979!2d39.1115765?hl=en-US"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llFullThrottleFlorence}>
          <Popup>
            Full Throttle Adrenaline Park
            <br />
            24 Spiral Dr
            <br />
            Florence, KY 41042
            <br />
            <a
              href="https://www.google.com/maps/dir//Full+Throttle+Adrenaline+Park+-+Florence,+24+Spiral+Dr,+Florence,+KY+41042/@39.0162803,-84.6446998,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8841c7ceb28e4c75:0x1f6adbf38c4d63a5!2m2!1d-84.6425111!2d39.0162803?hl=en-US"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llScaryStrokesWaldorf}>
          <Popup>
            Scary Strokes
            <br />
            2960 Technology Pl Suite #106
            <br />
            Waldorf, MD 20601
            <br />
            <a
              href="https://www.google.com/maps/dir//Scary+Strokes,+2960+Technology+Pl+Suite+%23106,+Waldorf,+MD+20601/@38.6309094,-76.9156496,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89b7a12c19b6d4c7:0x8fca4ec5c1402d33!2m2!1d-76.9134479!2d38.6309338?hl=en-US"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llBigPlayBiloxi}>
          <Popup>
            Big Play Entertainment Center
            <br />
            1842 Beach Blvd
            <br />
            Biloxi, MS 39531
            <br />
            <a
              href="https://www.google.com/maps/dir//Big+Play+Entertainment+Center,+1842+Beach+Blvd,+Biloxi,+MS+39531/@30.3942967,-88.9501403,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x889c11d11f868dd7:0xe6c2e11117bc62d9!2m2!1d-88.9479516!2d30.3942967?hl=en-US"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llK1SpeedLasVegas}>
          <Popup>
            K1 Speed
            <br />
            4175 S Arville St
            <br />
            Las Vegas, NV 89103
            <br />
            <a
              href="https://www.google.com/maps/dir//K1+Speed+-+Indoor+Go+Karts,+Corporate+Event+Venue,+Team+Building+Activities,+4175+S+Arville+St,+Las+Vegas,+NV+89103/@36.114248,-115.2019997,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x80c8c69834e30c99:0x429754f0833094b6!2m2!1d-115.1998586!2d36.1142476"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llVictoryLaneCharlotte}>
          <Popup>
            Victory Lane Indoor Karting
            <br />
            2330 Tipton Dr
            <br />
            Charlotte, NC 28206
            <br />
            <a
              href="https://www.google.com/maps/dir//Victory+Lane+Indoor+Karting,+2330+Tipton+Dr,+Charlotte,+NC+28206/@35.271075,-80.8457193,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8856a0f065e5766d:0xd766e19406d9d6e1!2m2!1d-80.8435306!2d35.271075"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llFullThrottleCincinnati}>
          <Popup>
            Full Throttle Adrenaline Park
            <br />
            11725 Commons Dr
            <br />
            Cincinnati, OH 45246
            <br />
            <a
              href="https://www.google.com/maps/dir//Full+Throttle+Adrenaline+Park+-+Cincinnati,+11725+Commons+Dr,+Cincinnati,+OH+45246/@39.2906901,-84.4566993,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88404e167ca763d7:0x57e5fe0dbd014137!2m2!1d-84.4545106!2d39.2906901"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llZoneSanJuan}>
          <Popup>
            The Zone
            <br />
            100 St Patrick Ave
            <br />
            Guaynabo, 00968, Puerto Rico
            <br />
            <a
              href="https://www.google.com/maps/dir//Caribbean+Cinemas,+100+St+Patrick+Ave,+Guaynabo,+00968,+Puerto+Rico/@18.4082721,-66.1076779,17z/data=!3m1!5s0x8c036906071551a9:0x881f184484269090!4m8!4m7!1m0!1m5!1m1!1s0x8c03690607389cab:0x617e9bc14e628ef9!2m2!1d-66.1054892!2d18.4082721"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llCharlestonFunMountPleasant}>
          <Popup>
            Charleston Fun Park
            <br />
            3255 N Hwy 17
            <br />
            Mt Pleasant, SC 29466
            <br />
            <a
              href="https://www.google.com/maps/dir//Charleston+Fun+Park,+3255+N+Hwy+17,+Mt+Pleasant,+SC+29466/@32.8682367,-79.7815559,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88fe6d0eb7e900dd:0x84a562898fb9947b!2m2!1d-79.7795264!2d32.8680488"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llAndrettiKaty}>
          <Popup>
            Andretti Karting and Games
            <br />
            Grand W Blvd #1230
            <br />
            Katy, TX 77449
            <br />
            <a
              href="https://www.google.com/maps/dir//Andretti+Karting+and+Games+%E2%80%93+Katy,+Grand+W+Blvd+%231230,+Katy,+TX+77449/@29.7875131,-95.7722461,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x864127a3c79d3e9d:0x8f1bb71ea71e4644!2m2!1d-95.7700574!2d29.7875131"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llCinergyOdessa}>
          <Popup>
            Cinergy Entertainment
            <br />
            8250 TX-191
            <br />
            Odessa, TX 79765
            <br />
            <a
              href="https://www.google.com/maps/dir//Cinergy+Odessa+Featuring+EPIC,+8250+TX-191,+Odessa,+TX+79765/@31.9246274,-102.2868371,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x86fbcf919e4aadc9:0x446fb60b84b7d2f0!2m2!1d-102.2846484!2d31.9246274"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llDaveBustersAustin}>
          <Popup>
            Dave & Buster's
            <br />
            9333 Research Blvd #A600
            <br />
            Austin, TX 78759
            <br />
            <a
              href="https://www.google.com/maps/dir//Dave+%26+Buster's,+9333+Research+Blvd+%23A600,+Austin,+TX+78759/@30.3834426,-97.7396394,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8644cb8249e9c02b:0xe5e65e6fb8f4f16e!2m2!1d-97.7374507!2d30.3834426"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llDaveBustersHouston}>
          <Popup>
            Dave & Buster's
            <br />
            7620 Katy Fwy #100
            <br />
            Houston, TX 77024
            <br />
            <a
              href="https://www.google.com/maps/dir//Dave+%26+Buster's,+7620+Katy+Fwy+%23100,+Houston,+TX+77024/@29.7862052,-95.4681024,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640c6bb23349bcf:0x5ee48c65b0e781c0!2m2!1d-95.4661246!2d29.7863915"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llPinballzLakeCreek}>
          <Popup>
            Pinballz Lake Creek
            <br />
            13729 Research Blvd
            <br />
            Austin, TX 78750
            <br />
            <a
              href="https://www.google.com/maps/dir//Pinballz+Lake+Creek,+13729+Research+Blvd,+Austin,+TX+78750/@30.46094,-97.7887654,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x865b32b420b3046d:0x96ea972b75011c2a!2m2!1d-97.7902277!2d30.4599815"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llSchulmansSherman}>
          <Popup>
            Schulman’s Movie Bowl Grille
            <br />
            110 FM1417
            <br />
            Sherman, TX 75090
            <br />
            <a
              href="https://www.google.com/maps/dir//Schulman%E2%80%99s+Movie+Bowl+Grille+-+Sherman,+110+FM1417,+Sherman,+TX+75090/@33.5950523,-96.6047086,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x864c63c28a787519:0x77c5552b0166f170!2m2!1d-96.6025178!2d33.5950528"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llK1SpeedNashville}>
          <Popup>
            K1 Speed
            <br />
            404 Davidson St
            <br />
            Nashville, TN 37213
            <br />
            <a
              href="https://www.google.com/maps/dir//404+Davidson+St,+Nashville,+TN+37213/@36.1606571,-86.7667297,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x886466475b038cc7:0x24978e0becf4e352!2m2!1d-86.762245!2d36.1606572"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llEpicenterPuyallup}>
          <Popup>
            The Epicenter
            <br />
            15317 Meridian Ave E<br />
            Puyallup, WA 98375
            <br />
            <a
              href="https://www.google.com/maps/dir//Epicenter,+15317+Meridian+Ave+E,+Puyallup,+WA+98375/@47.1170616,-122.2947454,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x5490fd8d3c94d009:0x64d3fba924a32ebb!2m2!1d-122.2925567!2d47.1170616"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llAccelerateWaukesha}>
          <Popup>
            Accelerate Indoor Speedway
            <br />
            W229N1400 Westwood Dr
            <br />
            Waukesha, WI 53186
            <br />
            <a
              href="https://www.google.com/maps/dir//Veloce+Indoor+Speedway+Milwaukee,+W229N1400+Westwood+Dr,+Waukesha,+WI+53186/@43.050113,-88.205635,17.82z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8805a9a9eb59b15f:0x4afc960a8decd41!2m2!1d-88.205635!2d43.0500679?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llBackAlleyDaytona}>
          <Popup>
            Back Alley Lanes VR-Cade
            <br />
            2400 S Ridgewood Ave
            <br />
            South Daytona, FL 32119
            <br />
            <a
              href="https://www.google.com/maps/dir//2400+S+Ridgewood+Ave+Suite+6,+South+Daytona,+FL+32119/@29.1640037,-81.0017817,18.3z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88e6d9cd01e4dfe5:0xbf622868c1a07f97!2m2!1d-81.0008877!2d29.1639926"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llAdventureZoneDuluth}>
          <Popup>
            Adventure Zone
            <br />
            329 S Lake Ave
            <br />            
            Duluth, MN 55802
            <br />
            <a
              href="https://www.google.com/maps/dir//329+S+Lake+Ave,+Duluth,+MN+55802/@46.7828623,-92.097847,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x52ae52eb4f33f391:0x77340c0f64a84985!2m2!1d-92.0956583!2d46.7828623"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llVortexNiagara}>
          <Popup>
            Vortex VR Arcade
            <br />
            360 Rainbow Blvd
            <br />            
            Niagara Falls, NY 14303
            <br />
            <a
              href="https://www.google.com/maps/dir//360+Rainbow+Blvd,+Niagara+Falls,+NY+14303/@43.0874441,-79.066344,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89d342e27bd560eb:0xa951a0efd9b71017!2m2!1d-79.0641553!2d43.0874441"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llXtremeActionFtLauderdale}>
          <Popup>
            Xtreme Action Park
            <br />
            5300 Powerline Rd
            <br />            
            Fort Lauderdale, FL 33309
            <br />
            <a
              href="https://www.google.com/maps/dir//5300+Powerline+Rd,+Fort+Lauderdale,+FL+33309/@26.1922868,-80.155688,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88d903cf5b51dc99:0xc72e7e5ba55f6d44!2m2!1d-80.1534993!2d26.1922868"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llTheDivrgence}>
          <Popup>
            The DiVRgence
            <br />
            3015 Governors Dr SW
            <br />            
            Huntsville, AL 35805
            <br />
            <a
              href="https://www.google.com/maps/dir//3015+Governors+Dr+SW,+Huntsville,+AL+35805/@34.7196259,-86.6092356,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88626c9bb8fb1de7:0xa7e9d666d67b4993!2m2!1d-86.6070469!2d34.7196259"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llLaurelLanesMapleShade}>
          <Popup>
            Laurel Lanes
            <br />
            2825 Route 73 South
            <br />            
            Maple Shade, NJ 08052
            <br />
            <a
              href="https://www.google.com/maps/dir//Laurel+Lanes,+2825+NJ-73,+Maple+Shade,+NJ+08052/@39.9394482,-74.9743899,20z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89c6cad14446432f:0x28aeeda78a8e1b2f!2m2!1d-74.9743023!2d39.9394973"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llStarLanesColumbus}>
          <Popup>
            Star Lanes Polaris
            <br />
            8655 Lyra Dr
            <br />            
            Columbus, OH 43240
            <br />
            <a
              href="https://www.google.com/maps/dir//8655+Lyra+Dr,+Columbus,+OH+43240/@40.1431144,-82.9802561,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8838f466a6c3f6a7:0xe01fab463b055bdf!2m2!1d-82.9780674!2d40.1431144"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llCrossroadsBeaumont}>
          <Popup>
            Crossroads Bowling Center
            <br />
            4370 Dowlen Rd
            <br />            
            Beaumont, TX 77706
            <br />
            <a
              href="https://www.google.com/maps/dir//4370+Dowlen+Rd,+Beaumont,+TX+77706/@30.119725,-94.1616433,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x86393366515bc69d:0xfedce2a5011f85a6!2m2!1d-94.1594546!2d30.119725"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llLaunchGurnee}>
          <Popup>
            Launch Trampoline Park
            <br />
            880 Lakeside Dr
            <br />            
            Gurnee, IL 60031
            <br />
            <a
              href="https://www.google.com/maps/dir//880+Lakeside+Dr,+Gurnee,+IL+60031/@42.3714763,-87.9529318,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x880f91e0a6b02ae5:0x2e006a81728e1dd7!2m2!1d-87.9529318!2d42.3714763"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llMb2SantaClarita}>
          <Popup>
            MB2 Entertainment
            <br />
            21516 Golden Triangle Rd.
            <br />
            Santa Clarita, CA 91350
            <br />
            <a
              href="https://www.google.com/maps/dir//21516+Golden+Triangle+Rd,+Santa+Clarita,+CA+91350/@34.4154551,-118.5113917,18.65z/data=!4m8!4m7!1m0!1m5!1m1!1s0x80c287b641cfc215:0x5b7a9445957d007d!2m2!1d-118.5114668!2d34.4158153?hl=en-US"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llOasisLanesElPaso}>
          <Popup>
            Oasis Lanes
            <br />
            1660 N Zaragoza Rd
            <br />
            El Paso, TX 79936
            <br />
            <a
              href="https://www.google.com/maps/dir//1660+N+Zaragoza+Rd,+El+Paso,+TX+79936/@31.7480959,-106.282377,18.04z/data=!4m8!4m7!1m0!1m5!1m1!1s0x86e7438a223cfb11:0x4dbedffa5d90fcf3!2m2!1d-106.2810844!2d31.7481747"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llAutobahnJacksonville}>
          <Popup>
            Autobahn Indoor Speedway
            <br />
            6601 Executive Park Ct N
            <br />
            Jacksonville, FL 32216
            <br />
            <a
              href="https://www.google.com/maps/dir//6601+Executive+Park+Ct+N,+Jacksonville,+FL+32216/@30.2554965,-81.6031802,18.25z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88e5ca01cb713cf5:0xf9758b019f43f5f6!2m2!1d-81.6023928!2d30.2554284"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llLakeAreaLakeCharles}>
          <Popup>
            Lake Area Adventures
            <br />
            1337 Country Club Rd
            <br />
            Lake Charles, LA 70605
            <br />
            <a
              href="https://www.google.com/maps/dir//1337+Country+Club+Rd,+Lake+Charles,+LA+70605/@30.1677286,-93.2395966,21z/data=!4m8!4m7!1m0!1m5!1m1!1s0x863b86357342919d:0x8f789730366358b7!2m2!1d-93.2394723!2d30.1677708"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llLaunchLeominster}>
          <Popup>
            Launch Trampoline Park
            <br />
            118 Commercial Rd
            <br />
            Leominster, MA 01453
            <br />
            <a
              href="https://www.google.com/maps/dir//118+Commercial+Rd,+Leominster,+MA+01453/@42.5290546,-71.7399407,20.39z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89e3ef4747798777:0x84c47efe78214cdd!2m2!1d-71.7398817!2d42.5291335"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llAccelerateMokena}>
          <Popup>
            Accelerate Indoor Speedway
            <br />
            8580 Springlake Drive
            <br />
            Mokena, IL 60448
            <br />
            <a
              href="https://www.google.com/maps/dir//Accelerate+Indoor+Speedway+%26+Events+-+Chicago,+8580+Springlake+Dr,+Mokena,+IL+60448,+United+States/@41.5502551,-87.8263281,20.65z/data=!3m1!5s0x880e151abacba331:0x7155681c71c22b1b!4m17!1m7!3m6!1s0x880e151ab5262683:0x56372dd5d1d55822!2sAccelerate+Indoor+Speedway+%26+Events+-+Chicago!8m2!3d41.5502838!4d-87.8262391!16s%2Fg%2F11c6s4qk2z!4m8!1m0!1m5!1m1!1s0x880e151ab5262683:0x56372dd5d1d55822!2m2!1d-87.8262391!2d41.5502838!3e3"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llOwynnsWorldLitchfieldPark}>
          <Popup>
            Owynn's World of Adventure
            <br />
            5110 N. Dysart Road
            <br />
            Litchfield Park, AZ 85340
            <br />
            <a
              href="https://www.google.com/maps/dir//5110+N+Dysart+Rd,+Litchfield+Park,+AZ+85340/@33.5092587,-112.3427158,19.86z/data=!4m8!4m7!1m0!1m5!1m1!1s0x872b4731cb77fa31:0xc2ac5451bac306dd!2m2!1d-112.3424387!2d33.5092774"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llSacTownRanchoCordova}>
          <Popup>
            Sac Town Family Fun
            <br />
            12401 Folsom Blvd
            <br />
            Rancho Cordova, CA 95742
            <br />
            <a
              href="https://www.google.com/maps/dir//12401+Folsom+Blvd+%23105,+Rancho+Cordova,+CA+95742/@38.6303625,-121.2158841,19.34z/data=!4m8!4m7!1m0!1m5!1m1!1s0x809ae6ffed14f7b7:0x5d55048797bc6acc!2m2!1d-121.2155194!2d38.6303857"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={ll1upEntertainmentTampa}>
          <Popup>
            1UP Entertainment + Social
            <br />
            11778 North Dale Mabry Highway
            <br />
            Tampa, FL 33618
            <br />
            <a
              href="https://www.google.com/maps/dir//11778+N+Dale+Mabry+Hwy,+Tampa,+FL+33618/@28.055466,-82.5063003,20.65z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88c2c0e4eb2ce40f:0xbde24db79ca6d914!2m2!1d-82.5061735!2d28.055518?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llK1SpeedLeesSummit}>
          <Popup>
            K1 Speed
            <br />
            2911 NE Independence Ave
            <br />
            Lee's Summit, MO 64064
            <br />
            <a
              href="https://www.google.com/maps/dir//K1+Speed+-+Indoor+Go+Karts,+Corporate+Event+Venue,+Team+Building+Activities,+2911+NE+Independence+Ave,+Lee's+Summit,+MO+64064/@38.9657827,-94.3603744,18z/data=!4m8!4m7!1m0!1m5!1m1!1s0x87c11f4c28cbee3d:0xe001fcab9cfb33e!2m2!1d-94.360283!2d38.965737?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llWarriorZoneFtCampbell}>
          <Popup>
            Fort Campbell Warrior Zone
            <br />
            3910 Indiana Ave 
            <br />
            Fort Campbell, KY 42223
            <br />
            <a
              href="https://www.google.com/maps/dir//3910+Indiana+Ave,+Fort+Campbell,+KY+42223/@36.6639941,-87.4707662,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88652bf9847dc3d7:0x508375a11d2a00c4!2m2!1d-87.4681913!2d36.6639941?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llQuakeKennewick}>
          <Popup>
            Quake Family Fun Center
            <br />
            106904 E Detrick Pr
            <br />
            Kennewick, WA 99338
            <br />
            <a
              href="https://www.google.com/maps/dir//106904+E+Detrick+Pr,+Kennewick,+WA+99338/@46.195213,-119.275153,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x5498773db12f52cd:0xd61997e709bd9083!2m2!1d-119.2725781!2d46.195213?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llScandiaFairfield}>
          <Popup>
            Scandia Golfland
            <br />
            4300 Central Pl
            <br />
            Fairfield, CA 94534
            <br />
            <a
              href="https://www.google.com/maps/dir//Scandia+Golfland,+4300+Central+Pl,+Fairfield,+CA+94534/@38.224922,-122.1264796,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x808513c9f7fb1893:0xedd8e1ed6f210d62!2m2!1d-122.1239047!2d38.224922?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llTokenMtgEdgewater}>
          <Popup>
            Token MTG
            <br />
            133 Mitchells Chance Rd
            <br />
            Edgewater, MD 21037
            <br />
            <a
              href="https://www.google.com/maps/dir//Token,+133+Mitchells+Chance+Rd,+Edgewater,+MD+21037/@38.928171,-76.5658689,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89b7f3032f83e753:0xe70ca5910f4bf672!2m2!1d-76.563294!2d38.928171?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llFastLaneBoise}>
          <Popup>
            Fast Lane Boise
            <br />
            10243 W Fairview Ave
            <br />
            Boise, ID 83704
            <br />
            <a
              href="https://www.google.com/maps/dir//10243+W+Fairview+Ave,+Boise,+ID+83704/@43.618676,-116.3124646,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x54ae55d6f2343a2f:0xea64bd56446af35e!2m2!1d-116.3098897!2d43.618676?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llPremiereBryan}>
          <Popup>
            Premiere Cinemas
            <br />
            950 N Earl Rudder Fwy
            <br />
            Bryan, TX 77808
            <br />
            <a
              href="https://www.google.com/maps/dir//950+N+Earl+Rudder+Fwy,+Bryan,+TX+77808/@30.6759582,-96.3390575,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x864681391b4ab0ed:0x6b0712e5f3218787!2m2!1d-96.3364826!2d30.6759582?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llThunderRoadSiouxFalls}>
          <Popup>
            Thunder Road Entertainment
            <br />
            201 N Kiwanis Ave 
            <br />
            Sioux Falls, SD 57104
            <br />
            <a
              href="https://www.google.com/maps/dir//201+N+Kiwanis+Ave,+Sioux+Falls,+SD+57104/@43.5491008,-96.7645887,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x878eca636d0deb31:0xe6d7345d3bd67993!2m2!1d-96.7617787!2d43.5489316?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llCinemaSocialCorbin}>
          <Popup>
            Cinema Social
            <br />
            1871 Cumberland Falls Hwy
            <br />
            Corbin, KY 40701
            <br />
            <a
              href="https://www.google.com/maps/dir//1871+Cumberland+Falls+Hwy,+Corbin,+KY/@36.9170861,-84.1664899,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x885cdb14d76cf8b7:0xce83e7f04a88dbcf!2m2!1d-84.1252902!2d36.9170932?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llFlight509Spokane}>
          <Popup>
            Flight 509
            <br />
            10502 E Montgomery Dr.
            <br />
            Spokane Valley, WA 99206
            <br />
            <a
              href="https://www.google.com/maps/dir//10502+E+Montgomery+Dr,+Spokane+Valley,+WA+99206/@47.6801456,-117.2664847,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x549e1ff5eba28e2b:0x1f1dbbf743455c73!2m2!1d-117.2639098!2d47.6801456?hl=en&entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
        <Marker icon={greenIcon} position={llGrandSlamCoonRapids}>
          <Popup>
            Grand Slam Family Fun Center
            <br />
            2941 Coon Rapids Blvd NW
            <br />
            Coon Rapids, MN 55433
            <br />
            <a
              href="https://www.google.co.in/maps/dir//2941+Coon+Rapids+Blvd+NW,+Coon+Rapids,+MN+55433/@45.1737628,-93.3396443,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x52b33b8a25e8fc99:0x69e308e7990463a!2m2!1d-93.3396443!2d45.1737628?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </Popup>
        </Marker>
      </Map>
      </div>
      <div className="locations-list">
        <div className="locations-state">Alabama</div>
        <div className="locations-name">
          <p className="locations-venue">Launch Trampoline Park</p>
          <p className="locations-city">Prattville, AL</p>
          <p>
            <a
              href="https://launchtrampolinepark.com/prattville/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollLaunchPrattville}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">The DiVRgence</p>
          <p className="locations-city">Huntsville, AL</p>
          <p>
            <a
              href="https://thedivrgence.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollTheDivrgence}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Arizona</div>
        <div className="locations-name">
          <p className="locations-venue">Jake's Unlimited</p>
          <p className="locations-city">Mesa, AZ</p>
          <p>
            <a
              href="https://www.jakesunlimited.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollJakesUnlimitedMesa}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Owynn's World of Adventure</p>
          <p className="locations-city">Litchfield Park, AZ</p>
          <p>
            <a
              href="https://owynnsworldofadventure.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollOwynnsWorldLitchfieldPark}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">California</div>
        <div className="locations-name">
          <p className="locations-venue">No Surrender Adventure Park</p>
          <p className="locations-city">Clovis, CA</p>
          <p>
            <a
              href="https://nosurrendertag.com/clovis/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollNoSurrenderClovis}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Scandia Golfland</p>
          <p className="locations-city">Fairfield, CA</p>
          <p>
            <a
              href="https://scandiafamilycenter.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollScandiaFairfield}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Sac Town Family Fun Mini Golf & Arcade</p>
          <p className="locations-city">Rancho Cordova, CA</p>
          <p>
            <a
              href="https://www.sactownfamilyfun.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollSacTownRanchoCordova}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">MB2 Entertainment</p>
          <p className="locations-city">Santa Clarita, CA</p>
          <p>
            <a
              href="https://mb2entertainmentscv.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollMb2SantaClarita}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Epicenter</p>
          <p className="locations-city">Santa Rosa, CA</p>
          <p>
            <a
              href="https://visitepicenter.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollEpicenterSantaRosa}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Florida</div>
        <div className="locations-name">
          <p className="locations-venue">Gator Mike's Family Fun Park</p>
          <p className="locations-city">Cape Coral, FL</p>
          <p>
            <a
              href="https://gatormikes.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollGatorMikesCapeCoral}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Xtreme Action Park</p>
          <p className="locations-city">Fort Lauderdale, FL</p>
          <p>
            <a
              href="https://xtremeactionpark.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollXtremeActionFtLauderdale}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Autobahn Indoor Speedway</p>
          <p className="locations-city">Jacksonville, FL</p>
          <p>
            <a
              href="https://autobahnspeed.com/locations/jacksonville-fl-indoor-go-karts/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollAutobahnJacksonville}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">
            Back Alley Lanes VR-Cade
          </p>
          <p className="locations-city">South Daytona, FL</p>
          <p>
            <a
              href="https://www.joeybsbowlcade.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollBackAlleyDaytona}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">1UP Entertainment + Social</p>
          <p className="locations-city">Tampa, FL</p>
          <p>
            <a
              href="https://playat1up.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyToll1upEntertainmentTampa}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Georgia</div>
        <div className="locations-name">
          <p className="locations-venue">Andretti Indoor Karting & Games</p>
          <p className="locations-city">Marietta, GA</p>
          <p>
            <a
              href="https://andrettikarting.com/marietta/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollAndrettiMarietta}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Idaho</div>
        <div className="locations-name">
          <p className="locations-venue">Fast Lane Boise</p>
          <p className="locations-city">Boise, ID</p>
          <p>
            <a
              href="https://fastlaneboise.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollFastLaneBoise}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Illinois</div>
        <div className="locations-name">
          <p className="locations-venue">Launch Trampoline Park</p>
          <p className="locations-city">Gurnee, IL</p>
          <p>
            <a
              href="https://launchtrampolinepark.com/gurnee/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollLaunchGurnee}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Accelerate Indoor Speedway</p>
          <p className="locations-city">Mokena, IL</p>
          <p>
            <a
              href="https://acceleratespeed.com/chicago/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollAccelerateMokena}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Iowa</div>
        <div className="locations-name">
          <p className="locations-venue">Pole Position Raceway</p>
          <p className="locations-city">Des Moines, IA</p>
          <p>
            <a
              href="https://www.polepositionraceway.com/desmoines/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollPolePositionDesMoines}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Kansas</div>
        <div className="locations-name">
          <p className="locations-venue">SpareTime Interactive Entertainment</p>
          <p className="locations-city">Fort Riley, KS</p>
          <p>
            <a
              href="https://riley.armymwr.com/programs/SpareTime-Interactive-Entertainment"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollSpareTimeFortRiley}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Kentucky</div>
        <div className="locations-name">
          <p className="locations-venue">Cinema Social</p>
          <p className="locations-city">Corbin, KY</p>
          <p>
            <a
              href="https://www.cinemasocial.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollCinemaSocialCorbin}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Full Throttle Adrenaline Park</p>
          <p className="locations-city">Florence, KY</p>
          <p>
            <a
              href="https://gofullthrottle.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollFullThrottleFlorence}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Fort Campbell Warrior Zone</p>
          <p className="locations-city">Fort Campbell, KY</p>
          <p>
            <a
              href="https://campbell.armymwr.com/programs/warrior-zone-1"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollWarriorZoneFtCampbell}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Louisiana</div>
        <div className="locations-name">
          <p className="locations-venue">Lake Area Adventures</p>
          <p className="locations-city">Lake Charles, LA</p>
          <p>
            <a
              href="https://lakeareaadventures.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollLakeAreaLakeCharles}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Maryland</div>
        <div className="locations-name">
          <p className="locations-venue">Token MTG</p>
          <p className="locations-city">Edgewater, MD</p>
          <p>
            <a
              href="https://tokenmtg.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollTokenMtgEdgewater}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Scary Strokes</p>
          <p className="locations-city">Waldorf, MD</p>
          <p>
            <a
              href="https://www.scarystrokes.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollScaryStrokesWaldorf}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Massachusetts</div>
        <div className="locations-name">
          <p className="locations-venue">Launch Trampoline Park</p>
          <p className="locations-city">Leominster, MA</p>
          <p>
            <a
              href="https://launchtrampolinepark.com/leominster/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollLaunchLeominster}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Minnesota</div>
        <div className="locations-name">
          <p className="locations-venue">Adventure Zone</p>
          <p className="locations-city">Duluth, MN</p>
          <p>
            <a
              href="https://www.adventurezoneduluth.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollAdventureZoneDuluth}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Grand Slam Family Fun Center</p>
          <p className="locations-city">Coon Rapids, MN</p>
          <p>
            <a
              href="https://www.grandslamcoonrapids.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollGrandSlamCoonRapids}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Mississippi</div>
        <div className="locations-name">
          <p className="locations-venue">Big Play Entertainment Center</p>
          <p className="locations-city">Biloxi, MS</p>
          <p>
            <a
              href="https://www.bigplaybiloxi.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollBigPlayBiloxi}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Missouri</div>
        <div className="locations-name">
          <p className="locations-venue">K1 Speed</p>
          <p className="locations-city">Lee's Summit, MO</p>
          <p>
            <a
              href="https://www.k1speed.com/lees-summit-location.html"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollK1SpeedLeesSummit}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Nevada</div>
        <div className="locations-name">
          <p className="locations-venue">K1 Speed</p>
          <p className="locations-city">Las Vegas, NV</p>
          <p>
            <a
              href="https://www.k1speed.com/las-vegas-location.html"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollK1SpeedLasVegas}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">New Jersey</div>
        <div className="locations-name">
          <p className="locations-venue">Laurel Lanes</p>
          <p className="locations-city">Maple Shade, NJ</p>
          <p>
            <a
              href="https://laurellanesnj.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollLaurelLanesMapleShade}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">New York</div>
        <div className="locations-name">
          <p className="locations-venue">Vortex VR Arcade</p>
          <p className="locations-city">Niagara Falls, NY</p>
          <p>
            <a
              href="https://www.vortex-vr.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollVortexNiagara}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">North Carolina</div>
        <div className="locations-name">
          <p className="locations-venue">Victory Lane Karting</p>
          <p className="locations-city">Charlotte, NC</p>
          <p>
            <a
              href="https://www.victorylanekarting.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollVictoryLaneCharlotte}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">North Dakota</div>
        <div className="locations-name">
          <p className="locations-venue">Thunder Road Entertainment</p>
          <p className="locations-city">Sioux Falls, ND</p>
          <p>
            <a
              href="https://thunderroadsiouxfalls.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollThunderRoadSiouxFalls}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Ohio</div>
        <div className="locations-name">
          <p className="locations-venue">Full Throttle Adrenaline Park</p>
          <p className="locations-city">Cincinnati, OH</p>
          <p>
            <a
              href="https://gofullthrottle.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollFullThrottleCincinnati}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Star Lanes Polaris</p>
          <p className="locations-city">Columbus, OH</p>
          <p>
            <a
              href="https://starlanespolaris.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollStarLanesColumbus}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Puerto Rico</div>
        <div className="locations-name">
          <p className="locations-venue">The Zone</p>
          <p className="locations-city">San Juan, PR</p>
          <p>
            <a
              href="https://thezonepr.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollZoneSanJuan}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">South Carolina</div>
        <div className="locations-name">
          <p className="locations-venue">Charleston Fun Park</p>
          <p className="locations-city">Mount Pleasant, SC</p>
          <p>
            <a
              href="https://charlestonfunpark.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollCharlestonFunMountPleasant}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Tennessee</div>
        <div className="locations-name">
          <p className="locations-venue">K1 Speed</p>
          <p className="locations-city">Nashville, TN</p>
          <p>
            <a
              href="https://www.k1speed.com/nashville-location.html"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollK1SpeedNashville}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Texas</div>
        <div className="locations-name">
          <p className="locations-venue">Dave & Buster's</p>
          <p className="locations-city">Austin, TX</p>
          <p>
            <a
              href="https://www.daveandbusters.com/locations/austin"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollDaveBustersAustin}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Pinballz Lake Creek</p>
          <p className="locations-city">Austin, TX</p>
          <p>
            <a
              href="https://pinballz.com/lake-creek/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollPinballzLakeCreek}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Crossroads Bowling Center</p>
          <p className="locations-city">Beaumont, TX</p>
          <p>
            <a
              href="https://www.crossroadsbowling.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollCrossroadsBeaumont}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Premiere Cinemas</p>
          <p className="locations-city">Bryan, TX</p>
          <p>
            <a
              href="https://www.pccmovies.com/location/10032/Bryan-PREMIERE-LUX-Cine-15-IMAX-and-Pizza-Pub"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollPremiereBryan}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Oasis Lanes</p>
          <p className="locations-city">El Paso, TX</p>
          <p>
            <a
              href="https://oasislanesep.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollOasisLanesElPaso}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Dave & Buster's</p>
          <p className="locations-city">Houston, TX</p>
          <p>
            <a
              href="https://www.daveandbusters.com/locations/houston-katy-fwy"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollDaveBustersHouston}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Andretti Indoor Karting & Games</p>
          <p className="locations-city">Katy, TX</p>
          <p>
            <a
              href="https://andrettikarting.com/katy/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollAndrettiKaty}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Cinergy Entertainment</p>
          <p className="locations-city">Odessa, TX</p>
          <p>
            <a
              href="https://cinergy.com/locations/odessa/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollCinergyOdessa}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Schulman's Movie Bowl Grille</p>
          <p className="locations-city">Sherman, TX</p>
          <p>
            <a
              href="https://sherman.moviebowlgrille.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollSchulmansSherman}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Washington</div>
        <div className="locations-name">
          <p className="locations-venue">Flight 509</p>
          <p className="locations-city">Spokane Valley, WA</p>
          <p>
            <a
              href="https://www.flight509.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollFlight509Spokane}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">Quake Family Fun Center</p>
          <p className="locations-city">Kennewick, WA</p>
          <p>
            <a
              href="https://quakefamilyfun.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollQuakeKennewick}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-name">
          <p className="locations-venue">The Epicenter</p>
          <p className="locations-city">Puyallup, WA</p>
          <p>
            <a
              href="https://epicenteradventure.com/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollEpicenterPuyallup}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
        <div className="locations-state">Wisconsin</div>
        <div className="locations-name">
          <p className="locations-venue">
            Accelerate Indoor Speedway
          </p>
          <p className="locations-city">Waukesha, WI</p>
          <p>
            <a
              href="https://acceleratespeed.com/milwaukee/"
              className="locations-web"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
          </p>
          <button
            onClick={handleFlyTollAccelerateWaukesha}
            className="locations-map-btn"
          >
            Find on map
          </button>
        </div>
      </div>
    </div>
  );
}

export default ArenaLocationsMap;

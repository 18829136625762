import React from "react";
import { Link } from "react-router-dom";
import "./PvpGames.css";
import "./GamesData";
import Fade from "react-reveal/Fade";
import ScrollContainer from "react-indiana-drag-scroll";

function PvpGames({
  imgGameEliteForce,
  altEliteForce,
  imgGameHardpoint,
  altGameHardpoint,
}) {
  return (
    <>
      <div className="pvp-games-bg">
        <div className="pvp-games-container">
          <Fade bottom duration={800}>
            <h1 className="pvp-games-header">
              Player vs. Player Competitive Games
            </h1>
          </Fade>
          <Fade bottom duration={800}>
            <h3 className="pvp-games-subheader">
              Battle against your friends and prove you're the best
            </h3>
          </Fade>
          <ScrollContainer className="pvp-games-scroll-container">
            <div className="pvp-games-carousel-margin">
              <Link to="/games">
                <img
                  src={imgGameEliteForce}
                  alt={altEliteForce}
                  className="pvp-games-img"
                />
              </Link>
            </div>
            <div className="pvp-games-carousel-margin">
              <Link to="/games">
                <img
                  src={imgGameHardpoint}
                  alt={altGameHardpoint}
                  className="pvp-games-img"
                />
              </Link>
            </div>
          </ScrollContainer>
        </div>
      </div>
    </>
  );
}

export default PvpGames;

import React from "react";
import "./HomeHeroSection.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";

function HomeHeroSection({
  lightBg,
  lightText,
  lightTextDesc,
  headline,
  subTitle,
  buttonLabel,
}) {
  return (
    <>
      <div className={ lightBg ? "home-hero-section-text-dark home-hero-section-bg" : "home-hero-section-text-light home-hero-section-bg-dark" }>
        <div className="home-hero-section-container">
            <div className="home-hero-section-col-text">
              <div className="home-hero-section-text-wrapper">
                <h1 className={ lightText ? "home-hero-section-heading" : "home-hero-section-heading home-hero-section-text-dark" }>{headline}</h1>
                <p className={ lightTextDesc ? "home-hero-section-subtitle" : "home-hero-section-subtitle home-hero-section-text-dark" }>{subTitle}</p>
                <Link to="/locations">
                  <Button buttonSize="btn-wide" buttonColor="green">
                    {buttonLabel}
                    <div className="active-comp-right-arrow">
                      <img src="/images/arrow-icon-now.svg" alt="arrow" />
                    </div>
                  </Button>
                </Link>
              </div>
            </div>
          </div>
      </div>
    </>
  );
}

export default HomeHeroSection;

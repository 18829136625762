import React from "react";
import "./ContestLandingHeroSection.css";
import Fade from "react-reveal/Fade";
import LazyAnimation from "./LazyAnimation";
import GoogleMapReact from "google-map-react";

function ContestLandingHeroSection() {
  const ContestMapMarker = () => (
    <img
      className="contest-map-marker"
      src="images/marker-green.png"
      alt="Map marker icon"
    />
  );
  const defaultProps = {
    center: {
      lat: 43.05005182108529,
      lng: -88.20609409618939,
    },
    zoom: 11,
  };

  return (
    <>
      <div className="contest-landing-hero-section-text-extra-padding">
        <div className="contest-landing-hero-section-text">
          <div className="contest-landing-hero-section-container">
            <div className="contest-landing-hero-section-row">
              <div className="contest-landing-hero-section-col">
                <LazyAnimation />
                <Fade bottom cascade duration={800}>
                  <div className="contest-landing-hero-section-text-wrapper">
                    <h1 className="contest-landing-hero-section-heading contest-landing-hero-section-text-dark">
                      Where to play
                    </h1>
                    <p className="contest-landing-hero-section-subtitle contest-landing-hero-section-text-dark">
                      <img src="/images/accelerate_logo.png" alt="" />
                    </p>
                    <p className="contest-landing-hero-section-subtitle contest-landing-hero-section-text-dark">
                      W229N1400 Westwood Dr
                      <br />
                      Waukesha, WI 53186
                      <br />
                      <a className="contest-landing-how-link-gray" href="https://acceleratespeed.com/milwaukee/">
                        Visit Website
                      </a>
                    </p>
                  </div>
                </Fade>
              </div>
              <div className="contest-landing-hero-section-col">
                <div style={{ height: "550px", width: "100%", }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyA2EnH7YKk3P3hPR4yd2n3raKXss0e_3_0",
                    }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                  >
                    <ContestMapMarker
                      lat={43.05005182108529}
                      lng={-88.20609409618939}
                    />
                  </GoogleMapReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContestLandingHeroSection;

import React, { useEffect, useState } from "react";
import "./ContestLanding.css";
import { contestObjSacTown, contestAdSacTown, contestHowSacTown } from './ContestLandingData';
import ContestLandingAd from "../../ContestLandingAd";
import ContestLandingHeroSacTown from "../../ContestLandingHeroSacTown";
import ContestLandingHomeHero from "../../ContestLandingHomeHero";
import ContestLandingHowTo from "../../ContestLandingHowTo";
import ContestLandingEnd from "../../ContestLandingEnd";
import { Helmet } from "react-helmet";

function ContestLandingSacTown() {
  return (
    <>
    <Helmet>
        <meta name="robots" content="noindex" />
    </Helmet>
      <ContestLandingHomeHero {...contestObjSacTown} />
      <ContestLandingAd {...contestAdSacTown}/>
      <ContestLandingHeroSacTown />
      <ContestLandingHowTo {...contestHowSacTown} />
      <ContestLandingEnd />
    </>
  );
}

export default ContestLandingSacTown;
import React from "react";
import { Link } from "react-router-dom";
import "./OtherGames.css";
import "./GamesData";
import Fade from "react-reveal/Fade";
import ScrollContainer from "react-indiana-drag-scroll";

function OtherGames({ imgGameParanormal, altGameParanormal, imgGameRhythmMaster, altRhythmMaster }) {
  return (
    <>
      <div className="other-games-bg">
        <div className="other-games-container">
          <Fade bottom duration={800}>
            <h1 className="other-games-header">Immersive Experiences</h1>
          </Fade>
          <Fade bottom duration={800}>
            <h3 className="other-games-subheader">
              Walk through an immersive haunted house or slash beats to the rhythm of the music
            </h3>
          </Fade>
          <ScrollContainer className="other-games-scroll-container">
            <div className="other-games-carousel-margin">
              <Link to="/games">
                <img
                  src={imgGameParanormal}
                  alt={altGameParanormal}
                  className="other-games-img"
                />
              </Link>
              </div>
              <div className="other-games-carousel-margin">
              <Link to="/games">
                <img
                  src={imgGameRhythmMaster}
                  alt={altRhythmMaster}
                  className="other-games-img"
                />
              </Link>
            </div>
          </ScrollContainer>
        </div>
      </div>
    </>
  );
}

export default OtherGames;

import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./GamesPageCarousel.css";
import { Button } from "./Button";
import {
  VideoModalDeadZone,
  VideoModalEliteForce,
  VideoModalParanormal,
} from "./VideoModal";

export function GamesPageCarousel() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 800,
    autoplaySpeed: 4000,
    cssEase: "linear",
    pauseOnHover: false,
  };

  const [videoshow1, setVideoShow1] = useState(false);
  const [videoshow2, setVideoShow2] = useState(false);
  const [videoshow3, setVideoShow3] = useState(false);

  return (
    <>
      <div className="games-carousel-container">
        <Slider {...settings}>
          <div className="carousel-bg-slide-1">
            <div className="carousel-text-wrapper">
              {/* <h1 className="carousel-title">Dead Zone</h1> */}
              <img className="carousel-logo" src="/images/carousel_title_deadzone.png" alt="Dead Zone Logo" />
              <p className="carousel-subtitle">
                Team up with friends to defeat the zombie horde!
              </p>
              <Button
                buttonSize="btn-wide"
                buttonColor="green"
                onClick={() => setVideoShow1(true)}
              >
                Watch Trailer
                <div className="gamecard-right-arrow">
                  <img src="/images/arrow-icon-now.svg" alt="arrow" />
                </div>
              </Button>
            </div>
          </div>
          <div className="carousel-bg-slide-2">
            <div className="carousel-text-wrapper">
              {/* <h1 className="carousel-title">Elite Force</h1> */}
              <img className="carousel-logo" src="/images/carousel_title_eliteforce.png" alt="Elite Force Logo" />
              <p className="carousel-subtitle">
                Join the Elite Force and fight against your friends in this
                military shooter deathmatch.
              </p>
              <Button
                buttonSize="btn-wide"
                buttonColor="green"
                onClick={() => setVideoShow2(true)}
              >
                Watch Trailer
                <div className="gamecard-right-arrow">
                  <img src="/images/arrow-icon-now.svg" alt="arrow" />
                </div>
              </Button>
            </div>
          </div>
          <div className="carousel-bg-slide-3">
            <div className="carousel-text-wrapper">
              {/* <h1 className="carousel-title">Paranormal</h1> */}
              <img className="carousel-logo" src="/images/carousel_title_paranormal.png" alt="Paranormal Logo" />
              <p className="carousel-subtitle">
                Enter a haunted mansion to search for paranormal activity. Will
                you survive the night?
              </p>
              <Button
                buttonSize="btn-wide"
                buttonColor="green"
                onClick={() => setVideoShow3(true)}
              >
                Watch Trailer
                <div className="gamecard-right-arrow">
                  <img src="/images/arrow-icon-now.svg" alt="arrow" />
                </div>
              </Button>
            </div>
          </div>
        </Slider>
      </div>
      <VideoModalDeadZone
        title="Trailer"
        onClose={() => setVideoShow1(false)}
        show={videoshow1}
      />
      <VideoModalEliteForce
        title="Trailer"
        onClose={() => setVideoShow2(false)}
        show={videoshow2}
      />
      <VideoModalParanormal
        title="Trailer"
        onClose={() => setVideoShow3(false)}
        show={videoshow3}
      />
    </>
  );
}

export default GamesPageCarousel;

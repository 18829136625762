export const competeObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    extraPad: true,
    headline: 'Become an Esports Champion',
    description:
      'Compete in our weekly and monthly prize contests. Form a 4-player team with your friends and become esports champions! Win $2,000 in the monthly contest or $400 in the weekly contest.',
    description2: '',
    noButton: 'noButton',
    buttonLabel: '',
    buttonLink: '',
    imgStart: 'start',
    img: 'images/omniplayer_section1_bigcheck.jpg',
    alt: 'Runner'
  };
  
  export const competeObjTwo = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    extraPad: false,
    headline: 'Come Play Anytime',
    description:
      'The contests use ongoing leaderboards, so you can come play anytime and compete for the top spot.',
    description2: '',
    noButton: '',
    buttonLabel: 'See Leaderboards',
    buttonLink: '/leaderboards',
    imgStart: '',
    img: 'images/omniplayer_section2_leaderboard.jpg',
    alt: 'Runner'
  };

  export const competeObjThree = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    extraPad: false,
    headline: 'Compete Against Players Nationwide',
    description:
      'Play with your team against Omni Arena players at entertainment venues nationwide.',
    description2: '',
    noButton: 'noButton',
    buttonLabel: 'See Leaderboards',
    buttonLink: '/leaderboards',
    imgStart: 'start',
    img: 'images/omniplayer_competesection2.jpg',
    alt: 'VR Headset'
  };

  export const competeObjFour = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    extraPad: true,
    headline: 'Form Your Team!',
    description:
      "Form a team of up to four players. Each player on the team needs to log in with an Omni Arena player account. Teams that include guests won't be displayed on the leaderboard.",
    description2: '',
    noButton: '',
    buttonLabel: 'See Contest Rules',
    buttonLink: '/contest-rules',
    imgStart: 'start',
    img: 'images/omniplayer_section4_playerlaugh.jpg',
    alt: 'Crouch'
  };

  export const competeObjFive = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    extraPad: false,
    headline: 'Play To Win!',
    description:
      "You can play as many times as you want, but only your top leaderboard spot is eligible for prize money. A player can only be on one team and cannot win more than one prize.",
    description2:
      "In the weekly contest, players can only win a prize every other week. If a player won a prize in the prior week, the player's team will be ineligible to win the following week.",
    noButton: 'noButton',
    buttonLabel: '',
    buttonLink: '',
    imgStart: '',
    img: 'images/omniplayer_section5_playerpoint.jpg',
    alt: 'Crouch'
  };  

  export const competeObjSix = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    extraPad: false,
    headline: 'Get Your Cash Prize!',
    description:
      'After each contest, the prize winners will be contacted by Virtuix. The prize money for each winning team will be split evenly between all eligible team members.',
    description2: '',
    buttonLabel: 'See Game Rules',
    buttonLink: '/game-rules',
    imgStart: 'start',
    img: 'images/omniplayer_section6_playerkid.jpg',
    alt: 'Crouch'
  };  

  export const locationSectionLightBG = {
    lightBg: true,
  };


import React, { useState, useEffect } from 'react';
import CompetitionSection from '../../CompetitionSection';
import HeroSection from '../../HeroSection';
import HomeHeroSection from '../../HomeHeroSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from '../HomePage/Data';
import { activeCompetition } from '../../CompetitionSectionData';
import EsportsGames from '../../EsportsGames';
import PvpGames from '../../PvpGames';
import OtherGames from '../../OtherGames';
import { gameCard } from '../../GamesData';
import LocationSection from '../../LocationSection';
import { AccountPasswordModal } from '../../ResetPassword';

function Reset() {

    const [resetPassword, setResetPassword] = useState(false);

    let url = window.location.href;

    useEffect(()=>{
        setTimeout(()=>{
            if(url.indexOf('?token') !== -1) {setResetPassword(true)}
        }, 0)
      }, [url]);

    return (
        <div>
        <HomeHeroSection {...homeObjOne} />
        <HeroSection {...homeObjTwo} />
        <HeroSection {...homeObjThree} />
        <HeroSection {...homeObjFour} />
        <CompetitionSection {...activeCompetition} />
        <EsportsGames {...gameCard} />
        <PvpGames {...gameCard} />
        <OtherGames {...gameCard} />
        <LocationSection />
        <AccountPasswordModal onClose={() => setResetPassword(false)} show={resetPassword} />
        </div>
    )
}

export default Reset
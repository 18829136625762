import React from "react";
import { Link } from "react-router-dom";
import "./EsportsGames.css";
import "./GamesData";
import Fade from "react-reveal/Fade";
import ScrollContainer from "react-indiana-drag-scroll";

function EsportsGames({
  imgGameDeadZone,
  altDeadZone,
  imgGameBlackbeard,
  altBlackbeard,
  imgGameGunslinger,
  altGunslinger,
  imgGameCoreDefense,
  altCoreDefense,
}) {
  return (
    <>
      <div className="esports-games-bg">
        <div className="esports-games-container">
          <Fade bottom duration={800}>
            <h1 className="esports-games-header">Team-Based Esports Games</h1>
          </Fade>
          <Fade bottom duration={800}>
            <h3 className="esports-games-subheader">
              Compete for the high score in these games to win weekly and
              monthly cash prizes
            </h3>
          </Fade>
          <ScrollContainer className="esports-games-scroll-container">
            <div className="esports-games-carousel-margin">
              <Link to="/games">
                <img
                  src={imgGameDeadZone}
                  alt={altDeadZone}
                  className="esports-games-img"
                />
              </Link>
            </div>
            <div className="esports-games-carousel-margin">
              <Link to="/games">
                <img
                  src={imgGameBlackbeard}
                  alt={altBlackbeard}
                  className="esports-games-img"
                />
              </Link>
            </div>
            <div className="esports-games-carousel-margin">
              <Link to="/games">
                <img
                  src={imgGameGunslinger}
                  alt={altGunslinger}
                  className="esports-games-img"
                />
              </Link>
            </div>
            <div className="esports-games-carousel-margin">
              <Link to="/games">
                <img
                  src={imgGameCoreDefense}
                  alt={altCoreDefense}
                  className="esports-games-img esports-games-img-last"
                />
              </Link>
            </div>
          </ScrollContainer>
        </div>
      </div>
    </>
  );
}

export default EsportsGames;

import React from "react";
import "./ContestLandingHomeHero.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import "./Button.css";
import LazyAnimation from "./LazyAnimation";

function ContestLandingHomeHero({
  lightBg,
  lightText,
  lightTextDesc,
  headline,
  subTitle,
  buttonLabel,
}) {
  return (
    <>
    <div className="contest-home-hero-video-overlay">
      <video
        autoPlay
        playsInline
        muted="true"
        loop
        className="contest-home-hero-video-position contest-home-hero-bg-video"
        poster="/video/oa_trailer_playing_teaser_img.jpg"
      >
        <source src="/video/oa_contest_video.webm" type="video/webm" />
        <source src="/video/oa_contest_video.mp4" type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
      </div>
        <div className="contest-home-hero-section-container contest-home-hero-section-text-light-nopad">
          <div className="contest-home-hero-section-col-text">
            <div className="contest-home-hero-section-text-wrapper">
              <h1
                className={
                  lightText
                    ? "contest-home-hero-section-heading"
                    : "contest-home-hero-section-heading contest-home-hero-section-text-dark"
                }
              >
                {headline}
              </h1>
              <LazyAnimation />
              <p
                className={
                  lightTextDesc
                    ? "contest-home-hero-section-subtitle"
                    : "contest-home-hero-section-subtitle contest-home-hero-section-text-dark"
                }
              >
                {subTitle}
              </p>
            </div>
          </div>
        </div>
    </>
  );
}

export default ContestLandingHomeHero;

import React from "react";
import "./ContestLandingAd.css";

function ContestLandingAd({ contestAdImg }) {
  return (
    <>
      <div className="contest-landing-ad-hero-section">
        <div className="contest-landing-ad-hero-container">
          <img className="contest-landing-ad-style" src={ contestAdImg } alt="Contest Advertisement" />
        </div>
      </div>
    </>
  );
}

export default ContestLandingAd;

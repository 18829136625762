import React, { useState, useEffect } from 'react';
import SharePage from '../../SharePage';
import { Survey } from '../../Survey';
import { Helmet } from "react-helmet";

function Share() {

    const [survey, setSurvey] = useState(false);

    let url = window.location.href + '?id=' + window.location.search.substring(4);

    useEffect(()=>{
        const sessionId = window.location.search.substring(4);
        fetch("https://api.playerhub.omniverse.global/social/"+sessionId+"/info",
        {
            headers: { "Content-Type": "application/json" },
        }).then(async (result) => {
            const responseBody = await result.json();
            setSurvey(!responseBody.isPageVisited)
        })
        .then(() => {
              fetch(
                "https://api.playerhub.omniverse.global/social/" + sessionId + "/@set-is-page-visited",
                {
                    method: "POST",
                }
              );
        });
      }, [url]);

    return (
        <>
        <Helmet>
        <title>Watch me play Omni Arena | Step Into The Game</title>
        <meta name="title" content="Watch me play Omni Arena | Step Into The Game" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://arena.virtuix.com/share" />
        <meta property="og:title" content="Watch me play Omni Arena | Step Into The Game" />
        <meta property="og:image" content="https://arena.virtuix.com/images/meta_image_share.jpg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://arena.virtuix.com/share" />
        <meta property="twitter:title" content="Watch me play Omni Arena | Step Into The Game" />
        <meta property="twitter:image" content="https://arena.virtuix.com/images/meta_image_share.jpg" />
      </Helmet>     
        <SharePage />
        <Survey onClose={() => setSurvey(false)} show={survey} />
        </>
    );
}

export default Share
import React from "react";
import { Link } from "react-router-dom";
import "./CompetitionSection.css";
import "./CompetitionSectionData";
import { Button } from "./Button";
import Fade from "react-reveal/Fade";
import LazyAnimation from "./LazyAnimation";

function CompetitionSectionShare({
  imgMonth,
  monthCaption,
  imgWeek,
  weekCaption,
  gameTitleMonth,
  gameTitleWeek,
  subTitleMonth,
  subTitleWeek,
  buttonLabel,
  alt,
}) {
  return (
    <>
      <div className="active-comp-bg">
        <div className="active-comp-container">
          <Fade bottom duration={800}>
            <h1 className="active-comp-header">
              Win Real Money
            </h1>
          </Fade>
          <div className="active-comp-row">
            <div className="active-comp-col">
              <img src={imgMonth} alt={alt} className="active-comp-img" />
              <Fade bottom duration={800}>
                <div className="active-comp-caption">{monthCaption}</div>
              </Fade>
              <LazyAnimation></LazyAnimation>
              <Fade bottom duration={800}>
                <div className="active-comp-title">{gameTitleMonth}</div>
              </Fade>
              <Fade bottom duration={800}>
                <div className="active-comp-subtitle">{subTitleMonth}</div>
              </Fade>
              <Link to="/leaderboards">
                <Button buttonSize="btn-wide" buttonColor="green">
                  {buttonLabel}{" "}
                  <div className="active-comp-right-arrow">
                    <img src="/images/arrow-icon-now.svg" alt="arrow" />
                  </div>
                </Button>
              </Link>
            </div>
            <div className="active-comp-col">
              <img src={imgWeek} alt={alt} className="active-comp-img" />
              <Fade bottom duration={800}>
                <div className="active-comp-caption">{weekCaption}</div>
              </Fade>
              <LazyAnimation></LazyAnimation>
              <Fade bottom duration={800}>
                <div className="active-comp-title">{gameTitleWeek}</div>
              </Fade>
              <Fade bottom duration={800}>
                <div className="active-comp-subtitle">{subTitleWeek}</div>
              </Fade>
              <a href="https://arena.virtuix.com/leaderboards#weekly">
                <Button buttonSize="btn-wide" buttonColor="green">
                  {buttonLabel}{" "}
                  <div className="active-comp-right-arrow">
                    <img src="/images/arrow-icon-now.svg" alt="arrow" />
                  </div>
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompetitionSectionShare;

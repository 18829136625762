import React from "react";
import "../../Copy.css";

function ContestRules() {
  return (
    <>
      <div className="copy-container">
        <h1 className="copy-title">Omni Arena Contest Rules</h1>
        <h2 className="copy-header">1. Official Rules</h2>
        <p className="copy-body">
          These Official Rules ("Official Rules") govern the grounds for
          competitive play of Omni Arena prize contests ("Contests" or
          "Contest") organized by Virtuix Inc. or any of its affiliates
          (collectively, "Virtuix" or "we"), including rules governing player
          eligibility, teams, prize awards, and the conduct of players. These
          Official Rules also contain limitations on liability, a waiver of
          bringing claims in a class format, and other legally binding contract
          terms. You are required to read and understand these Official Rules
          before you can participate in any Contests. You must accept these
          Official Rules in order to participate as a player and member of a
          team in Contests (respectively, "Player" and "Team Member"), and you
          must abide by these Official Rules in order to remain eligible to play
          in Contests and receive prizes. We make every effort to ensure the
          rules are complete and up to date, but this may not always be the
          case. If you have any questions about the rules, please contact us at{" "}
          <a
            className="copy-link-gray"
            href="mailto:info@virtuix.com"
            target="_blank"
            rel="noreferrer"
          >
            info@virtuix.com
          </a>
          .
        </p>
        <div className="copy-indent">
          <h3 className="copy-subheader">
            1.1. Acceptance of the Official Rules
          </h3>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>
                You indicate your acceptance of these Official Rules if you
                enter and participate in any game or match that is part of any
                Contests.
              </li>
              <li>
                If you are under the legal age of majority in your jurisdiction
                of residence, but over the age of thirteen (13) as of the
                commencement of a Contest (a "Minor"), your parent or legal
                guardian must accept these Official Rules on your behalf.
              </li>
              <li>
                If you (or your parent or legal guardian, if you are a Minor) do
                not accept these Official Rules, you may not participate in any
                Contests. If you are a Player and you do not meet the
                Eligibility Requirements in Section 2 below, you are not
                considered an Eligible Participant and may not participate in
                any Contests.
              </li>
            </ul>
          </p>
          <h3 className="copy-subheader">
            1.2. Applicability of the Official Rules
          </h3>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc copy-list-indent">
              <li>
                These Official Rules govern competitive play during any
                Contests.
              </li>
              <li>
                These Official Rules are in addition to the specific game rules
                for each individual game for a Contest ("Game Rules") that may
                be found at{" "}
                <a
                  className="copy-link-gray"
                  href="https://arena.virtuix.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://arena.virtuix.com
                </a>{" "}
                (the "Site").
              </li>
              <li>
                These Official Rules are also in addition to the Omni Arena{" "}
                <a
                  className="copy-link-gray"
                  href="https://arena.virtuix.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a
                  className="copy-link-gray"
                  href="https://arena.virtuix.com/terms-of-use"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>{" "}
                found at the Site.
              </li>
              <li>
                If you or a member of your team in a Contest ("Team") violates
                these Official Rules, you may be subject to game forfeitures,
                prize forfeitures, disciplinary action, and legal claims, as
                explained below.
              </li>
              <li>
                If any part of these Official Rules is declared unenforceable or
                invalid, the remainder will continue to be valid and
                enforceable.
              </li>
              <li>
                In the event there is a discrepancy or inconsistency between the
                terms and conditions of the Official Rules and disclosures or
                other statements contained in any Contests-related materials,
                Game Rules, privacy policy or terms of use at any physical
                location or on any website of any "Participating Location" (as
                defined in Section 1.3 below), the Official Rules shall prevail,
                govern and control and the discrepancy will be resolved in
                Virtuix's sole and absolute discretion.
              </li>
            </ul>
          </p>
          <h3 className="copy-subheader">1.3. Definitions</h3>
          <p className="copy-body">
            Before each Contest, Virtuix will publish on the Site a list of
            location-based entertainment venues that operate Virtuix Omni
            systems and will participate in the Contest ("Participating
            Locations"). Participating Locations may also include the booth of
            Virtuix or a Virtuix distributor or partner at any events or
            tradeshow where Virtuix or a Virtuix distributor or partner is
            hosting an official Contest. Any location that is not confirmed by
            Virtuix or on the Site and is not included in the list of
            Participation Locations is not a Participating Location, and any
            gameplay at such location will not be included in the Contest.
          </p>
          <p className="copy-body">
            Each Contest will be based on one specific Omni Arena game and game
            mode (the "Contest Game") of which the Player or Team scores will be
            aggregated into the Contest leaderboard.
          </p>
        </div>
        <h2 className="copy-header">2. Eligibility Requirements</h2>
        <p className="copy-body">
          CONTESTS ARE OPEN ONLY TO PLAYERS WHO MEET ALL OF THE FOLLOWING
          CRITERIA: (I) HAVE AN OMNI ARENA USER ACCOUNT IN GOOD STANDING WITH A
          VALID EMAIL ADDRESS; (II) AS OF THE DATE OF ENTRY, ARE OVER THE LEGAL
          AGE OF MAJORITY UNDER THE LAWS OF THE RELEVANT JURISDICTION OF THE
          PARTICIPATING LOCATION WHERE THE PLAYER PARTICIPATES IN THE CONTEST,
          OR WHO ARE OVER THE AGE OF THIRTEEN YEARS OF AGE AND HAVE WRITTEN
          PERMISSION FROM THEIR PARENT OR GUARDIAN; (III) ARE A RESIDENT WITHIN
          THE COUNTRY OF A PARTICIPATING LOCATION ("ELIGIBLE COUNTRIES"); AND
          (IV) ARE A NATURAL PERSON (I.E., NOT A CORPORATION, PARTNERSHIP, OR
          OTHER LEGAL ENTITY) ("PLAYERS").
        </p>
        <p className="copy-body">
          IF ALL ELIGIBILITY CRITERIA ARE MET, THE PLAYER IS CONSIDERED AN
          "ELIGIBLE PARTICIPANT" (ELIGIBILITY IS NOT GUARANTEED AND MAY BE
          REVOKED AT ANY TIME).
        </p>
        <p className="copy-body">
          THE CONTESTS ARE VOID OUTSIDE OF THE ELIGIBLE COUNTRIES AND WHERE
          PROHIBITED BY LAW. THE CONTESTS ARE VOID IN ANY LOCATIONS OTHER THAN
          THE PARTICIPATING LOCATIONS.
        </p>
        <div className="copy-indent">
          <h3 className="copy-subheader">2.1. Verifying Eligibility</h3>
          <div className="copy-indent">
            <h3 className="copy-subheader">
              2.1.1. Residency Requirements {"&"} Proof
            </h3>
            <p className="copy-body">
              To be eligible to receive prizes won in any Contests you must
              provide Virtuix with proof that you are a residing in one of the
              Eligible Countries identified in Section 2 above.
            </p>
            <h3 className="copy-subheader">2.1.2. Minimum Age Requirements</h3>
            <p className="copy-body">
              You may participate in Contests if at the time of entry you have
              reached the age of majority in the relevant jurisdiction of the
              Participating Location where you participate in the Contests. If
              you are under the age of majority in such jurisdiction if at the
              time of entry into any Contests, but over the age of thirteen (13)
              at such time, you may still enter the Contest and participate as a
              Player if you otherwise meet all of the eligibility criteria set
              in these Official Rules AND one of your parents or legal guardians
              reads and accepts these Official Rules on your behalf. If you win
              any prize(s), the prize(s) will be awarded in the name of your
              parent or legal guardian.
            </p>
          </div>
          <h3 className="copy-subheader">2.2. Ineligible Players</h3>
          <p className="copy-body">
            Virtuix employees, Virtuix distributors and their staff, and staff
            of Participating Locations who are participating in the Contest are
            not eligible to enter or win any prizes in the Contest.
          </p>
          <h3 className="copy-subheader">2.3. Participating Locations</h3>
          <p className="copy-body">
            Players can only participate in the Contests by playing at one of
            the Participating Locations. Gameplay at a location that is not a
            Participating Location will not be recorded or included in the
            Contest leaderboard.
          </p>
          <h3 className="copy-subheader">2.4. Omni Arena User Account</h3>
          <p className="copy-body">
            A valid Omni Arena user account is required to participate in any
            Contests. Players can create an Omni Arena user account in the Omni
            Arena attraction.
          </p>
          <p className="copy-body">
            Only teams in which every player has an Omni Arena user account and
            is logged in will be eligible for prizes. Teams in which one or more
            players play as a guest will not be displayed on the leaderboard and
            are ineligible to receive prizes.
          </p>
          <h3 className="copy-subheader">2.5. Teams</h3>
          <p className="copy-body">
            A player can only be on one team. If a player plays on several
            teams, only the team with the highest score will be displayed on the
            leaderboard and be eligible for a prize. All of the player's other
            teams will be removed from the leaderboard and be ineligible to
            receive prizes.
          </p>
          <h3 className="copy-subheader">2.6. Previous Winners</h3>
          <p className="copy-body">
            In the weekly contest, players can only win a prize every other
            week. If a player won a prize in the prior week, the player's team
            will be removed from the leaderboard in the subsequent week and be
            ineligible to win a prize.
          </p>
          <h3 className="copy-subheader">2.7. Exceptions</h3>
          <p className="copy-body">
            Virtuix reserves the right, at its sole discretion, to consider a
            Player an Eligible Participant even if not all eligibility criteria
            are met.
          </p>
        </div>
        <h2 className="copy-header">
          3. Player and Team Rules and Requirements
        </h2>
        <div className="copy-indent">
          <h3 className="copy-subheader">3.1. Number of Players</h3>
          <p className="copy-body">
            The Contests require participating teams of up to four Eligible
            Participants, depending on the Contest Game. For clarification and
            the removal of doubt, these Official Rules do not govern Team
            relationships. The terms of the relationship between Players and
            their respective Teams are left to each of the Teams and their
            Players.
          </p>
          <h3 className="copy-subheader">3.2. Player Names</h3>
          <p className="copy-body">
            Players' Omni Arena Usernames must be deemed appropriate by Virtuix.
            Virtuix reserves the right to ban certain user names or ask Players
            to change their Omni Arena Username.
          </p>
          <h3 className="copy-subheader">3.3. Acknowledgment</h3>
          <p className="copy-body">
            By participating as a Player in a Contest, you acknowledge that the
            Virtuix Omni is a physical device, and using the Virtuix Omni
            requires physical exercise. You agree to not use the Virtuix Omni if
            you are pregnant, intoxicated, epileptic, or recovering from major
            surgery, illness, or a heart attack. The Virtuix Omni is intended
            for use by healthy adults between 4'2" (128 cm) and 6'5" (195 cm)
            tall, and not more than 285 lbs (130 kg). You agree not to use the
            Virtuix Omni if you do not meet the height and weight requirements,
            or if you are not healthy enough to engage in moderate to heavy
            physical exercise.
          </p>
          <p className="copy-body">
            Exercise can present a health risk. The Virtuix Omni should not be
            used for longer than one hour at a time. You should consult a
            physician before beginning any exercise with the Virtuix Omni.
            Players who feel faint or dizzy should immediately discontinue use
            of the Virtuix Omni. Some people may have a severe reaction to
            certain video games or virtual reality environments, including
            dizziness, seizures, epileptic seizures, or blackouts triggered by
            light flashes or patterns, even if they have no prior history of
            seizures or blackouts. If you have had a seizure, loss of awareness,
            or other symptoms that may be linked to an epileptic disorder, you
            should consult a physician before using the Virtuix Omni.
          </p>
        </div>
        <h2 className="copy-header">4. Format and Scheduling</h2>
        <div className="copy-indent">
          <h3 className="copy-subheader">4.1. Contest Dates and Times</h3>
          <p className="copy-body">
            The Contest date(s) for a specific Contest will be communicated on
            the Site and may be one or more consecutive days. In most instances,
            the start and conclusion times for a specific Contest will also be
            communicated on the Site. If no start or conclusion times are
            communicated on the Site for a specific Contest, then the start time
            for a specific Contest shall be 8 AM Eastern Time on the Contest
            date or the first day of the "Contest Period" (as defined below),
            and the conclusion time of the Contest shall be 2 AM Pacific Time on
            the day following the Contest date or the last full day of the
            Contest Period. The time period from the beginning of a Contest to
            its conclusion shall be deemed to constitute a "Contest Period."
            Winners of a Contest shall in all instances be determined at the end
            of the Contest regardless of the number of days comprising the
            Contest Period.
          </p>
          <h3 className="copy-subheader">4.2. Contest Format</h3>
          <p className="copy-body">
            Any Eligible Participant who plays the Contest Game during the
            Contest Period at a Participating Location will automatically
            participate in the Contest. The Player or Team score will
            automatically be recorded. Notwithstanding the above, depending on
            the Contest Game or type of Contest, only a Player's or Team's top
            score may be included in or displayed on the Contest leaderboard.
            For some Contests, a Player may only be allowed to be part of one
            Team, and only the score of his best performing team may be included
            in the Contest leaderboard. Participants are responsible for
            understanding the Contest Game controls and instructions. All scores
            will be tracked by the Contest Game's internal scoring determination
            system and will be deemed the official result for all purposes of
            the Contest.
          </p>
        </div>
        <h2 className="copy-header">5. Participation Costs</h2>
        <div className="copy-indent">
          <h3 className="copy-subheader">
            5.1. Participating Location Charges
          </h3>
          <p className="copy-body">
            A Participating Location charges Players for gameplay on the Virtuix
            Omni and to participate in the Contest. The amount of this charge is
            in the Participating Location's sole discretion.
          </p>
          <h3 className="copy-subheader">5.2. No reimbursements</h3>
          <p className="copy-body">
            UNDER NO CIRCUMSTANCES WILL VIRTUIX BE LIABLE FOR OR REIMBURSE ANY
            COSTS OR EXPENSES INCURRED BY THE PLAYER TO PARTICIPATE IN ANY
            CONTESTS.
          </p>
          <p className="copy-body">
            Virtuix is not responsible or liable for any costs or expenses of
            Eligible Participants participating in the Contests, including
            without limitation any costs or expenses associated with
            malfunctioning hardware, software, or internet connectivity at the
            Participating Location. Furthermore, Virtuix cannot guarantee that a
            Participating Location will actually participate in the Contest
            during the Contest Period or offer gameplay on the Virtuix Omni
            during the Contest Period. For the avoidance of doubt, you
            acknowledge and agree that Virtuix will not reimburse you for any
            costs you incurred to participate in any Contests, even if caused by
            technical malfunctions, cancellations by a Participating Location,
            player disqualification, Contest cancellation, or any other
            circumstance preventing you from participating in the Contest after
            you incurred costs to participate in the Contest.
          </p>
        </div>
        <h2 className="copy-header">6. Contest Prizes</h2>
        <div className="copy-indent">
          <h3 className="copy-subheader">6.1. Verifying Eligibility</h3>
          <p className="copy-body">
            ALL PLAYERS MUST BE VERIFIED BY VIRTUIX TO ENSURE THEIR ELIGIBILITY
            PRIOR TO BEING DECLARED A WINNER AND/OR RECEIVING ANY PRIZE. DISPLAY
            OF STATISTICS IN THE GAME (E.G. ON A LEADERBOARD) DURING THE CONTEST
            DOES NOT ENTITLE ANY ELIGIBLE PARTICIPANT TO A PRIZE. As set forth
            in Section 8, Virtuix reserves the right to disqualify any Player or
            deem any Player ineligible at its sole discretion. If a Player is
            disqualified or deemed ineligible, even after the Player has
            incurred costs to participate in a Contest, you acknowledge and
            agree that Virtuix will not be liable for such costs and will not
            reimburse the Player for such costs. The participant is responsible
            for the accuracy of their statements, especially for the correctness
            of their e-mail address. In order to participate, it is necessary
            that all required information is accurate, otherwise the participant
            may be excluded from receiving a prize. In addition, the participant
            is fully responsible for the shipping address data they provide. The
            winner furthermore agrees that their name and photo may be published
            by Virtuix or its licensees in connection with the Contests.
          </p>
          <h3 className="copy-subheader">6.2. Claim Deadline</h3>
          <p className="copy-body">
            The winners of the Contest are informed via e-mail. After having
            received a notice, the winner has two weeks to claim the prize. If
            the winner fails to respond within two weeks, any rights to claim
            the prize may be denied.
          </p>
          <h3 className="copy-subheader">6.3. Prizes</h3>
          <p className="copy-body">
            There is no possibility of claiming an alternate prize or alternate
            payment of the prize. In addition, it is not possible to trade the
            prize for other items, vouchers or goods. If the prize is provided
            by a third party, Virtuix will not be responsible for defects in the
            prize, incorrect prizes or inaccurate information about the prize.
            The prizes of each Contest will be announced on the Site. The prizes
            may be listed per Team. Each Team Member of a Team needs to be an
            Eligible Participant to be eligible to receive the prize, and each
            Team Member is individually responsible for providing the required
            information to receive his or her equal share of the prize.
          </p>
          <p className="copy-body">
            Participating Locations are not responsible for the Contest prizes
            advertised and sponsored by Virtuix, and Players shall not make any
            claims related to such Contest prizes to any Participating Location.
          </p>
          <div className="copy-indent">
            <h3 className="copy-subheader">6.3.1. Non-Cash Prizes</h3>
            <p className="copy-body">
              The winner is responsible to provide Virtuix with a valid and
              correct shipping address within an Eligible Country. Non-cash
              prizes can only be shipped to a shipping address in an Eligible
              Country. Virtuix is not responsible if a parcel wasn't accepted,
              can't be delivered, or gets lost. If the winner is located outside
              of the United States, then Virtuix reserves the right to pay out
              the value of the non-cash prize to the winner in cash, instead of
              shipping the non-cash prize to the winner.
            </p>
            <h3 className="copy-subheader">6.3.2. Cash Prizes</h3>
            <p className="copy-body">
              Cash prize money will be transferred via PayPal. The winner is
              responsible to provide a valid and correct PayPal account in order
              to receive the cash prize money. Virtuix intends to transfer the
              prize money within 4 weeks to 6 weeks, although the time of
              transfer may vary and is decided by Virtuix.
            </p>
          </div>
          <h3 className="copy-subheader">6.4. No Legal Recourse</h3>
          <p className="copy-body">
            There is no legal recourse for any claim brought by a Player
            (including without limitation an Eligible Participant) in connection
            with prizes under these Official Rules.
          </p>
          <h3 className="copy-subheader">6.5. Reserved Rights</h3>
          <p className="copy-body">
            Virtuix reserves the right to cancel or end Contests at any time and
            without advance notice.
          </p>
        </div>
        <h2 className="copy-header">7. Conduct of Players and Team Members</h2>
        <p className="copy-body">
          The following conduct is considered to be inappropriate and in
          violation of this Section. In addition, a Participating Location may
          have its own rules and policies, and all Players shall be subject to
          such Participating Location's rules.
        </p>
        <div className="copy-indent">
          <h3 className="copy-subheader">7.1. Behavior</h3>
          <div className="copy-indent">
            <h3 className="copy-subheader">
              7.1.1. Reporting Disruptive Behavior
            </h3>
            <p className="copy-body">
              Players and Teams are responsible for reporting any misconduct,
              unsportsmanlike behavior, cheating or other disruptive behavior to
              Virtuix. Please submit reports to{" "}
              <a
                className="copy-link-gray"
                href="mailto:info@virtuix.com"
                target="_blank"
                rel="noreferrer"
              >
                info@virtuix.com
              </a>
              .
            </p>
            <h3 className="copy-subheader">7.1.2. Competitive Integrity</h3>
            <p className="copy-body">
              All Team Members and Players must at all times observe the highest
              standards of good sportsmanship, personal integrity, honesty, and
              fair play. Unfair conduct includes but is not limited to
              collusion, hacking, exploiting, ringing, intentional
              disconnection, or other cheating methods. Team Members and Players
              are required to behave in a professional and a sportsmanlike
              manner in their interactions with other competitors, event
              organizers, and members of the Virtuix administration team, the
              media, sponsors, and fans. Virtuix maintain the sole right to
              judge violations of these rules and standards of integrity for
              competitive play.
            </p>
            <h3 className="copy-subheader">
              7.1.3. Illegal and Unethical Conduct
            </h3>
            <p className="copy-body">
              Team Members are required to observe all laws applicable to their
              participation in any Contests, including all games, matches, media
              events, autograph signings, photo sessions, sponsor events and
              other gatherings or events occurring with or as part of Contests.
            </p>
            <p className="copy-body">
              A Team Member may not, during the Contest, commit any act or
              become involved in any situation or occurrence which brings them
              into public disrepute, scandal or ridicule, or shocks or offends
              the community, or derogates from their public image or reflects
              unfavorably upon Virtuix, Omni Arena or any of the other products,
              services or sponsors of Virtuix.
            </p>
            <h3 className="copy-subheader">7.1.4. Profanity and Hate Speech</h3>
            <p className="copy-body">
              Virtuix is committed to providing a competitive environment that
              is free of harassment and discrimination. In furtherance of this
              commitment, Team Members and Players engaged in any Contests are
              prohibited from engaging in or promoting harassment or
              discrimination based on race, color, religion, gender, national
              origin, age, disability, sexual orientation or any other status or
              characteristic protected by law.
            </p>
            <p className="copy-body">
              All Team Members and Players may not use language that is obscene,
              foul, vulgar, insulting, threatening, abusive, libelous,
              slanderous, or otherwise offensive or objectionable. Team Members
              and Players may not use obscene gestures or profanity in their
              Omni Arena username, player handles, game chat, lobby chat, or
              live interviews. This rule applies to English and all other
              languages used in the Contests and includes abbreviations, slang,
              and/or obscure references.
            </p>
            <h3 className="copy-subheader">7.1.5. Disruptive Behavior</h3>
            <p className="copy-body">
              Team Members and Players may not take any action or perform any
              gesture directed at another player, Virtuix or Participating
              Location staff, fan, or any other party or incite others to do the
              same that is abusive, insulting, mocking, or disruptive.
            </p>
            <h3 className="copy-subheader">7.1.6. Unsportsmanlike Behavior</h3>
            <p className="copy-body">
              Should Virtuix determine that an Eligible Participant commits, or
              threatens to commit, violent acts against any other Eligible
              Participant in the Contest, a Virtuix representative, a
              Participating Location, or any spectator of the Contest, or
              engages in any unsportsmanlike behavior, the Eligible Participant
              may be disqualified from the Contest. Virtuix reserves the right
              at its sole discretion to disqualify any individual suspected of
              tampering with the entry process or the operation of the Contest;
              or to be acting in any manner deemed by Virtuix to be in violation
              of the Official Rules, or to be acting in any manner deemed by
              Virtuix to be unsportsmanlike or disruptive, or with intent to
              annoy, abuse, threaten or harass any other person.
            </p>
            <p className="copy-body">
              CAUTION: ANY ATTEMPT BY A USER OR ELIGIBLE PARTICIPANT OR ANY
              OTHER INDIVIDUAL TO DELIBERATELY DAMAGE ANY WEBSITE RELATED TO THE
              CONTESTS OR UNDERMINE THE LEGITIMATE OPERATION OF THE CONTESTS IS
              A VIOLATION OF CRIMINAL AND CIVIL LAWS AND SHOULD SUCH AN ATTEMPT
              BE MADE, VIRTUIX RESERVES THE RIGHT TO SEEK DAMAGES AND COSTS
              (INCLUDING WITHOUT LIMITATION, ATTORNEYS' FEES) FROM ANY SUCH
              PERSON TO THE FULLEST EXTENT PERMITTED BY LAW.
            </p>
          </div>
          <h3 className="copy-subheader">7.2. Cheating</h3>
          <div className="copy-indent">
            <h3 className="copy-subheader">7.2.1. Cheating in the Contests</h3>
            <p className="copy-body">
              Players must compete to the best of their ability at all times. It
              is each Eligible Participant's responsibility to know and
              understand the rules as they exist during the Contest. Any form of
              cheating will not be tolerated. If Virtuix determines that an
              Eligible Participant is cheating or otherwise interfering with the
              Contest, the Eligible Participant will be immediately disqualified
              and removed from the Contest and may be restricted from
              participating in any events in the future. Examples of cheating
              include, without limitation:
            </p>
            <div className="copy-indent">
              <h3 className="copy-subheader">7.2.1.1. DDoS</h3>
              <p className="copy-body">
                Attempts to interfere with another Eligible Participant's
                connection to the Game service through Distributed Denial of
                Service ("DDoS") or any other means;
              </p>
              <h3 className="copy-subheader">7.2.1.2. Account Sharing</h3>
              <p className="copy-body">
                Any incidence of an Eligible Participant allowing individual who
                is not the Eligible Participant to play on an Eligible
                Participant's Omni Arena Account during the Contest; and
              </p>
              <h3 className="copy-subheader">7.2.1.3. Movement Emulation</h3>
              <p className="copy-body">
                Attempts to emulate movement on the Virtuix Omni without
                actually physically walking or running on the Virtuix Omni, for
                example by simulating the movement of the Omni footwear by hand
                or other means.
              </p>
            </div>
          </div>
          <h3 className="copy-subheader">7.3. Gambling</h3>
          <p className="copy-body">
            Players are not allowed to place or attempt to place wagers on any
            matches or actions in, or around, any Contests. This rule also
            prohibits Team Members from participating in anyone else's betting
            activities, asking anyone to place bets on Contest games or matches
            on a Team Member's behalf, or encouraging anyone else to bet on
            Contest games or matches.
          </p>
          <h3 className="copy-subheader">7.4. Alcohol and Drugs</h3>
          <p className="copy-body">
            Virtuix's goal is to provide a safe competitive environment by
            eliminating the hazards to health and safety created by alcohol and
            drug misuse and abuse. Virtuix will not tolerate the presence of
            illegal drugs or the unapproved use of legal drugs at any Contest.
            The use, possession, distribution or sale of illegal substances, or
            the excessive use of alcohol, is strictly prohibited while a Team
            Member is engaged in a Contest.
          </p>
          <p className="copy-body">
            The unauthorized use or possession of prescription drugs by Team
            Members during a Contest is prohibited. Prescription drugs may be
            used only by the person to whom they are prescribed and in the
            manner, combination, and quantity as prescribed. Prescription drugs
            may only be used to treat the condition for which they are
            prescribed and may not be used to enhance performance in a game or
            match.
          </p>
          <h3 className="copy-subheader">7.5. Interviews and the Media</h3>
          <p className="copy-body">
            Team Members must be polite to spectators and media.
          </p>
          <h3 className="copy-subheader">7.6. Restricted Sponsors</h3>
          <p className="copy-body">
            Virtuix wants you to be successful in your efforts to build your own
            brand and sponsorship opportunities. However, in order to preserve
            the reputation of Virtuix, Virtuix's products, and the Contests,
            Virtuix needs to place some restrictions on sponsor categories.
          </p>
          <div className="copy-indent">
            <h3 className="copy-subheader">7.6.1. Categories</h3>
            <p className="copy-body">
              The following sponsor categories will not be allowed to any player
              participating in the Contests:
            </p>
            <p className="copy-body copy-list-indent">
              <ul className="copy-list-disc">
                <li>
                  Pornography (or materials that Virtuix, in its sole
                  discretion, determines are the equivalent of pornography);
                </li>
                <li>Alcohol;</li>
                <li>Tobacco or cigarettes, including vaping products;</li>
                <li>Narcotics, or similar controlled substances;</li>
                <li>Firearms;</li>
                <li>Gambling, including websites that offer to gamble;</li>
                <li>
                  Any person or entity that offers products or services that
                  Virtuix determines are detrimental to the Contests or that
                  give one player an unfair advantage over another player,
                  including hacking, account sellers, etc.
                </li>
              </ul>
            </p>
            <p className="copy-body">
              Further, no Team Member may promote, advertise or otherwise
              display the name or trademarks of any business engaged in gambling
              at any of the Contests.
            </p>
            <h3 className="copy-subheader">
              7.6.2. Enterprise & Reserved Sponsorship
            </h3>
            <p className="copy-body">
              In addition, Virtuix needs to preserve the right to have
              enterprise-wide sponsorships or sponsorships for particular game
              titles or particular events in order to fund the development and
              expansion of Contests and related events. Accordingly, Virtuix may
              designate certain sponsors or product categories as "reserved."
              Players participating in the Contests and related events will not
              be permitted to accept sponsorships from reserved sponsors or in
              reserved categories.
            </p>
          </div>
        </div>
        <h2 className="copy-header">8. Disciplinary Action and Penalties</h2>
        <div className="copy-indent">
          <h3 className="copy-subheader">8.1. Disciplinary Action</h3>
          <p className="copy-body">
            In order to preserve the integrity of the Contests, Virtuix has the
            right to monitor compliance with these Official Rules, investigate
            possible breaches of these Official Rules and impose sanctions for
            violations.
          </p>
          <h3 className="copy-subheader">8.2. Non-Compliance</h3>
          <p className="copy-body">
            You agree to cooperate with Virtuix in any such investigation. No
            Team Member or Player may refuse or fail to follow the reasonable
            instructions or decisions of Virtuix staff.
          </p>
          <div className="copy-indent">
            <h3 className="copy-subheader">8.2.1. Subjection to Penalty</h3>
            <p className="copy-body">
              Any person found to have engaged in any act that Virtuix believes
              violates any of these Official Rules or does not align with the
              spirit of these rules, in the sole discretion of the Virtuix will
              be subject to one or more penalties. The nature and extent of the
              penalties imposed will be in the sole discretion of Virtuix.
            </p>
          </div>
          <h3 className="copy-subheader">8.3. Penalties</h3>
          <p className="copy-body">
            If Virtuix determines that a Team Member or Player has been cheating
            or has otherwise violated these Official Rules, the Team Member or
            Player may be immediately disqualified and removed from the Contest
            and any future Contests.
          </p>
        </div>
        <h2 className="copy-header">
          9. Use and Collection of Your Brand Materials and Personal Data
        </h2>
        <div className="copy-indent">
          <h3 className="copy-subheader">
            9.1. License to Use Your Brand Materials
          </h3>
          <p className="copy-body">
            Virtuix wants to help promote you as a member of an elite Team and
            help you develop your personal brand. To do that, Virtuix needs a
            license to use your name, nickname, Omni Arena username, logo,
            initials, likeness, image, photograph, animation, autograph, voice,
            public persona, biographical information and backstory ("Your Brand
            Materials"). Virtuix also wants to use Your Brand Materials to
            promote Virtuix, Omni Arena, the Contests, and other products and
            services, and you agree that Virtuix may do that.
          </p>
          <p className="copy-body">
            Accordingly, you hereby grant to Virtuix a royalty-free, fully
            paid-up, worldwide, perpetual, irrevocable, nonexclusive right and
            license (with the right to grant sublicenses) to copy, display,
            distribute, edit, transmit, host, store and otherwise use Your Brand
            Materials, and create derivative works thereof, in any and all
            present and future media, on or in connection with (i) the
            broadcast, re-broadcast and/or streaming (including via internet
            transmission and wireless networks) of all or any part of the
            Contests (including any scrimmages, games, matches or other
            competitions that are part of the Contests) or any associated event,
            and/or (ii) the advertising, marketing, distribution, promotion and
            sale of the products and services of Virtuix.
          </p>
          <h3 className="copy-subheader">9.2. Advertising Materials</h3>
          <p className="copy-body">
            The grant of rights and licenses in Section 9.1 includes the right
            and license of Virtuix to copy, display, distribute, edit, transmit,
            host, store and otherwise use Your Brand Materials, and create
            derivative works thereof, on or in connection with:
          </p>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>
                Websites of Virtuix and its streaming and broadcast partners;
              </li>
              <li>Social media postings;</li>
              <li>Print and online advertising and content;</li>
              <li>Network, cable, and local television and radio;</li>
              <li>Newspaper and magazine advertising and content;</li>
              <li>
                Online advertising and content, including banners, leaderboards,
                and skyscrapers;
              </li>
              <li>
                Outdoor and indoor billboards, posters, signs, and displays;
              </li>
              <li>
                Product catalogs, point-of-sale materials, hang tags, product
                packaging and instruction manuals;
              </li>
              <li>Press releases, newsletters and e-alerts; and</li>
              <li>
                Hats, shirts and other apparel and gaming gear and peripherals.
              </li>
            </ul>
          </p>
          <p className="copy-body">
            You hereby waive and agree not to assert, any rights of prior review
            and/or approval of any of the foregoing advertising materials
            ("Advertising Materials") that you may have under applicable law.
            Nothing in these Official Rules requires Virtuix to make use of any
            of the rights or licenses granted herein. You agree to take, at
            Virtuix's expense, any further action (including execution of
            affidavits and other documents) that Virtuix reasonably requests to
            effect, perfect or confirm Virtuix's rights as set forth in these
            Official Rules.
          </p>
          <h3 className="copy-subheader">
            9.3. Ownership of Advertising Materials, Feedback, Stats, and
            Suggestions
          </h3>
          <p className="copy-body">
            As between you and Virtuix, you are the sole owner of Your Brand
            Materials.
          </p>
          <p className="copy-body">
            As between you and Virtuix, Virtuix shall be the sole owner of all
            of the following:
          </p>
          <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
              <li>
                Advertising Materials (but not Your Brand Materials that are
                incorporated into or used in the Advertising Materials);
              </li>
              <li>
                Works of authorship, audio-visual works, artwork, compilations,
                data and documentation that are created by or for a member of
                Virtuix and that incorporate or make use of all or any part of
                Your Brand Materials (but not Your Brand Materials that are
                incorporated or used therein);
              </li>
              <li>
                Suggestions, comments and other feedback that you may provide to
                Virtuix in any way;
              </li>
              <li>
                Data and statistics relating to your play of Omni Arena, and all
                feeds and data streams of such data and statistics; and
              </li>
              <li>
                All intellectual property rights in each of the foregoing.
              </li>
            </ul>
          </p>
          <p className="copy-body">
            You agree not to engage, directly or indirectly, in any acts that
            might jeopardize, or contest or attempt to acquire, any rights of
            Virtuix in the Advertising Materials or any of the foregoing items.
          </p>
          <h3 className="copy-subheader">9.4. Collection of Personal Data</h3>
          <p className="copy-body">
            Virtuix collects the information necessary to operate the Contests
            at the time that you register your Omni Arena Account, and without
            limiting Virtuix's rights under its online Privacy Policy, Virtuix
            will use such information to contact you for matters and events
            related to the operation of the Contests. Virtuix may also share
            your Personal Data with third-party providers that provide customer
            services in connection with the Contests, including operators of
            entertainment venues that operate Virtuix Omni or Omni Arena
            attractions. Virtuix will collect, store, and use information
            collected in connection with the Contests (including information
            collected at live events) in accordance with Virtuix's Privacy
            Policy, available at{" "}
            <a
              className="copy-link-gray"
              href="https://arena.virtuix.com"
              target="_blank"
              rel="noreferrer"
            >
              https://arena.virtuix.com
            </a>
            . By accepting these Official Rules by participating in the
            Contests, you are acknowledging your agreement to the terms of
            Virtuix's{" "}
            <a
              className="copy-link-gray"
              href="https://arena.virtuix.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>
        </div>
        <h2 className="copy-header">10. General Release</h2>
        <p className="copy-body">
          As a condition to being allowed to participate in the Contests and to
          the greatest extent permitted by the applicable laws and regulations,
          Players agree to release, indemnify and hold harmless Virtuix Inc.,
          its affiliates, its distributors, and its Participating Locations (the
          "Released Parties") from any liability whatsoever, and waive any and
          all causes of action, related to any claims, costs, injuries, losses,
          or damages of any kind arising out of or in connection with the
          Contests or delivery, misdelivery, acceptance, possession, use of or
          inability to use any prize (including, without limitation, claims,
          costs, injuries, losses and damages related to personal injuries,
          death, damage to or destruction of property, rights of publicity or
          privacy, defamation or portrayal in a false light, whether intentional
          or unintentional), whether under a theory of contract, tort (including
          negligence), warranty or other theory.
        </p>
        <h2 className="copy-header">11. Limitation of Liability</h2>
        <p className="copy-body">
          BY ENTERING THE CONTEST, PLAYER AGREES THAT TO THE EXTENT PERMITTED BY
          APPLICABLE LAW: (A) ANY AND ALL DISPUTES, CLAIMS AND CAUSES OF ACTION
          ARISING OUT OF OR CONNECTED WITH THE EVENT, OR ANY PRIZE AWARDED, WILL
          BE RESOLVED INDIVIDUALLY, WITHOUT RESORT TO ANY FORM OF CLASS ACTION;
          (B) ANY AND ALL CLAIMS, JUDGMENTS AND AWARDS WILL BE LIMITED TO ACTUAL
          THIRD-PARTY, OUT-OF-POCKET COSTS INCURRED (IF ANY) NOT TO EXCEED TEN
          DOLLARS ($10.00), BUT IN NO EVENT WILL ATTORNEYS' FEES BE AWARDED OR
          RECOVERABLE; AND (C) UNDER NO CIRCUMSTANCES WILL ANY PLAYERS BE
          PERMITTED TO OBTAIN ANY AWARD FOR, AND ENTRANT HEREBY KNOWINGLY AND
          EXPRESSLY WAIVES ALL RIGHTS TO SEEK, PUNITIVE, INCIDENTAL,
          CONSEQUENTIAL OR SPECIAL DAMAGES, LOST PROFITS AND/OR ANY OTHER
          DAMAGES, OTHER THAN ACTUAL OUT OF POCKET EXPENSES NOT TO EXCEED TEN
          DOLLARS ($10.00), AND/OR ANY RIGHTS TO HAVE DAMAGES MULTIPLIED OR
          OTHERWISE INCREASED. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATIONS
          OR EXCLUSION OF LIABILITY, SO THE ABOVE MAY NOT APPLY TO YOU.
        </p>
        <h2 className="copy-header">
          12. General (But Important) Terms and Conditions
        </h2>
        <p className="copy-body">
          Players agree to be bound by these Official Rules and by the decisions
          of Virtuix, which are final and binding in all respects. Virtuix
          reserves the right to change these Official Rules at any time, in its
          sole discretion, and to suspend or cancel the Contests or any
          entrant's participation in the Contests, in its sole discretion.
          Players who violate these Official Rules, tamper with the operation of
          the Contests or engage in any conduct that is detrimental or unfair to
          Virtuix or any other entrant (in each case as determined in Virtuix's
          sole discretion) are subject to disqualification from entry into the
          Contests. Virtuix reserves the right to lock out persons whose
          eligibility is in question or who have been disqualified or are
          otherwise ineligible to enter the Contests. All disputes that arise
          out of or relate to the Contests, an associated event or these
          Official Rules, or the breach thereof, shall be governed by and
          construed under the laws of the United States of America and the law
          of the State of Texas, without regard to choice of law principles,
          provided that Virtuix shall have the right to disqualify any players
          that are rendered ineligible due to local law. If you have any
          questions about these Official Rules or the Contests, please contact
          Virtuix at{" "}
          <a
            className="copy-link-gray"
            href="mailto:info@virtuix.com"
            target="_blank"
            rel="noreferrer"
          >
            info@virtuix.com
          </a>
          .
        </p>
        <div className="copy-indent">
          <h3 className="copy-subheader">12.1. Copyrights and Trademarks</h3>
          <p className="copy-body">
            Omni Arena and Virtuix are trademarks of Virtuix Holdings Inc. All
            rights are reserved. Nothing in these Official Rules or your
            participation in the Contests grants to you, by implication, waiver,
            estoppel or otherwise, any right or license to use or display any
            name, logo, service mark or trademark of Virtuix or any other
            Contest organizer.
          </p>
          <h3 className="copy-subheader">12.2. Changes to Contest Games</h3>
          <p className="copy-body">
            Virtuix reserves the right to patch, update or improve Contest Games
            at any time to fix bugs, change balance or add features or
            functionality as deemed appropriate by Virtuix, in its sole
            discretion. You agree that Virtuix will not be liable to you for any
            changes to Contest Games or these Official Rules.
          </p>
          <h3 className="copy-subheader">
            12.3. Changes to these Official Rules
          </h3>
          <p className="copy-body">
            These Official Rules contain the rules, policies and practices of
            Virtuix for the Contests that are in effect at the time of their
            publication. All previously issued rules, policies and practices
            related to the Contests, whether in print or online, are superseded.
            Virtuix reserves the right to revise, modify, change, delete or add
            to these Official Rules at any time, in its sole discretion, and to
            suspend, cancel or modify the Contests or any Player's participation
            in the Contests.
          </p>
          <h3 className="copy-subheader">12.4. No Employment Relationship</h3>
          <p className="copy-body">
            You agree that you are not an employee or contractor of Virtuix and
            are not entitled to employee or fringe benefits. Nothing in these
            Official Rules shall be deemed or construed to create a partnership,
            joint venture or similar relationship or create fiduciary duties or
            responsibilities on the part of Virtuix.
          </p>
          <h3 className="copy-subheader">12.5. Communications</h3>
          <p className="copy-body">
            We both agree that these Official Rules constitute a written
            agreement signed by both you and Virtuix under applicable law. To
            the fullest extent permitted by applicable law, these Official
            Rules, and any other notices, requests and other communications
            provided for herein, may be provided to you electronically, and you
            agree to receive all such communications from Virtuix in electronic
            form. Electronic communications will be delivered to you at the
            email address listed on your Omni Arena Account.
          </p>
          <h3 className="copy-subheader">12.6. Governing Law and Forum</h3>
          <p className="copy-body">
            To the maximum extent permitted by law, the construction, validity,
            and performance of these Official Rules is governed by the laws of
            the State of Texas in all disputes arising out of or relating to
            your participation in the Contests or these Official Rules. As a
            condition of your participation in the Contests, you hereby consent
            to Texas law, excluding Texas's choice-of-law provisions. Litigation
            of disputes under this Agreement shall be conducted in courts
            located in the City of Austin, Texas. The parties hereto consent to
            the jurisdiction of any local, state, or federal court in which an
            action is commenced and located in accordance with the terms of this
            Section and that is located in Austin, Texas. The parties further
            agree not to disturb such choice of forum, and if not resident in
            such state, waive the personal service of any and all process upon
            them, and consent that such service of process may be made by
            certified or registered mail, return receipt requested, addressed to
            the parties as set forth herein.
          </p>
          <h3 className="copy-subheader">12.7. Severability</h3>
          <p className="copy-body">
            If any of the provisions of these Official Rules are held by a court
            or other tribunal of competent jurisdiction to be void or
            unenforceable, such provisions shall be limited or eliminated to the
            minimum extent necessary and replaced with a valid provision that
            best embodies the intent of the term or provision in these Official
            Rules, so that these Official Rules shall remain in full force and
            effect.
          </p>
        </div>
        <p className="copy-body">Last Updated: April 25, 2022</p>
      </div>
    </>
  );
}

export default ContestRules;

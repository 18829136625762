import React, {useState, useEffect, useLayoutEffect} from "react";
import { useTable } from "react-table";
import "./CompTable.css";

function Table({ columns, data }) {
  
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  // Render Data Table UI
  return (
    <table className="scrolldown" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function MonthlyCompTable(props) {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(dataLoaded){
      return;
    }
    var data = [];
    var currentRank = 0;
    fetch("https://api.leaderboard.omniverse.global/api/leaderboards/"+props.leaderboardId+"/score?SelectedDateTime=2022-03-31T00%3A00%3A00.000Z&Count=100&IsFiltered=true")
    .then(result => result.json())
    .then(result => {
      result.forEach((score, index) => {
        data.push({
          rank: ++currentRank,
          venue: (
            <img className="comp-table-img"
              src={score.shopLogo}
              alt="Venue"
            />
          ),
          team: score.users?.map(user => user.nickname).join(" / "),
          score: score.score.toLocaleString('en')

        })
      });
      setDataLoaded(true);
      setLeaderboardData(data);
    });
    },[])

  const columns = [
    {
      Header: "Rank",
      accessor: "rank",
    },
    {
      Header: "Venue",
      accessor: "venue",
    },
    {
      Header: "Team",
      accessor: "team",
    },
    {
      Header: "Score",
      accessor: "score",
    },
  ];

  return (
    <>
      <Table data={leaderboardData} columns={columns} />
    </>
  );
}

export default MonthlyCompTable;
